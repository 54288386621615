import oneColor from "onecolor";
import view from "./view/previewView";
import kind from "./kind";
import buttonGlobalStyleKind from '../Button/globalStyle/kind';
import * as stylesheetSelectors from "../../Workspace/epics/stylesheets/selectors";
import { getGlobalstyleClassNameFromStyle } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import fontConfigMappers from '../../../mappers/block/font';
import type { WebshopComponent } from "./flowTypes";
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import './view/mobile.css';
import {
    getColorsForWebshopPublishScript,
    getWebshopLinkStyleForPreview,
    getWebshopLinkClassname,
    getViewdetailsButtonStyleForPreview,
    getDiscountDetailsStyle
} from "./utils";
import { BUTTON_THEME_PRIMARY, THEME_BUTTON_CLASS } from "../../ThemeGlobalData/constants";
import { themeaccent } from "../../App/epics/tinyMceEpic/editorUtils/constants";

export default {
    kind,
    view,
    calcProps: ({
        globalStyles,
        isServerPreview,
        uiLocale,
        componentsDependencies,
        component,
        domain,
        template,
        templateSelectorPreviewMode,
        siteMap,
        selectedParentTheme,
        siteSettings: { themeSettingsData }
    }: CalcProps<WebshopComponent>) => {
        let buttonClassName;
        const linkClassName = getWebshopLinkClassname(globalStyles),
            { autoColorMode } = themeSettingsData,
            getGlobalstyleClassNameForFirstButton = () => {
                return getGlobalstyleClassNameFromStyle(stylesheetSelectors.getFirstStylesheetByType(buttonGlobalStyleKind)(globalStyles));
            },
            firstButtonClassName = getGlobalstyleClassNameForFirstButton();
        let { buttonClassname: buyNowButtonClassName, buttonStyle: buyNowButtonStyle } = getViewdetailsButtonStyleForPreview(
            autoColorMode,
            component.buyNowButtonId || component.buttonId,
            globalStyles,
            selectedParentTheme,
            component.buyNowButtonThemeSelected || BUTTON_THEME_PRIMARY
        );
        if (isServerPreview) {
            if (!autoColorMode) {
                const buttonStyle = stylesheetSelectors.stylesheetByIdSelector(component.buttonId)(globalStyles);
                if (buttonStyle) {
                    buttonClassName = getGlobalstyleClassNameFromStyle(buttonStyle);
                }
            }
            if (autoColorMode || !buttonClassName) {
                buttonClassName = firstButtonClassName;
            }
            if (autoColorMode || !buyNowButtonClassName) {
                buyNowButtonClassName = firstButtonClassName;
            }
            if (autoColorMode) {
                buttonClassName =
                    `${buttonClassName} ${selectedParentTheme} ${component.buttonThemeSelected} ${THEME_BUTTON_CLASS}`;
                buyNowButtonClassName =
                    `${buyNowButtonClassName} ${selectedParentTheme} ${component.buyNowButtonThemeSelected} ${THEME_BUTTON_CLASS}`;
            }
            return {
                isServerPreview,
                uiLocale,
                buttonClassName,
                buyNowButtonClassName,
                // Only for webshop in publish page. Parent theme will be added in webshop root.
                linkClassName: autoColorMode ? `${linkClassName} ${themeaccent}` : linkClassName,
                buyButtonText: component.buyButtonText || '',
                buyNowButtonText: component.buyNowButtonText || '',
                enableBuyNowButton: component.enableBuyNowButton,
                domain,
                mobileView: siteMap.activateMobileView,
                templateSelectorPreviewMode,
                themeSettingsData,
                selectedParentTheme
            };
        }

        const hasWebShopComponentDependencies = componentsDependencies && componentsDependencies.WEBSHOP;
        const getButtonClassName = () => {
            if (autoColorMode) {
                return firstButtonClassName;
            }
            return hasWebShopComponentDependencies ?
                hasWebShopComponentDependencies.stylesheetsIdToNameMap[component.buttonId] : '';
        };
        const currentLocale = hasWebShopComponentDependencies ?
            hasWebShopComponentDependencies.localeDetails.current : template.locale;
        return {
            isServerPreview,
            uiLocale,
            buttonClassName: getButtonClassName(),
            buyNowButtonClassName,
            linkClassName,
            buyButtonText: component.buyButtonText || '',
            enableBuyNowButton: component.enableBuyNowButton,
            buyNowButtonText: component.buyNowButtonText || '',
            buttonStyle: hasWebShopComponentDependencies ? hasWebShopComponentDependencies.buttonStyle : {},
            buyNowButtonStyle: buyNowButtonStyle || {},
            linkStyle: getWebshopLinkStyleForPreview({
                autoColorMode,
                globalStyles,
                selectedParentTheme
            }),
            siteFonts: hasWebShopComponentDependencies ? hasWebShopComponentDependencies.siteFonts : [],
            locale: currentLocale,
            selectedParentTheme,
            themeSettingsData,
            globalStyles,
            featuredProduct: hasWebShopComponentDependencies ?
                hasWebShopComponentDependencies.featuredProduct : ''
        };
    },
    getSpecificStyles: ({
        component,
        globalStyles,
        autoColorMode,
        selectedParentTheme
    }: CmpSpecificStyles<WebshopComponent>): string => {
        const {
            fontColor,
            focusColor,
            labelBgColor,
            labelTextColor,
            promoRibbonBgColor,
            promoRibbonTextColor,
            hoverColor
        } = getColorsForWebshopPublishScript({
            autoColorMode,
            globalStyles,
            selectedParentTheme,
            component
        });
        const
            globalTextStyle = stylesheetSelectors.textNormalGlobalstyle(globalStyles),
            fontFamily = fontConfigMappers.family((component.font || globalTextStyle.font).toString()),

            lightFontColor = oneColor(fontColor).alpha(0.5),
            lighterFontColor = oneColor(fontColor).alpha(0.2),
            lightFocusColor = oneColor(focusColor).alpha(0.5),
            lightFocusBgColor = oneColor(focusColor).alpha(0.1),
            lightFocusContrastColor = oneColor(oneColor(lightFocusColor).lightness() > 0.5 ? '#000000' : '#ffffff'),
            fontColorCssa = oneColor(fontColor).cssa();

        let css = '.onecom-webshop-main {';
        css += 'font-family: ' + fontFamily + ';';
        css += '}';

        css += '.onecom-webshop-main,' +
            '.onecom-webshop-main a.link, .onecom-webshop-main a.link:link,' +
            '.onecom-webshop-main a.link:visited, .onecom-webshop-main a.link:hover,' +
            '.onecom-webshop-main a.link:active, .onecom-webshop-main input,' +
            '.onecom-webshop-main select, .onecom-webshop-main .text-color {';
        css += 'color: ' + fontColorCssa + ';';
        css += '}';

        css += '.onecom-webshop-main .text-color svg {';
        css += 'fill: ' + fontColorCssa + ';';
        css += '}';

        css += '.onecom-webshop-main .bg-light-focus:hover {';
        css += 'color: ' + oneColor(lightFocusContrastColor).cssa() + ' !important;';
        css += 'background-color: ' + oneColor(lightFocusColor).cssa() + ' !important';
        css += '}';

        css += '.onecom-webshop-main .border-fcolor, .onecom-webshop-main .border-light-color {';
        css += 'border-color: ' + oneColor(fontColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .border-light-font-color {';
        css += 'border-color: ' + oneColor(lightFontColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .focus, .onecom-webshop-main .mobile-slider a {';
        css += 'color: ' + oneColor(focusColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .focus svg {';
        css += 'fill: ' + oneColor(focusColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .bg-extra-light-focus:hover {';
        css += 'background-color: ' + oneColor(lightFocusBgColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .bg-extra-light-focus {';
        css += 'border-color: ' + oneColor(lighterFontColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .overlay-container:hover .overlay {';
        css += 'background-color: ' + oneColor(hoverColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .product-label {';
        css += 'background-color: ' + oneColor(labelBgColor).cssa() + ' !important;';
        css += 'color: ' + oneColor(labelTextColor).cssa() + ' !important;';
        css += '}';

        css += '.onecom-webshop-main .discount-ribbon-label {';
        css += 'background-color: ' + oneColor(promoRibbonBgColor).cssa() + ' !important;';
        css += 'color: ' + oneColor(promoRibbonTextColor).cssa() + ' !important;';
        css += '}';

        css += `.onecom-webshop-main ${getDiscountDetailsStyle(globalStyles, selectedParentTheme)}`;

        return css;
    },
    getSpecificScript: (
        arg: any,
    ): string => {
        const autoColorMode = arg.autoColorMode;
        const globalStyles = arg.globalStyles;
        const selectedParentTheme = arg.selectedParentTheme;
        const {
            id,
            buttonId,
            buttonThemeSelected,
            buyNowButtonId,
            buyNowButtonThemeSelected
        } = arg.component;

        const addToCardButton = getViewdetailsButtonStyleForPreview(
            autoColorMode,
            buttonId || buyNowButtonId,
            globalStyles,
            selectedParentTheme,
            buttonThemeSelected || BUTTON_THEME_PRIMARY
        );
        const buttonStyle = addToCardButton.buttonStyle;

        const buyNowButton = getViewdetailsButtonStyleForPreview(
            autoColorMode,
            buyNowButtonId || buttonId,
            globalStyles,
            selectedParentTheme,
            buyNowButtonThemeSelected || BUTTON_THEME_PRIMARY
        );

        let buyNowButtonStyle = buyNowButton.buttonStyle;

        if ((/.button1/g).test(buyNowButtonStyle)) {
            buyNowButtonStyle = buyNowButtonStyle.replace(/.button1/g, '.ws-buy-now.button1');
        }

        const linkStyle = getWebshopLinkStyleForPreview({
            autoColorMode,
            globalStyles,
            selectedParentTheme
        });

        const discountDescriptionStyle = getDiscountDetailsStyle(globalStyles, selectedParentTheme);

        const webshopPreviewStyles = `${buttonStyle || ""} ${buyNowButtonStyle || ""} ${linkStyle || ""} 
        ${discountDescriptionStyle || ""}`.replace(/\n/g, '');

        return `(function () {
                    oneJQuery(document).ready(function() {
                        setTimeout(function () {
                            var webshopDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="WEBSHOP"]');
                            var webshopIframe = webshopDiv.find("iframe");
                            var featuredProductUrl = webshopIframe.attr('featuredproducturl');
                            if (featuredProductUrl) {
                                var iframeSrcProductView = webshopIframe.attr('src') + '#!/products/' + featuredProductUrl;
                                webshopIframe.attr('src', iframeSrcProductView);
                            }
                        }, 10);

                        window.addEventListener("message", function(event) {
                            if (event.data) {
                                if(event.data.type === "UPDATE_WEBSHOP_HEIGHT") {
                                    var webshopDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="WEBSHOP"]');
                                    var webshopIframe = webshopDiv.find("iframe");

                                    // Set webshop iframe's parent div min height
                                    webshopDiv.css("min-height", event.data.data);

                                    // Set webshop iframe height
                                    webshopIframe.attr("height", event.data.data);
                                } else if(event.data.type === "FETCH_WEBSHOP_PREVIEW_STYLES") {
                                    var webshopDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="WEBSHOP"]');
                                    var webshopIframe = webshopDiv.find("iframe");
                                    if (webshopIframe && webshopIframe.length > 0) {
                                        var iframeComponent = webshopIframe[0];
                                        if (iframeComponent
                                            && iframeComponent.contentWindow
                                            && typeof iframeComponent.contentWindow.postMessage === 'function') {
                                            iframeComponent.contentWindow.postMessage(
                                                {
                                                    type: 'RECEIVE_WEBSHOP_PREVIEW_STYLES',
                                                    data: '${webshopPreviewStyles}'
                                                },
                                                new URL(iframeComponent.src).origin
                                            );
                                        }
                                    }

                                }
                            }
                        }, false);
                    });
                })();`;
    }
};
