export enum OnlineShopStatus {
    NoShop = "NoShop",
    Active = "Active",
    Inactive = "Inactive"
}

export type OnlineShopType = {
    onlineShopStatus: OnlineShopStatus | null;
    isLoadingOpenOrders: boolean;
    isLoadingOutOfStockProducts: boolean;
    isLoadingRevenue: boolean;
    isLoadingProducts: boolean;
    openOrders: number;
    outofstockProducts: number;
    revenue: Array<Record<string, number | string>> | -1;
    products: number;
    openDashboard: boolean;
    shopAppRoute: string;
    /*
        isShopLoggedIn is `null`  if not initialized.
        isShopLoggedIn is `false` if login attempt fails. (login request is blocked in firefox because of mime type).
        isShopLoggedIn is `true`  if login attempt succeeds.
     */
    isShopLoggedIn: boolean | null;
};

export type MoveOnlineDialogProps = {
    config: any;
    dialogProps: any;
};
