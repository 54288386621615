/* @flow */

// TODO: move to /partners

const PartnerName = {
    ONECOM: 'onecom',
    HOSTNET: 'hostnet',
    CHECKDOMAIN: 'checkdomain',
  }

const partnerIsOnecom = (partner/* : string */)/*: boolean */ =>  partner === PartnerName.ONECOM;
const partnerIsHostnet = (partner/*: string */)/*: boolean */ => partner === PartnerName.HOSTNET;
const partnerIsCheckdomain = (partner/*: string */)/*: boolean */ => partner === PartnerName.CHECKDOMAIN;

module.exports = {
    PartnerName,
    partnerIsOnecom,
    partnerIsHostnet,
    partnerIsCheckdomain
}
