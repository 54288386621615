import type { SideBarItemDef } from '../../SideBar/types';

export const BUSINESS_LISTINGS_PAGE_SIDE_BAR_ITEM_ID = 'BUSINESS_LISTINGS_PAGE_SIDE_BAR_ITEM_ID';

export const BusinessListingsSideBarItem: SideBarItemDef = {
    id: BUSINESS_LISTINGS_PAGE_SIDE_BAR_ITEM_ID,
    title: 'msg: common.BusinessListings.label {Business listing}',
    header: {
        title: 'msg: common.BusinessListings.label {Business listing}',
        description: 'msg: BusinessListings.header.label_v2 {Your business information, such as address and phone number is synced from your website to your listing. You can update this information in the General settings and then publish your site to refresh your listing.}'
    }
};
