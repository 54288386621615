/* @flow */

const { PartnerName } = require("../partners/PartnerName.js");
const SubscriptionType = {
    UNKNOWN: 'UNKNOWN',
    BASIC: 'BASIC',
    NORMAL: 'NORMAL',
    PREMIUM: 'PREMIUM',
    ECOMMERCE: 'ECOMMERCE',
    ECOMMERCE_DIFM: 'ECOMMERCE_DIFM',
    DEMO: 'DEMO',
    TRIAL: 'TRIAL',
    FREE: 'FREE',
    PAID: 'PAID',
    DESIGNER: 'DESIGNER',
};
const SUBSCRIPTIONERR = 'subscriptionErr';

const WSBSubToPartnerConfigType = {
    [PartnerName.ONECOM]: {
        [SubscriptionType.UNKNOWN]: '$unknown',
        [SubscriptionType.TRIAL]: '$trial',
        [SubscriptionType.BASIC]: '$basic',
        [SubscriptionType.NORMAL]: '$starter',
        [SubscriptionType.PREMIUM]: '$premium',
        [SubscriptionType.ECOMMERCE]: '$ecommerce'
    },
    [PartnerName.HOSTNET]: {
        [SubscriptionType.UNKNOWN]: '$unknown',
        [SubscriptionType.TRIAL]: '$trial',
        [SubscriptionType.NORMAL]: '$start',
        [SubscriptionType.PREMIUM]: '$pro',
        [SubscriptionType.ECOMMERCE]: '$plus',
        [SubscriptionType.ECOMMERCE_DIFM]: '$plus_difm'
    },
    [PartnerName.CHECKDOMAIN]: {
        [SubscriptionType.UNKNOWN]: '$unknown',
        [SubscriptionType.TRIAL]: '$trial',
        [SubscriptionType.NORMAL]: '$start',
        [SubscriptionType.PREMIUM]: '$pro',
        [SubscriptionType.ECOMMERCE]: '$ecommerce'
    },
    siteTemplates: {
        [SubscriptionType.UNKNOWN]: '$unknown',
        [SubscriptionType.ECOMMERCE]: '$designer'
    }
}

module.exports = {
    SubscriptionType,
    SUBSCRIPTIONERR,
    WSBSubToPartnerConfigType,
};
