/* eslint-disable max-len */

export const gmbKeysMap = {
    "3d_designer_portfolio": "msg: GMBCID.3d_designer_portfolio {3D Designer Portfolio}",
    "abarth_dealer": "msg: GMBCID.abarth_dealer {Abarth Dealer}",
    "abbey": "msg: GMBCID.abbey {Abbey}",
    "aboriginal_and_torres_strait_islander_organisation": "msg: GMBCID.aboriginal_and_torres_strait_islander_organisation {Aboriginal and Torres Strait Islander organisation}",
    "aboriginal_art_gallery": "msg: GMBCID.aboriginal_art_gallery {Aboriginal art gallery}",
    "abrasives_supplier": "msg: GMBCID.abrasives_supplier {Abrasives Supplier}",
    "abundant_life_church": "msg: GMBCID.abundant_life_church {Abundant Life church}",
    "academic_project": "msg: GMBCID.academic_project {Academic Project}",
    "academic_resume": "msg: GMBCID.academic_resume {Academic Resume}",
    "acaraje_restaurant": "msg: GMBCID.acaraje_restaurant {Acaraje restaurant}",
    "accordionist": "msg: GMBCID.accordionist {Accordionist}",
    "accountant": "msg: GMBCID.accountant {Accountant}",
    "accounting_firm": "msg: GMBCID.accounting_firm {Accounting firm}",
    "accounting_school": "msg: GMBCID.accounting_school {Accounting School}",
    "accounting_software_company": "msg: GMBCID.accounting_software_company {Accounting Software Company}",
    "acrobatic_diving_pool": "msg: GMBCID.acrobatic_diving_pool {Acrobatic diving pool}",
    "acrylic_store": "msg: GMBCID.acrylic_store {Acrylic shop}",
    "acupuncture_clinic": "msg: GMBCID.acupuncture_clinic {Acupuncture Clinic}",
    "acupuncture_school": "msg: GMBCID.acupuncture_school {Acupuncture school}",
    "acupuncturist": "msg: GMBCID.acupuncturist {Acupuncturist}",
    "acura_dealer": "msg: GMBCID.acura_dealer {Acura Dealer}",
    "administrative_attorney": "msg: GMBCID.administrative_attorney {Administrative attorney}",
    "adult_dvd_store": "msg: GMBCID.adult_dvd_store {Adult DVD shop}",
    "adult_education_school": "msg: GMBCID.adult_education_school {Adult education centre}",
    "adult_entertainment_club": "msg: GMBCID.adult_entertainment_club {Adult entertainment club}",
    "adult_entertainment_store": "msg: GMBCID.adult_entertainment_store {Adult entertainment store}",
    "adventure_sports": "msg: GMBCID.adventure_sports {Adventure sports}",
    "adventure_sports_center": "msg: GMBCID.adventure_sports_center {Adventure sports centre}",
    "advertising_agency": "msg: GMBCID.advertising_agency {Advertising agency}",
    "advertising_photographer": "msg: GMBCID.advertising_photographer {Advertising Photographer}",
    "aerated_drinks_supplier": "msg: GMBCID.aerated_drinks_supplier {Aerated Drinks Supplier}",
    "aerial_installation_service": "msg: GMBCID.aerial_installation_service {Antenna service}",
    "aerial_photographer": "msg: GMBCID.aerial_photographer {Aerial photographer}",
    "aerial_sports_center": "msg: GMBCID.aerial_sports_center {Aerial Sports Center}",
    "aero_dance_class": "msg: GMBCID.aero_dance_class {Aero dance class}",
    "aerobics_instructor": "msg: GMBCID.aerobics_instructor {Aerobics Instructor}",
    "aeroclub": "msg: GMBCID.aeroclub {Aero Club}",
    "aeromodel_shop": "msg: GMBCID.aeromodel_shop {Aeromodel shop}",
    "aeronautical_engineer": "msg: GMBCID.aeronautical_engineer {Aeronautical engineer}",
    "afghani_restaurant": "msg: GMBCID.afghani_restaurant {Afghani restaurant}",
    "african_restaurant": "msg: GMBCID.african_restaurant {African restaurant}",
    "afrobeat_musician": "msg: GMBCID.afrobeat_musician {Afrobeat Musician}",
    "after_school_program": "msg: GMBCID.after_school_program {After-school programme}",
    "aggregate_supplier": "msg: GMBCID.aggregate_supplier {Aggregate Supplier}",
    "agistment_service": "msg: GMBCID.agistment_service {Agistment service}",
    "agricultural_association": "msg: GMBCID.agricultural_association {Agricultural association}",
    "agricultural_cooperative": "msg: GMBCID.agricultural_cooperative {Agricultural cooperative}",
    "agricultural_machinery_manufacturer": "msg: GMBCID.agricultural_machinery_manufacturer {Agricultural Machinery Manufacturer}",
    "agricultural_organization": "msg: GMBCID.agricultural_organization {Agricultural organisation}",
    "agricultural_product_wholesaler": "msg: GMBCID.agricultural_product_wholesaler {Agricultural Product Wholesaler}",
    "agricultural_production": "msg: GMBCID.agricultural_production {Agricultural Production}",
    "agricultural_service": "msg: GMBCID.agricultural_service {Agricultural service}",
    "agricultural_service_supply_agency": "msg: GMBCID.agricultural_service_supply_agency {Agricultural Service Supply Agency}",
    "agriculture_cooperative": "msg: GMBCID.agriculture_cooperative {Agriculture cooperative}",
    "agrochemicals_supplier": "msg: GMBCID.agrochemicals_supplier {Agrochemicals Supplier}",
    "aikido_club": "msg: GMBCID.aikido_club {Aikido club}",
    "aikido_school": "msg: GMBCID.aikido_school {Aikido school}",
    "air_compressor_repair_service": "msg: GMBCID.air_compressor_repair_service {Air compressor repair service}",
    "air_compressor_supplier": "msg: GMBCID.air_compressor_supplier {Air Compressor Supplier}",
    "air_conditioning_contractor": "msg: GMBCID.air_conditioning_contractor {Air conditioning contractor}",
    "air_conditioning_repair_service": "msg: GMBCID.air_conditioning_repair_service {Air Conditioning Repair Service}",
    "air_conditioning_store": "msg: GMBCID.air_conditioning_store {Air conditioning store}",
    "air_conditioning_system_supplier": "msg: GMBCID.air_conditioning_system_supplier {Air Conditioning System Supplier}",
    "air_duct_cleaning_service": "msg: GMBCID.air_duct_cleaning_service {Air duct cleaning service}",
    "air_filter_supplier": "msg: GMBCID.air_filter_supplier {Air Filter Supplier}",
    "airbrushing_service": "msg: GMBCID.airbrushing_service {Airbrushing service}",
    "airbrushing_supply_store": "msg: GMBCID.airbrushing_supply_store {Airbrushing Supply Shop}",
    "aircraft_maintenance_company": "msg: GMBCID.aircraft_maintenance_company {Aircraft maintenance company}",
    "aircraft_supply_store": "msg: GMBCID.aircraft_supply_store {Aircraft Supply Shop}",
    "airplane_exhibit": "msg: GMBCID.airplane_exhibit {Airplane Exhibit}",
    "airport": "msg: GMBCID.airport {Airport}",
    "airport_shuttle_service": "msg: GMBCID.airport_shuttle_service {Airport shuttle service}",
    "airsoft_gun_shop": "msg: GMBCID.airsoft_gun_shop {Airsoft Gun Shop}",
    "airstrip": "msg: GMBCID.airstrip {Airstrip}",
    "alcohol_manufacturer": "msg: GMBCID.alcohol_manufacturer {Alcohol Manufacturer}",
    "alcoholic_beverage_wholesaler": "msg: GMBCID.alcoholic_beverage_wholesaler {Alcoholic Beverage Wholesaler}",
    "alfa_romeo_dealer": "msg: GMBCID.alfa_romeo_dealer {Alfa Romeo Dealer}",
    "alliance_church": "msg: GMBCID.alliance_church {Alliance church}",
    "alsace_restaurant": "msg: GMBCID.alsace_restaurant {Alsace restaurant}",
    "alternative_fuel_station": "msg: GMBCID.alternative_fuel_station {Alternative Fuel Station}",
    "alternator_supplier": "msg: GMBCID.alternator_supplier {Alternator Supplier}",
    "alto": "msg: GMBCID.alto {Alto}",
    "aluminium_supplier": "msg: GMBCID.aluminium_supplier {Aluminium Supplier}",
    "aluminum_frames_supplier": "msg: GMBCID.aluminum_frames_supplier {Aluminium Frames Supplier}",
    "aluminum_welder": "msg: GMBCID.aluminum_welder {Aluminium Welder}",
    "aluminum_window": "msg: GMBCID.aluminum_window {Aluminium Window}",
    "amateur_theatre": "msg: GMBCID.amateur_theatre {Amateur theater}",
    "ambient_music_artist": "msg: GMBCID.ambient_music_artist {Ambient Music Artist}",
    "ambulance_service": "msg: GMBCID.ambulance_service {Ambulance service}",
    "american_grocery_store": "msg: GMBCID.american_grocery_store {American grocery store}",
    "american_restaurant": "msg: GMBCID.american_restaurant {American restaurant}",
    "amish_furniture_store": "msg: GMBCID.amish_furniture_store {Amish Furniture Shop}",
    "amphitheatre": "msg: GMBCID.amphitheatre {Amphitheatre}",
    "amusement_center": "msg: GMBCID.amusement_center {Amusement Centre}",
    "amusement_machine_supplier": "msg: GMBCID.amusement_machine_supplier {Amusement Machine Supplier}",
    "amusement_park": "msg: GMBCID.amusement_park {Amusement park}",
    "amusement_park_ride": "msg: GMBCID.amusement_park_ride {Amusement park ride}",
    "amusement_ride_supplier": "msg: GMBCID.amusement_ride_supplier {Amusement Ride Supplier}",
    "an_hui_restaurant": "msg: GMBCID.an_hui_restaurant {Anhui restaurant}",
    "anago_restaurant": "msg: GMBCID.anago_restaurant {Anago restaurant}",
    "andalusian_restaurant": "msg: GMBCID.andalusian_restaurant {Andalusian restaurant}",
    "andhra_restaurant": "msg: GMBCID.andhra_restaurant {Andhra Restaurant}",
    "anganwadi_center": "msg: GMBCID.anganwadi_center {Anganwadi center}",
    "angler_fish_restaurant": "msg: GMBCID.angler_fish_restaurant {Angler fish restaurant}",
    "anglican_church": "msg: GMBCID.anglican_church {Anglican church}",
    "animal_activist": "msg: GMBCID.animal_activist {Animal Activist}",
    "animal_control_service": "msg: GMBCID.animal_control_service {Animal Control Service}",
    "animal_park": "msg: GMBCID.animal_park {Animal Park}",
    "animal_protection_organization": "msg: GMBCID.animal_protection_organization {Animal Protection Organisation}",
    "animal_rescue_service": "msg: GMBCID.animal_rescue_service {Animal rescue service}",
    "animal_shelter": "msg: GMBCID.animal_shelter {Animal shelter}",
    "animal_watering_hole": "msg: GMBCID.animal_watering_hole {Animal Watering Hole}",
    "animation_portfolio": "msg: GMBCID.animation_portfolio {Animation Portfolio}",
    "animation_studio": "msg: GMBCID.animation_studio {Animation Studio}",
    "anime_club": "msg: GMBCID.anime_club {Anime club}",
    "anime_enthusiast": "msg: GMBCID.anime_enthusiast {Anime Enthusiast}",
    "anniversary": "msg: GMBCID.anniversary {Anniversary}",
    "antique_furniture_restoration_service": "msg: GMBCID.antique_furniture_restoration_service {Antique furniture restoration service}",
    "antique_furniture_store": "msg: GMBCID.antique_furniture_store {Antique Furniture Shop}",
    "antique_store": "msg: GMBCID.antique_store {Antique Shop}",
    "apartment_building": "msg: GMBCID.apartment_building {Apartment Building}",
    "apartment_complex": "msg: GMBCID.apartment_complex {Apartment Complex}",
    "apostolic_church": "msg: GMBCID.apostolic_church {Apostolic church}",
    "appliance_parts_supplier": "msg: GMBCID.appliance_parts_supplier {Appliance Parts Supplier}",
    "appliance_repair_service": "msg: GMBCID.appliance_repair_service {Appliance repair service}",
    "appliance_store": "msg: GMBCID.appliance_store {Appliance Shop}",
    "appraiser": "msg: GMBCID.appraiser {Appraiser}",
    "aquaculture_farm": "msg: GMBCID.aquaculture_farm {Aquaculture Farm}",
    "aquarium": "msg: GMBCID.aquarium {Aquarium}",
    "aquarium_hobbyist": "msg: GMBCID.aquarium_hobbyist {Aquarium Hobbyist}",
    "aquarium_shop": "msg: GMBCID.aquarium_shop {Aquarium shop}",
    "aquatic_center": "msg: GMBCID.aquatic_center {Aquatic centre}",
    "arab_restaurant": "msg: GMBCID.arab_restaurant {Arab restaurant}",
    "arbitration_service": "msg: GMBCID.arbitration_service {Arbitration Service}",
    "arboretum": "msg: GMBCID.arboretum {Arboretum}",
    "arborist_and_tree_surgeon": "msg: GMBCID.arborist_and_tree_surgeon {Arborist and tree surgeon}",
    "archaeological_museum": "msg: GMBCID.archaeological_museum {Archaeological museum}",
    "archery_club": "msg: GMBCID.archery_club {Archery Club}",
    "archery_event": "msg: GMBCID.archery_event {Archery Event}",
    "archery_hall": "msg: GMBCID.archery_hall {Archery Hall}",
    "archery_range": "msg: GMBCID.archery_range {Archery Range}",
    "archery_store": "msg: GMBCID.archery_store {Archery Store}",
    "architect": "msg: GMBCID.architect {Architect}",
    "architect_portfolio": "msg: GMBCID.architect_portfolio {Architect Portfolio}",
    "architects_association": "msg: GMBCID.architects_association {Architects association}",
    "architectural_and_engineering_model_maker": "msg: GMBCID.architectural_and_engineering_model_maker {Architectural and Engineering Model Maker}",
    "architectural_designer": "msg: GMBCID.architectural_designer {Architectural Designer}",
    "architectural_firm": "msg: GMBCID.architectural_firm {Architectural Firm}",
    "architectural_salvage_store": "msg: GMBCID.architectural_salvage_store {Architectural Salvage Shop}",
    "architecture": "msg: GMBCID.architecture {Architecture}",
    "architecture_firm": "msg: GMBCID.architecture_firm {Architecture firm}",
    "architecture_school": "msg: GMBCID.architecture_school {Architecture School}",
    "archive": "msg: GMBCID.archive {Archive}",
    "arena": "msg: GMBCID.arena {Arena}",
    "argentinian_restaurant": "msg: GMBCID.argentinian_restaurant {Argentinian restaurant}",
    "armed_forces_association": "msg: GMBCID.armed_forces_association {Armed forces association}",
    "armenian_church": "msg: GMBCID.armenian_church {Armenian church}",
    "armenian_restaurant": "msg: GMBCID.armenian_restaurant {Armenian restaurant}",
    "army_and_navy_store": "msg: GMBCID.army_and_navy_store {Army & Navy Shop}",
    "army_base": "msg: GMBCID.army_base {Army Base}",
    "army_museum": "msg: GMBCID.army_museum {Army museum}",
    "aromatherapy_class": "msg: GMBCID.aromatherapy_class {Aromatherapy class}",
    "aromatherapy_service": "msg: GMBCID.aromatherapy_service {Aromatherapy Service}",
    "art_cafe": "msg: GMBCID.art_cafe {Art cafe}",
    "art_center": "msg: GMBCID.art_center {Art Centre}",
    "art_curator_portfolio": "msg: GMBCID.art_curator_portfolio {Art Curator Portfolio}",
    "art_enthusiast": "msg: GMBCID.art_enthusiast {Art Enthusiast}",
    "art_gallery": "msg: GMBCID.art_gallery {Art gallery}",
    "art_handcraft": "msg: GMBCID.art_handcraft {Art handcraft}",
    "art_museum": "msg: GMBCID.art_museum {Art museum}",
    "art_portfolio": "msg: GMBCID.art_portfolio {Art Portfolio}",
    "art_project": "msg: GMBCID.art_project {Art Project}",
    "art_restoration_service": "msg: GMBCID.art_restoration_service {Art restoration service}",
    "art_school": "msg: GMBCID.art_school {Art school}",
    "art_studio": "msg: GMBCID.art_studio {Art studio}",
    "art_supply_store": "msg: GMBCID.art_supply_store {Art supply shop}",
    "artificial_plant_supplier": "msg: GMBCID.artificial_plant_supplier {Artificial Plant Supplier}",
    "artist": "msg: GMBCID.artist {Artist}",
    "artist_portfolio": "msg: GMBCID.artist_portfolio {Artist Portfolio}",
    "arts_organization": "msg: GMBCID.arts_organization {Arts Organisation}",
    "asbestos_testing_service": "msg: GMBCID.asbestos_testing_service {Asbestos testing service}",
    "ashram": "msg: GMBCID.ashram {Ashram}",
    "asian_fusion_restaurant": "msg: GMBCID.asian_fusion_restaurant {Asian fusion restaurant}",
    "asian_grocery_store": "msg: GMBCID.asian_grocery_store {Asian Grocery Shop}",
    "asian_household_goods_store": "msg: GMBCID.asian_household_goods_store {Asian Household Goods Shop}",
    "asian_restaurant": "msg: GMBCID.asian_restaurant {Asian restaurant}",
    "asphalt_contractor": "msg: GMBCID.asphalt_contractor {Asphalt contractor}",
    "asphalt_mixing_plant": "msg: GMBCID.asphalt_mixing_plant {Asphalt Mixing Plant}",
    "assamese_restaurant": "msg: GMBCID.assamese_restaurant {Assamese restaurant}",
    "assemblies_of_god_church": "msg: GMBCID.assemblies_of_god_church {Assemblies of God church}",
    "assembly_hall": "msg: GMBCID.assembly_hall {Assembly Hall}",
    "assembly_room": "msg: GMBCID.assembly_room {Assembly Room}",
    "asset_management_company": "msg: GMBCID.asset_management_company {Asset Management Company}",
    "assisted_living_facility": "msg: GMBCID.assisted_living_facility {Assisted living residence}",
    "association_or_organization": "msg: GMBCID.association_or_organization {Association or organisation}",
    "aston_martin_dealer": "msg: GMBCID.aston_martin_dealer {Aston Martin Dealer}",
    "astrologer": "msg: GMBCID.astrologer {Astrologer}",
    "asturian_restaurant": "msg: GMBCID.asturian_restaurant {Asturian restaurant}",
    "athletic_club": "msg: GMBCID.athletic_club {Athletic Club}",
    "athletic_competition": "msg: GMBCID.athletic_competition {Athletic Competition}",
    "athletic_field": "msg: GMBCID.athletic_field {Athletics field}",
    "athletic_park": "msg: GMBCID.athletic_park {Athletic Park}",
    "athletic_track": "msg: GMBCID.athletic_track {Athletic track}",
    "attorney": "msg: GMBCID.attorney {Attorney}",
    "atv_dealer": "msg: GMBCID.atv_dealer {ATV Dealer}",
    "audi_dealer": "msg: GMBCID.audi_dealer {Audi Dealer}",
    "audio_visual_consultant": "msg: GMBCID.audio_visual_consultant {Audio visual consultant}",
    "audio_visual_equipment_repair_service": "msg: GMBCID.audio_visual_equipment_repair_service {Audio visual equipment repair service}",
    "audio_visual_equipment_supplier": "msg: GMBCID.audio_visual_equipment_supplier {Audio-Visual Equipment Supplier}",
    "audit_firm": "msg: GMBCID.audit_firm {Audit Firm}",
    "auditing_service": "msg: GMBCID.auditing_service {Auditing Service}",
    "auditor": "msg: GMBCID.auditor {Auditor}",
    "auditorium": "msg: GMBCID.auditorium {Auditorium}",
    "australian_goods_store": "msg: GMBCID.australian_goods_store {Australian Goods Shop}",
    "australian_restaurant": "msg: GMBCID.australian_restaurant {Australian restaurant}",
    "austrian_restaurant": "msg: GMBCID.austrian_restaurant {Austrian restaurant}",
    "auto_accessories_wholesaler": "msg: GMBCID.auto_accessories_wholesaler {Auto Accessories Wholesaler}",
    "auto_air_conditioning_service": "msg: GMBCID.auto_air_conditioning_service {Auto Air-conditioning Service}",
    "auto_auction": "msg: GMBCID.auto_auction {Auto Auction}",
    "auto_body_parts_supplier": "msg: GMBCID.auto_body_parts_supplier {Auto Body Parts Supplier}",
    "auto_bodywork_mechanic": "msg: GMBCID.auto_bodywork_mechanic {Auto Bodywork Mechanic}",
    "auto_chemistry_shop": "msg: GMBCID.auto_chemistry_shop {Auto chemistry shop}",
    "auto_dent_removal_service": "msg: GMBCID.auto_dent_removal_service {Auto Dent Removal Service}",
    "auto_electrical_service": "msg: GMBCID.auto_electrical_service {Auto electrical service}",
    "auto_glass_repair_service": "msg: GMBCID.auto_glass_repair_service {Auto glass repair service}",
    "auto_glass_shop": "msg: GMBCID.auto_glass_shop {Windshield Repair Service}",
    "auto_market": "msg: GMBCID.auto_market {Auto market}",
    "auto_painting": "msg: GMBCID.auto_painting {Auto Painting}",
    "auto_parts_manufacturer": "msg: GMBCID.auto_parts_manufacturer {Auto Parts Manufacturer}",
    "auto_parts_market": "msg: GMBCID.auto_parts_market {Vehicle Parts Market}",
    "auto_parts_store": "msg: GMBCID.auto_parts_store {Auto parts store}",
    "auto_radiator_repair_service": "msg: GMBCID.auto_radiator_repair_service {Auto Radiator Repair Service}",
    "auto_restoration_service": "msg: GMBCID.auto_restoration_service {Auto Restoration Service}",
    "auto_spring_shop": "msg: GMBCID.auto_spring_shop {Shock absorbers & suspension shop}",
    "auto_sunroof_shop": "msg: GMBCID.auto_sunroof_shop {Vehicle Sunroof Shop}",
    "auto_tune_up_service": "msg: GMBCID.auto_tune_up_service {Auto Tune Up Service}",
    "auto_upholsterer": "msg: GMBCID.auto_upholsterer {Auto Upholsterer}",
    "auto_window_tinting_service": "msg: GMBCID.auto_window_tinting_service {Auto window tinting service}",
    "auto_wrecker": "msg: GMBCID.auto_wrecker {Auto Wrecker}",
    "automation_company": "msg: GMBCID.automation_company {Automation company}",
    "avant_garde_artist": "msg: GMBCID.avant_garde_artist {Avant Garde Artist}",
    "aviation_consultant": "msg: GMBCID.aviation_consultant {Aviation consultant}",
    "aviation_training_institute": "msg: GMBCID.aviation_training_institute {Aviation training institute}",
    "awadhi_restaurant": "msg: GMBCID.awadhi_restaurant {Awadhi Restaurant}",
    "awning_supplier": "msg: GMBCID.awning_supplier {Awning Supplier}",
    "ayam_penyet_restaurant": "msg: GMBCID.ayam_penyet_restaurant {Ayam penyet restaurant}",
    "azerbaijani_restaurant": "msg: GMBCID.azerbaijani_restaurant {Azerbaijani restaurant}",
    "baby_clothing_store": "msg: GMBCID.baby_clothing_store {Baby Clothing Shop}",
    "baby_shower": "msg: GMBCID.baby_shower {Baby Shower}",
    "baby_store": "msg: GMBCID.baby_store {Baby shop}",
    "baby_swimming_school": "msg: GMBCID.baby_swimming_school {Baby swimming school}",
    "backing_vocalist": "msg: GMBCID.backing_vocalist {Backing Vocalist}",
    "baden_restaurant": "msg: GMBCID.baden_restaurant {Baden restaurant}",
    "badminton_club": "msg: GMBCID.badminton_club {Badminton club}",
    "badminton_complex": "msg: GMBCID.badminton_complex {Badminton Complex}",
    "badminton_court": "msg: GMBCID.badminton_court {Badminton court}",
    "bag_shop": "msg: GMBCID.bag_shop {Bag shop}",
    "bagel_shop": "msg: GMBCID.bagel_shop {Bagel shop}",
    "bagpiper": "msg: GMBCID.bagpiper {Bagpiper}",
    "bahai_house_of_worship": "msg: GMBCID.bahai_house_of_worship {Bahá'í house of worship}",
    "bait_shop": "msg: GMBCID.bait_shop {Bait & tackle shop}",
    "baker_portfolio": "msg: GMBCID.baker_portfolio {Baker Portfolio}",
    "bakery": "msg: GMBCID.bakery {Bakery}",
    "bakery_equipment": "msg: GMBCID.bakery_equipment {Bakery Equipment}",
    "bakso_restaurant": "msg: GMBCID.bakso_restaurant {Bakso restaurant}",
    "balalaika_player": "msg: GMBCID.balalaika_player {Balalaika Player}",
    "balinese_restaurant": "msg: GMBCID.balinese_restaurant {Balinese restaurant}",
    "ballet_school": "msg: GMBCID.ballet_school {Ballet school}",
    "balloon_artist": "msg: GMBCID.balloon_artist {Balloon artist}",
    "balloon_ride_tour_agency": "msg: GMBCID.balloon_ride_tour_agency {Balloon Ride Tour Agency}",
    "balloon_store": "msg: GMBCID.balloon_store {Balloon Shop}",
    "ballroom_dance_instructor": "msg: GMBCID.ballroom_dance_instructor {Ballroom Dance Instructor}",
    "band": "msg: GMBCID.band {Band}",
    "bangladeshi_restaurant": "msg: GMBCID.bangladeshi_restaurant {Bangladeshi restaurant}",
    "bangle_shop": "msg: GMBCID.bangle_shop {Bangle Shop}",
    "banjo_player": "msg: GMBCID.banjo_player {Banjo Player}",
    "bank": "msg: GMBCID.bank {Bank}",
    "bankruptcy_attorney": "msg: GMBCID.bankruptcy_attorney {Bankruptcy Attorney}",
    "banner_store": "msg: GMBCID.banner_store {Banner Shop}",
    "baptist_church": "msg: GMBCID.baptist_church {Baptist church}",
    "bar": "msg: GMBCID.bar {Bar}",
    "bar_and_grill": "msg: GMBCID.bar_and_grill {Bar & grill}",
    "bar_pmu": "msg: GMBCID.bar_pmu {Bar PMU}",
    "bar_restaurant_furniture_store": "msg: GMBCID.bar_restaurant_furniture_store {Bar Restaurant Furniture Shop}",
    "bar_tabac": "msg: GMBCID.bar_tabac {Bar tabac}",
    "barbecue_restaurant": "msg: GMBCID.barbecue_restaurant {Barbecue restaurant}",
    "barbecue_spots": "msg: GMBCID.barbecue_spots {Barbecue Spots}",
    "barber_school": "msg: GMBCID.barber_school {Barbers School}",
    "barber_shop": "msg: GMBCID.barber_shop {Barber shop}",
    "baritone": "msg: GMBCID.baritone {Baritone}",
    "bark_supplier": "msg: GMBCID.bark_supplier {Bark Supplier}",
    "baroque_flutist": "msg: GMBCID.baroque_flutist {Baroque Flutist}",
    "barrel_supplier": "msg: GMBCID.barrel_supplier {Barrel Supplier}",
    "bartending_school": "msg: GMBCID.bartending_school {Bartending school}",
    "baseball": "msg: GMBCID.baseball {Baseball}",
    "baseball_club": "msg: GMBCID.baseball_club {Baseball club}",
    "baseball_field": "msg: GMBCID.baseball_field {Baseball field}",
    "baseball_goods_store": "msg: GMBCID.baseball_goods_store {Baseball Goods Shop}",
    "basilica": "msg: GMBCID.basilica {Basilica}",
    "basketball_club": "msg: GMBCID.basketball_club {Basketball club}",
    "basketball_court": "msg: GMBCID.basketball_court {Basketball court}",
    "basketball_court_contractor": "msg: GMBCID.basketball_court_contractor {Basketball court contractor}",
    "basque_restaurant": "msg: GMBCID.basque_restaurant {Basque restaurant}",
    "bass_clarinetist": "msg: GMBCID.bass_clarinetist {Bass Clarinetist}",
    "bass_vocal_range": "msg: GMBCID.bass_vocal_range {Bass Vocal Range}",
    "bassist": "msg: GMBCID.bassist {Bassist}",
    "batak_restaurant": "msg: GMBCID.batak_restaurant {Batak restaurant}",
    "bathroom_remodeler": "msg: GMBCID.bathroom_remodeler {Bathroom Remodeler}",
    "bathroom_supply_store": "msg: GMBCID.bathroom_supply_store {Bathroom Supply Shop}",
    "batik_clothing_store": "msg: GMBCID.batik_clothing_store {Batik clothing store}",
    "battery_manufacturer": "msg: GMBCID.battery_manufacturer {Battery Manufacturer}",
    "battery_store": "msg: GMBCID.battery_store {Battery Shop}",
    "battery_wholesaler": "msg: GMBCID.battery_wholesaler {Battery Wholesaler}",
    "bavarian_restaurant": "msg: GMBCID.bavarian_restaurant {Bavarian restaurant}",
    "bbq_area": "msg: GMBCID.bbq_area {BBQ Area}",
    "beach_cleaning_service": "msg: GMBCID.beach_cleaning_service {Beach cleaning service}",
    "beach_clothing_store": "msg: GMBCID.beach_clothing_store {Beach Clothing Shop}",
    "beach_entertainment_shop": "msg: GMBCID.beach_entertainment_shop {Beach entertainment shop}",
    "beach_pavillion": "msg: GMBCID.beach_pavillion {Beach Pavillion}",
    "beach_volleyball_club": "msg: GMBCID.beach_volleyball_club {Beach volleyball club}",
    "beach_volleyball_court": "msg: GMBCID.beach_volleyball_court {Beach volleyball court}",
    "bead_store": "msg: GMBCID.bead_store {Bead Shop}",
    "bearing_supplier": "msg: GMBCID.bearing_supplier {Bearing Supplier}",
    "beatboxer": "msg: GMBCID.beatboxer {Beatboxer}",
    "beautician": "msg: GMBCID.beautician {Beautician}",
    "beauty_pageant": "msg: GMBCID.beauty_pageant {Beauty Pageant}",
    "beauty_product_supplier": "msg: GMBCID.beauty_product_supplier {Beauty Product Supplier}",
    "beauty_products_wholesaler": "msg: GMBCID.beauty_products_wholesaler {Beauty Products Wholesaler}",
    "beauty_salon": "msg: GMBCID.beauty_salon {Beauty Salon}",
    "beauty_school": "msg: GMBCID.beauty_school {Beauty therapy college}",
    "bed_and_breakfast": "msg: GMBCID.bed_and_breakfast {Bed & Breakfast}",
    "bed_shop": "msg: GMBCID.bed_shop {Bed shop}",
    "bedding_store": "msg: GMBCID.bedding_store {Bedding Shop}",
    "bedroom_furniture_store": "msg: GMBCID.bedroom_furniture_store {Bedroom furniture store}",
    "beef_rice_bowl_restaurant": "msg: GMBCID.beef_rice_bowl_restaurant {Gyudon restaurant}",
    "beer_distributor": "msg: GMBCID.beer_distributor {Beer Distributor}",
    "beer_garden": "msg: GMBCID.beer_garden {Beer garden}",
    "beer_hall": "msg: GMBCID.beer_hall {Beer hall}",
    "beer_store": "msg: GMBCID.beer_store {Beer shop}",
    "belgian_restaurant": "msg: GMBCID.belgian_restaurant {Belgian restaurant}",
    "belt_shop": "msg: GMBCID.belt_shop {Belt shop}",
    "bengali_restaurant": "msg: GMBCID.bengali_restaurant {Bengali restaurant}",
    "bentley_dealer": "msg: GMBCID.bentley_dealer {Bentley Dealer}",
    "berry_restaurant": "msg: GMBCID.berry_restaurant {Berry restaurant}",
    "berufsfachschule": "msg: GMBCID.berufsfachschule {Berufsfachschule (vocational school with apprenticeship)}",
    "betawi_restaurant": "msg: GMBCID.betawi_restaurant {Betawi restaurant}",
    "betting_agency": "msg: GMBCID.betting_agency {Betting agency}",
    "beverage_distributor": "msg: GMBCID.beverage_distributor {Beverage Distributor}",
    "bicycle_club": "msg: GMBCID.bicycle_club {Cycling Club}",
    "bicycle_rack": "msg: GMBCID.bicycle_rack {Bicycle Rack}",
    "bicycle_store": "msg: GMBCID.bicycle_store {Bicycle Shop}",
    "bicycle_wholesale": "msg: GMBCID.bicycle_wholesale {Bicycle Wholesale}",
    "bike_wash": "msg: GMBCID.bike_wash {Bike wash}",
    "bikram_yoga_studio": "msg: GMBCID.bikram_yoga_studio {Bikram yoga studio}",
    "bilingual_school": "msg: GMBCID.bilingual_school {Bilingual School}",
    "bingo_hall": "msg: GMBCID.bingo_hall {Bingo Hall}",
    "bio_data": "msg: GMBCID.bio_data {Bio Data}",
    "biochemical_supplier": "msg: GMBCID.biochemical_supplier {Biochemical Supplier}",
    "biotechnology_company": "msg: GMBCID.biotechnology_company {Biotechnology company}",
    "biotechnology_engineer": "msg: GMBCID.biotechnology_engineer {Biotechnology Engineer}",
    "bird_control_service": "msg: GMBCID.bird_control_service {Bird control service}",
    "bird_shop": "msg: GMBCID.bird_shop {Bird shop}",
    "bird_watching_area": "msg: GMBCID.bird_watching_area {Bird Watching Area}",
    "birth_certificate_service": "msg: GMBCID.birth_certificate_service {Birth Certificate Office}",
    "birthday_party": "msg: GMBCID.birthday_party {Birthday Party}",
    "biryani_restaurant": "msg: GMBCID.biryani_restaurant {Biryani restaurant}",
    "bistro": "msg: GMBCID.bistro {Bistro}",
    "blast_cleaning_service": "msg: GMBCID.blast_cleaning_service {Blast cleaning service}",
    "blind_school": "msg: GMBCID.blind_school {Blind school}",
    "blinds_shop": "msg: GMBCID.blinds_shop {Blinds shop}",
    "bluegrass_musician": "msg: GMBCID.bluegrass_musician {Bluegrass Musician}",
    "blueprint_service": "msg: GMBCID.blueprint_service {Blueprint service}",
    "blues_artist": "msg: GMBCID.blues_artist {Blues Artist}",
    "blues_club": "msg: GMBCID.blues_club {Blues club}",
    "bmw_dealer": "msg: GMBCID.bmw_dealer {BMW Dealer}",
    "bmw_motorcycle_dealer": "msg: GMBCID.bmw_motorcycle_dealer {BMW Motorcycle Dealer}",
    "bmx_club": "msg: GMBCID.bmx_club {BMX Club}",
    "bmx_park": "msg: GMBCID.bmx_park {BMX Park}",
    "bmx_track": "msg: GMBCID.bmx_track {BMX Track}",
    "board_game_club": "msg: GMBCID.board_game_club {Board Game Club}",
    "board_of_education": "msg: GMBCID.board_of_education {Education Councils & Boards Office}",
    "boarding_house": "msg: GMBCID.boarding_house {Boarding House}",
    "boarding_school": "msg: GMBCID.boarding_school {Boarding school}",
    "boat_accessories_supplier": "msg: GMBCID.boat_accessories_supplier {Boat Accessories Supplier}",
    "boat_builder": "msg: GMBCID.boat_builder {Boat builders}",
    "boat_club": "msg: GMBCID.boat_club {Boating club}",
    "boat_cover_supplier": "msg: GMBCID.boat_cover_supplier {Boat Cover Supplier}",
    "boat_dealer": "msg: GMBCID.boat_dealer {Boat Dealer}",
    "boat_ramp": "msg: GMBCID.boat_ramp {Boat Ramp}",
    "boat_tour_agency": "msg: GMBCID.boat_tour_agency {Boat Tour Agency}",
    "boat_trailer_dealer": "msg: GMBCID.boat_trailer_dealer {Boat Trailer Dealer}",
    "boating_instructor": "msg: GMBCID.boating_instructor {Boating Instructor}",
    "bocce_ball_court": "msg: GMBCID.bocce_ball_court {Bocce ball court}",
    "bodega": "msg: GMBCID.bodega {Bodega}",
    "body_piercing_shop": "msg: GMBCID.body_piercing_shop {Body piercing shop}",
    "body_shaping_class": "msg: GMBCID.body_shaping_class {Body shaping class}",
    "boiler_manufacturer": "msg: GMBCID.boiler_manufacturer {Boiler Manufacturer}",
    "boiler_supplier": "msg: GMBCID.boiler_supplier {Boiler Supplier}",
    "bongo_player": "msg: GMBCID.bongo_player {Bongo Player}",
    "bonsai_plant_supplier": "msg: GMBCID.bonsai_plant_supplier {Bonsai Plant Supplier}",
    "book_store": "msg: GMBCID.book_store {Book Shop}",
    "bookbinder": "msg: GMBCID.bookbinder {Bookbinder}",
    "bookmaker": "msg: GMBCID.bookmaker {Bookmaker}",
    "boot_camp": "msg: GMBCID.boot_camp {Boot Camp}",
    "boot_store": "msg: GMBCID.boot_store {Boot Shop}",
    "border_crossing_station": "msg: GMBCID.border_crossing_station {Border Crossing Station}",
    "bossa_nova_artist": "msg: GMBCID.bossa_nova_artist {Bossa Nova Artist}",
    "botanical_garden": "msg: GMBCID.botanical_garden {Botanical Garden}",
    "bottle_and_can_redemption_center": "msg: GMBCID.bottle_and_can_redemption_center {Bottle & Can Redemption Centre}",
    "bottled_water_supplier": "msg: GMBCID.bottled_water_supplier {Bottled Water Supplier}",
    "boutique": "msg: GMBCID.boutique {Boutique}",
    "bouzouki_player": "msg: GMBCID.bouzouki_player {Bouzouki Player}",
    "bowling_alley": "msg: GMBCID.bowling_alley {Bowling Alley}",
    "bowling_club": "msg: GMBCID.bowling_club {Bowling club}",
    "boxing_club": "msg: GMBCID.boxing_club {Boxing club}",
    "boxing_gym": "msg: GMBCID.boxing_gym {Boxing Gym}",
    "boxing_ring": "msg: GMBCID.boxing_ring {Boxing ring}",
    "boys_high_school": "msg: GMBCID.boys_high_school {Boy's High School}",
    "bpo_company": "msg: GMBCID.bpo_company {BPO Company}",
    "brake_shop": "msg: GMBCID.brake_shop {Brake shop}",
    "branding_agency": "msg: GMBCID.branding_agency {Branding Agency}",
    "brazilian_pastelaria": "msg: GMBCID.brazilian_pastelaria {Brazilian pastelaria}",
    "brazilian_restaurant": "msg: GMBCID.brazilian_restaurant {Brazilian restaurant}",
    "breakfast_restaurant": "msg: GMBCID.breakfast_restaurant {Breakfast restaurant}",
    "brewery": "msg: GMBCID.brewery {Brewery}",
    "brewpub": "msg: GMBCID.brewpub {Microbrewery}",
    "brick_manufacturer": "msg: GMBCID.brick_manufacturer {Brick Manufacturer}",
    "bridal_shop": "msg: GMBCID.bridal_shop {Bridal shop}",
    "bridge": "msg: GMBCID.bridge {Bridge}",
    "bridge_club": "msg: GMBCID.bridge_club {Bridge club}",
    "british_restaurant": "msg: GMBCID.british_restaurant {British restaurant}",
    "brunch_restaurant": "msg: GMBCID.brunch_restaurant {Brunch restaurant}",
    "bubble_tea_store": "msg: GMBCID.bubble_tea_store {Bubble tea store}",
    "buddhist_supplies_store": "msg: GMBCID.buddhist_supplies_store {Buddhist Supplies Shop}",
    "buddhist_temple": "msg: GMBCID.buddhist_temple {Buddhist temple}",
    "budget_japanese_inn": "msg: GMBCID.budget_japanese_inn {Budget Japanese Inn}",
    "buffet_restaurant": "msg: GMBCID.buffet_restaurant {Buffet restaurant}",
    "bugatti_dealer": "msg: GMBCID.bugatti_dealer {Bugatti Dealer}",
    "buick_dealer": "msg: GMBCID.buick_dealer {Buick Dealer}",
    "building_consultant": "msg: GMBCID.building_consultant {Building consultant}",
    "building_design_company": "msg: GMBCID.building_design_company {Building design company}",
    "building_designer": "msg: GMBCID.building_designer {Building designer}",
    "building_equipment_hire_service": "msg: GMBCID.building_equipment_hire_service {Building equipment hire service}",
    "building_inspector": "msg: GMBCID.building_inspector {Building Inspector}",
    "building_materials_market": "msg: GMBCID.building_materials_market {Building materials market}",
    "building_materials_store": "msg: GMBCID.building_materials_store {Building Materials Store}",
    "building_materials_supplier": "msg: GMBCID.building_materials_supplier {Building Materials Supplier}",
    "building_restoration_service": "msg: GMBCID.building_restoration_service {Building restoration service}",
    "building_society": "msg: GMBCID.building_society {Building society}",
    "building_support_supplier": "msg: GMBCID.building_support_supplier {Building Support Supplier}",
    "bulgarian_restaurant": "msg: GMBCID.bulgarian_restaurant {Bulgarian restaurant}",
    "bungee_jumping_center": "msg: GMBCID.bungee_jumping_center {Bungee Jumping Center}",
    "burglar_alarm_store": "msg: GMBCID.burglar_alarm_store {Burglar alarm shop}",
    "burmese_restaurant": "msg: GMBCID.burmese_restaurant {Burmese restaurant}",
    "burrito_restaurant": "msg: GMBCID.burrito_restaurant {Burrito restaurant}",
    "bus_and_coach_company": "msg: GMBCID.bus_and_coach_company {Bus and coach company}",
    "bus_depot": "msg: GMBCID.bus_depot {Bus Depot}",
    "bus_tour_agency": "msg: GMBCID.bus_tour_agency {Bus Tour Agency}",
    "business_center": "msg: GMBCID.business_center {Business Center}",
    "business_conference": "msg: GMBCID.business_conference {Business Conference}",
    "business_development_service": "msg: GMBCID.business_development_service {Business Development Service}",
    "business_law_attorney": "msg: GMBCID.business_law_attorney {Business Law Attorney}",
    "business_management_consultant": "msg: GMBCID.business_management_consultant {Business management consultant}",
    "business_park": "msg: GMBCID.business_park {Business Park}",
    "business_school": "msg: GMBCID.business_school {Business School}",
    "business_to_business_service": "msg: GMBCID.business_to_business_service {Business-to-Business Service}",
    "butane_gas_supplier": "msg: GMBCID.butane_gas_supplier {Butane Gas Supplier}",
    "butcher_shop": "msg: GMBCID.butcher_shop {Butcher Shop}",
    "butsudan_store": "msg: GMBCID.butsudan_store {Butsudan Shop}",
    "cabaret_club": "msg: GMBCID.cabaret_club {Cabaret club}",
    "cabinet_maker": "msg: GMBCID.cabinet_maker {Cabinet maker}",
    "cabinet_store": "msg: GMBCID.cabinet_store {Cabinet Shop}",
    "cable_company": "msg: GMBCID.cable_company {Cable Company}",
    "cadillac_dealer": "msg: GMBCID.cadillac_dealer {Cadillac Dealer}",
    "cafe": "msg: GMBCID.cafe {Cafe}",
    "cafeteria": "msg: GMBCID.cafeteria {Canteen}",
    "cajun_restaurant": "msg: GMBCID.cajun_restaurant {Cajun restaurant}",
    "cake_decorating_equipment_shop": "msg: GMBCID.cake_decorating_equipment_shop {Cake Decorating Equipment Shop}",
    "cake_decorating_portfolio": "msg: GMBCID.cake_decorating_portfolio {Cake Decorating Portfolio}",
    "californian_restaurant": "msg: GMBCID.californian_restaurant {Californian restaurant}",
    "calligraphy_lesson": "msg: GMBCID.calligraphy_lesson {Calligraphy Lesson}",
    "calvary_chapel_church": "msg: GMBCID.calvary_chapel_church {Calvary Chapel church}",
    "calypso_artist": "msg: GMBCID.calypso_artist {Calypso Artist}",
    "cambodian_restaurant": "msg: GMBCID.cambodian_restaurant {Cambodian restaurant}",
    "camera_store": "msg: GMBCID.camera_store {Camera Shop}",
    "campground": "msg: GMBCID.campground {Campground}",
    "camping_farm": "msg: GMBCID.camping_farm {Camping Farm}",
    "camping_store": "msg: GMBCID.camping_store {Camping Shop}",
    "canadian_pacific_northwest_restaurant": "msg: GMBCID.canadian_pacific_northwest_restaurant {Pacific Northwest restaurant (Canada)}",
    "canadian_restaurant": "msg: GMBCID.canadian_restaurant {Canadian restaurant}",
    "candle_store": "msg: GMBCID.candle_store {Candle shop}",
    "candy_store": "msg: GMBCID.candy_store {Candy Shop}",
    "cane_furniture_store": "msg: GMBCID.cane_furniture_store {Cane Furniture Shop}",
    "cannery": "msg: GMBCID.cannery {Cannery}",
    "canoe_and_kayak_club": "msg: GMBCID.canoe_and_kayak_club {Canoe and kayak club}",
    "canoe_and_kayak_store": "msg: GMBCID.canoe_and_kayak_store {Canoe & Kayak Shop}",
    "canoe_and_kayak_tour_agency": "msg: GMBCID.canoe_and_kayak_tour_agency {Canoe & Kayak Tour Agency}",
    "canoeing_area": "msg: GMBCID.canoeing_area {Canoeing area}",
    "cantabrian_restaurant": "msg: GMBCID.cantabrian_restaurant {Cantabrian restaurant}",
    "cantonese_restaurant": "msg: GMBCID.cantonese_restaurant {Cantonese restaurant}",
    "cape_verdean_restaurant": "msg: GMBCID.cape_verdean_restaurant {Cape Verdean restaurant}",
    "capoeira_school": "msg: GMBCID.capoeira_school {Capoeira school}",
    "capsule_hotel": "msg: GMBCID.capsule_hotel {Capsule Hotel}",
    "car_accessories_store": "msg: GMBCID.car_accessories_store {Car Accessories Shop}",
    "car_alarm_supplier": "msg: GMBCID.car_alarm_supplier {Car Alarm Supplier}",
    "car_battery_store": "msg: GMBCID.car_battery_store {Car Battery Shop}",
    "car_dealer": "msg: GMBCID.car_dealer {Car Dealer}",
    "car_detailing_service": "msg: GMBCID.car_detailing_service {Car Detailing Service}",
    "car_factory": "msg: GMBCID.car_factory {Car Factory}",
    "car_manufacturer": "msg: GMBCID.car_manufacturer {Car Manufacturer}",
    "car_race_track": "msg: GMBCID.car_race_track {Racetrack}",
    "car_rental": "msg: GMBCID.car_rental {Car Rental}",
    "car_repair": "msg: GMBCID.car_repair {Car Repair}",
    "car_security_system_installer": "msg: GMBCID.car_security_system_installer {Car security system installer}",
    "car_service": "msg: GMBCID.car_service {Car Service}",
    "car_stereo_store": "msg: GMBCID.car_stereo_store {Car Stereo Shop}",
    "career_summary": "msg: GMBCID.career_summary {Career Summary}",
    "caribbean_restaurant": "msg: GMBCID.caribbean_restaurant {Caribbean restaurant}",
    "carnival_and_fair": "msg: GMBCID.carnival_and_fair {Carnival And Fair}",
    "carnival_club": "msg: GMBCID.carnival_club {Carnival club}",
    "carpenter": "msg: GMBCID.carpenter {Carpenter}",
    "carpet_cleaning_service": "msg: GMBCID.carpet_cleaning_service {Carpet cleaning service}",
    "carpet_installer": "msg: GMBCID.carpet_installer {Carpet Fitter}",
    "carpet_manufacturer": "msg: GMBCID.carpet_manufacturer {Carpet Manufacturer}",
    "carpet_store": "msg: GMBCID.carpet_store {Carpet Retail Shop}",
    "carpet_wholesaler": "msg: GMBCID.carpet_wholesaler {Carpet Wholesaler}",
    "carvery_restaurant": "msg: GMBCID.carvery_restaurant {Carvery}",
    "casino": "msg: GMBCID.casino {Casino}",
    "casket_service": "msg: GMBCID.casket_service {Casket Service}",
    "castilian_restaurant": "msg: GMBCID.castilian_restaurant {Castilian restaurant}",
    "castle": "msg: GMBCID.castle {Castle}",
    "casual_japanese_style_restaurant": "msg: GMBCID.casual_japanese_style_restaurant {Syokudo and Teishoku restaurant}",
    "casual_sushi_restaurant": "msg: GMBCID.casual_sushi_restaurant {Conveyor belt sushi restaurant}",
    "cat_cafe": "msg: GMBCID.cat_cafe {Cat Cafe}",
    "cat_trainer": "msg: GMBCID.cat_trainer {Cat Trainer}",
    "catalonian_restaurant": "msg: GMBCID.catalonian_restaurant {Catalonian restaurant}",
    "catering_food_and_drink_supplies": "msg: GMBCID.catering_food_and_drink_supplies {Catering Food and Drink Supplies}",
    "cathedral": "msg: GMBCID.cathedral {Cathedral}",
    "catholic_cathedral": "msg: GMBCID.catholic_cathedral {Catholic cathedral}",
    "catholic_school": "msg: GMBCID.catholic_school {Catholic School}",
    "cattle_farm": "msg: GMBCID.cattle_farm {Cattle Farm}",
    "cattle_market": "msg: GMBCID.cattle_market {Cattle Market}",
    "caucasian_restaurant": "msg: GMBCID.caucasian_restaurant {Caucasian restaurant}",
    "cbse_school": "msg: GMBCID.cbse_school {CBSE School}",
    "cd_store": "msg: GMBCID.cd_store {CD Shop}",
    "ceiling_supplier": "msg: GMBCID.ceiling_supplier {Ceiling Supplier}",
    "celebration_and_event_photography_portfolio": "msg: GMBCID.celebration_and_event_photography_portfolio {Celebration And Event Photography Portfolio}",
    "cell_phone_accessory_store": "msg: GMBCID.cell_phone_accessory_store {Cell Phone Accessory Shop}",
    "cell_phone_charging_station": "msg: GMBCID.cell_phone_charging_station {Cell Phone Charging Station}",
    "cell_phone_store": "msg: GMBCID.cell_phone_store {Cell Phone Shop}",
    "cellist": "msg: GMBCID.cellist {Cellist}",
    "cement_manufacturer": "msg: GMBCID.cement_manufacturer {Cement Manufacturer}",
    "cement_supplier": "msg: GMBCID.cement_supplier {Cement Supplier}",
    "cemetery": "msg: GMBCID.cemetery {Cemetery}",
    "cendol_restaurant": "msg: GMBCID.cendol_restaurant {Cendol restaurant}",
    "central_american_restaurant": "msg: GMBCID.central_american_restaurant {Central American restaurant}",
    "central_bank": "msg: GMBCID.central_bank {Central bank}",
    "central_european_restaurant": "msg: GMBCID.central_european_restaurant {Central European restaurant}",
    "central_javanese_restaurant": "msg: GMBCID.central_javanese_restaurant {Central Javanese restaurant}",
    "ceramic_manufacturer": "msg: GMBCID.ceramic_manufacturer {Ceramic Manufacturer}",
    "ceramics_wholesaler": "msg: GMBCID.ceramics_wholesaler {Ceramics Wholesaler}",
    "certified_public_accountant": "msg: GMBCID.certified_public_accountant {Certified Public Accountant}",
    "chalet": "msg: GMBCID.chalet {Chalet}",
    "champon_noodle_restaurant": "msg: GMBCID.champon_noodle_restaurant {Champon noodle restaurant}",
    "chankonabe_restaurant": "msg: GMBCID.chankonabe_restaurant {Chanko restaurant}",
    "chapel": "msg: GMBCID.chapel {Chapel}",
    "charcuterie": "msg: GMBCID.charcuterie {Charcuterie}",
    "charity": "msg: GMBCID.charity {Charity}",
    "charity_and_fundraising_event": "msg: GMBCID.charity_and_fundraising_event {Charity And Fundraising Event}",
    "charter_school": "msg: GMBCID.charter_school {Academy}",
    "chartered_accountant": "msg: GMBCID.chartered_accountant {Chartered Accountant}",
    "check_cashing_service": "msg: GMBCID.check_cashing_service {Cheque cashing service}",
    "cheese_manufacturer": "msg: GMBCID.cheese_manufacturer {Cheese Manufacturer}",
    "cheesesteak_restaurant": "msg: GMBCID.cheesesteak_restaurant {Cheesesteak restaurant}",
    "chef_portfolio": "msg: GMBCID.chef_portfolio {Chef Portfolio}",
    "chemical_exporter": "msg: GMBCID.chemical_exporter {Chemical Exporter}",
    "chemical_manufacturer": "msg: GMBCID.chemical_manufacturer {Chemical Manufacturer}",
    "chemical_wholesaler": "msg: GMBCID.chemical_wholesaler {Chemical Wholesaler}",
    "chemistry_faculty": "msg: GMBCID.chemistry_faculty {Chemistry Faculty}",
    "chemistry_lab": "msg: GMBCID.chemistry_lab {Chemistry Lab}",
    "chesapeake_restaurant": "msg: GMBCID.chesapeake_restaurant {Chesapeake restaurant}",
    "chess_and_card_club": "msg: GMBCID.chess_and_card_club {Chess and card club}",
    "chess_club": "msg: GMBCID.chess_club {Chess Club}",
    "chess_instructor": "msg: GMBCID.chess_instructor {Chess Instructor}",
    "chess_tournament": "msg: GMBCID.chess_tournament {Chess Tournament}",
    "chettinad_restaurtant": "msg: GMBCID.chettinad_restaurtant {Chettinad Restaurant}",
    "chevrolet_dealer": "msg: GMBCID.chevrolet_dealer {Chevrolet Dealer}",
    "chicken_hatchery": "msg: GMBCID.chicken_hatchery {Chicken Hatchery}",
    "chicken_restaurant": "msg: GMBCID.chicken_restaurant {Chicken restaurant}",
    "chicken_wings_restaurant": "msg: GMBCID.chicken_wings_restaurant {Chicken wings restaurant}",
    "child_psychologist": "msg: GMBCID.child_psychologist {Child psychologist}",
    "childbirth_class": "msg: GMBCID.childbirth_class {Childbirth class}",
    "children_hall": "msg: GMBCID.children_hall {Children Hall}",
    "childrens_book_store": "msg: GMBCID.childrens_book_store {Childrens Book Shop}",
    "childrens_cafe": "msg: GMBCID.childrens_cafe {Childrens cafe}",
    "childrens_clothing_store": "msg: GMBCID.childrens_clothing_store {Children's Clothes Shop}",
    "childrens_club": "msg: GMBCID.childrens_club {Children's Club}",
    "childrens_farm": "msg: GMBCID.childrens_farm {Children's Farm}",
    "childrens_furniture_store": "msg: GMBCID.childrens_furniture_store {Children's Furniture Shop}",
    "childrens_library": "msg: GMBCID.childrens_library {Children's Library}",
    "childrens_museum": "msg: GMBCID.childrens_museum {Children's museum}",
    "childrens_party_service": "msg: GMBCID.childrens_party_service {Children's party service}",
    "childrens_store": "msg: GMBCID.childrens_store {Childrens Shop}",
    "childrens_theater": "msg: GMBCID.childrens_theater {Childrens Theatre}",
    "chilean_restaurant": "msg: GMBCID.chilean_restaurant {Chilean restaurant}",
    "chimney_services": "msg: GMBCID.chimney_services {Chimney services}",
    "chinaware_store": "msg: GMBCID.chinaware_store {Chinaware shop}",
    "chinese_language_instructor": "msg: GMBCID.chinese_language_instructor {Chinese Language Instructor}",
    "chinese_language_school": "msg: GMBCID.chinese_language_school {Chinese language school}",
    "chinese_medicine_store": "msg: GMBCID.chinese_medicine_store {Chinese Medicine Shop}",
    "chinese_noodle_restaurant": "msg: GMBCID.chinese_noodle_restaurant {Chinese noodle restaurant}",
    "chinese_pastry": "msg: GMBCID.chinese_pastry {Chinese bakery}",
    "chinese_restaurant": "msg: GMBCID.chinese_restaurant {Chinese restaurant}",
    "chinese_supermarket": "msg: GMBCID.chinese_supermarket {Chinese supermarket}",
    "chinese_takeaway": "msg: GMBCID.chinese_takeaway {Chinese Takeaway}",
    "chinese_tea_house": "msg: GMBCID.chinese_tea_house {Chinese tea house}",
    "chocolate_artisan": "msg: GMBCID.chocolate_artisan {Chocolate artisan}",
    "chocolate_cafe": "msg: GMBCID.chocolate_cafe {Chocolate cafe}",
    "chocolate_factory": "msg: GMBCID.chocolate_factory {Chocolate Factory}",
    "choir": "msg: GMBCID.choir {Choir}",
    "choir_singer": "msg: GMBCID.choir_singer {Choir Singer}",
    "chop_bar": "msg: GMBCID.chop_bar {Chop bar}",
    "chophouse_restaurant": "msg: GMBCID.chophouse_restaurant {Chophouse restaurant}",
    "christian_book_store": "msg: GMBCID.christian_book_store {Christian Book Shop}",
    "christian_church": "msg: GMBCID.christian_church {Christian church}",
    "christmas_store": "msg: GMBCID.christmas_store {Christmas Shop}",
    "christmas_tree_farm": "msg: GMBCID.christmas_tree_farm {Christmas-Tree Farm}",
    "chrysler_dealer": "msg: GMBCID.chrysler_dealer {Chrysler Dealer}",
    "church": "msg: GMBCID.church {Church}",
    "church_of_christ": "msg: GMBCID.church_of_christ {Church of Christ}",
    "church_of_jesus_christ_of_latter_day_saints": "msg: GMBCID.church_of_jesus_christ_of_latter_day_saints {Church of Jesus Christ of Latter-day Saints (Mormon)}",
    "church_of_the_nazarene": "msg: GMBCID.church_of_the_nazarene {Church of the Nazarene}",
    "church_office": "msg: GMBCID.church_office {Church Office}",
    "churreria": "msg: GMBCID.churreria {Churreria}",
    "cider_bar": "msg: GMBCID.cider_bar {Cider bar}",
    "cider_mill": "msg: GMBCID.cider_mill {Cider Mill}",
    "cig_kofte_restaurant": "msg: GMBCID.cig_kofte_restaurant {Cig kofte restaurant}",
    "cigar_shop": "msg: GMBCID.cigar_shop {Cigar shop}",
    "cinema_equipment_supplier": "msg: GMBCID.cinema_equipment_supplier {Cinema Equipment Supplier}",
    "citizen_information_bureau": "msg: GMBCID.citizen_information_bureau {Citizen Information Bureau}",
    "citizens_advice_bureau": "msg: GMBCID.citizens_advice_bureau {Citizens advice bureau}",
    "citroen_dealer": "msg: GMBCID.citroen_dealer {Citroen Dealer}",
    "city_administration": "msg: GMBCID.city_administration {City Administration}",
    "city_clerks_office": "msg: GMBCID.city_clerks_office {City Clerk's Office}",
    "city_courthouse": "msg: GMBCID.city_courthouse {City Courthouse}",
    "city_department_of_environment": "msg: GMBCID.city_department_of_environment {City Department of Environment}",
    "city_department_of_public_safety": "msg: GMBCID.city_department_of_public_safety {City Department of Public Safety}",
    "city_department_of_transportation": "msg: GMBCID.city_department_of_transportation {City Department of Transportation}",
    "city_district_office": "msg: GMBCID.city_district_office {City District Office}",
    "city_government_office": "msg: GMBCID.city_government_office {City Government Office}",
    "city_hall": "msg: GMBCID.city_hall {City Hall}",
    "city_park": "msg: GMBCID.city_park {City Park}",
    "civic_center": "msg: GMBCID.civic_center {Civic Center}",
    "civil_law_attorney": "msg: GMBCID.civil_law_attorney {Civil law attorney}",
    "clarinetist": "msg: GMBCID.clarinetist {Clarinetist}",
    "class": "msg: GMBCID.class {Class}",
    "classical_musician": "msg: GMBCID.classical_musician {Classical Musician}",
    "cleaners": "msg: GMBCID.cleaners {Cleaners}",
    "cleaning_products_supplier": "msg: GMBCID.cleaning_products_supplier {Cleaning Products Supplier}",
    "clock_repair_service": "msg: GMBCID.clock_repair_service {Clock repair service}",
    "clothes_and_fabric_manufacturer": "msg: GMBCID.clothes_and_fabric_manufacturer {Clothes and Fabric Manufacturer}",
    "clothes_and_fabric_wholesaler": "msg: GMBCID.clothes_and_fabric_wholesaler {Clothes and Fabric Wholesaler}",
    "clothes_market": "msg: GMBCID.clothes_market {Clothes market}",
    "clothing_alteration_service": "msg: GMBCID.clothing_alteration_service {Clothing alteration service}",
    "clothing_store": "msg: GMBCID.clothing_store {Clothing Shop}",
    "clothing_supplier": "msg: GMBCID.clothing_supplier {Clothing Supplier}",
    "clothing_wholesale_market_place": "msg: GMBCID.clothing_wholesale_market_place {Clothing Wholesale Market Place}",
    "clothing_wholesaler": "msg: GMBCID.clothing_wholesaler {Clothing Wholesaler}",
    "club": "msg: GMBCID.club {Club}",
    "cng_station": "msg: GMBCID.cng_station {CNG Station}",
    "coach": "msg: GMBCID.coach {Coach}",
    "coach_therapist": "msg: GMBCID.coach_therapist {Coach Therapist}",
    "coaching_center": "msg: GMBCID.coaching_center {Coaching Center}",
    "coal_exporter": "msg: GMBCID.coal_exporter {Coal Exporter}",
    "coal_supplier": "msg: GMBCID.coal_supplier {Coal Supplier}",
    "coalfield": "msg: GMBCID.coalfield {Coalfield}",
    "coast_guard_station": "msg: GMBCID.coast_guard_station {Coast Guard Station}",
    "coat_wholesaler": "msg: GMBCID.coat_wholesaler {Coat Wholesaler}",
    "cocktail_bar": "msg: GMBCID.cocktail_bar {Cocktail bar}",
    "coffee_machine_supplier": "msg: GMBCID.coffee_machine_supplier {Coffee Machine Supplier}",
    "coffee_roasters": "msg: GMBCID.coffee_roasters {Coffee roasters}",
    "coffee_shop": "msg: GMBCID.coffee_shop {Coffee shop}",
    "coffee_stand": "msg: GMBCID.coffee_stand {Coffee stand}",
    "coffee_vending_machine": "msg: GMBCID.coffee_vending_machine {Coffee Vending Machine}",
    "coffee_wholesaler": "msg: GMBCID.coffee_wholesaler {Coffee Wholesaler}",
    "coffin_supplier": "msg: GMBCID.coffin_supplier {Coffin Supplier}",
    "coin_operated_locker": "msg: GMBCID.coin_operated_locker {Coin Operated Locker}",
    "cold_cut_store": "msg: GMBCID.cold_cut_store {Cold Cut Store}",
    "cold_noodle_restaurant": "msg: GMBCID.cold_noodle_restaurant {Cold noodle restaurant}",
    "cold_storage_facility": "msg: GMBCID.cold_storage_facility {Cold Storage Facility}",
    "collectibles_store": "msg: GMBCID.collectibles_store {Collectibles Shop}",
    "collector_portfolio": "msg: GMBCID.collector_portfolio {Collector Portfolio}",
    "college": "msg: GMBCID.college {College}",
    "colombian_restaurant": "msg: GMBCID.colombian_restaurant {Colombian restaurant}",
    "combined_primary_and_secondary_school": "msg: GMBCID.combined_primary_and_secondary_school {Combined Primary and Secondary School}",
    "comedy_club": "msg: GMBCID.comedy_club {Comedy club}",
    "comic_book_store": "msg: GMBCID.comic_book_store {Comic book shop}",
    "comic_cafe": "msg: GMBCID.comic_cafe {Comic cafe}",
    "comic_show": "msg: GMBCID.comic_show {Comic Show}",
    "commercial_cleaning_service": "msg: GMBCID.commercial_cleaning_service {Commercial cleaning service}",
    "commercial_real_estate_attorney": "msg: GMBCID.commercial_real_estate_attorney {Commercial Real Estate Attorney}",
    "commercial_refrigeration": "msg: GMBCID.commercial_refrigeration {Commercial Refrigeration}",
    "commercial_refrigerator_supplier": "msg: GMBCID.commercial_refrigerator_supplier {Commercial Refrigerator Supplier}",
    "communications_central": "msg: GMBCID.communications_central {Communications Central}",
    "community_college": "msg: GMBCID.community_college {Community College}",
    "community_garden": "msg: GMBCID.community_garden {Community Garden}",
    "community_school": "msg: GMBCID.community_school {Community School}",
    "company": "msg: GMBCID.company {Company}",
    "composer": "msg: GMBCID.composer {Composer}",
    "comprehensive_secondary_school": "msg: GMBCID.comprehensive_secondary_school {Comprehensive Secondary School}",
    "computer_accessories_store": "msg: GMBCID.computer_accessories_store {Computer Accessories Shop}",
    "computer_animation_portfolio": "msg: GMBCID.computer_animation_portfolio {Computer Animation Portfolio}",
    "computer_club": "msg: GMBCID.computer_club {Computer Club}",
    "computer_consultant": "msg: GMBCID.computer_consultant {Computer Consultant}",
    "computer_desk_store": "msg: GMBCID.computer_desk_store {Computer desk store}",
    "computer_hardware_manufacturer": "msg: GMBCID.computer_hardware_manufacturer {Computer Hardware Manufacturer}",
    "computer_repair_service": "msg: GMBCID.computer_repair_service {Computer repair service}",
    "computer_service": "msg: GMBCID.computer_service {Computer service}",
    "computer_software_store": "msg: GMBCID.computer_software_store {Computer Software Shop}",
    "computer_store": "msg: GMBCID.computer_store {Computer Shop}",
    "computer_training_school": "msg: GMBCID.computer_training_school {School of Computing}",
    "computer_wholesaler": "msg: GMBCID.computer_wholesaler {Computer Wholesaler}",
    "concert_hall": "msg: GMBCID.concert_hall {Concert hall}",
    "concrete_contractor": "msg: GMBCID.concrete_contractor {Concrete contractor}",
    "concrete_factory": "msg: GMBCID.concrete_factory {Concrete Factory}",
    "concrete_metal_framework_supplier": "msg: GMBCID.concrete_metal_framework_supplier {Concrete Metal Framework Supplier}",
    "concrete_product_supplier": "msg: GMBCID.concrete_product_supplier {Concrete Product Supplier}",
    "condiments_supplier": "msg: GMBCID.condiments_supplier {Condiments Supplier}",
    "condominium_rental_agency": "msg: GMBCID.condominium_rental_agency {Condominium Rental Agency}",
    "confectionery_wholesaler": "msg: GMBCID.confectionery_wholesaler {Confectionery Wholesaler}",
    "conga_drummer": "msg: GMBCID.conga_drummer {Conga Drummer}",
    "congregation": "msg: GMBCID.congregation {Congregation}",
    "conservation_department": "msg: GMBCID.conservation_department {Environmental Conservation Department}",
    "conservative_club": "msg: GMBCID.conservative_club {Conservative club}",
    "conservative_synagogue": "msg: GMBCID.conservative_synagogue {Conservative synagogue}",
    "consignment_shop": "msg: GMBCID.consignment_shop {Consignment shop}",
    "construction_company": "msg: GMBCID.construction_company {Construction Company}",
    "construction_equipment_supplier": "msg: GMBCID.construction_equipment_supplier {Construction Equipment Supplier}",
    "construction_machine_dealer": "msg: GMBCID.construction_machine_dealer {Construction Machine Dealer}",
    "construction_material_wholesaler": "msg: GMBCID.construction_material_wholesaler {Construction Material Wholesaler}",
    "consultant": "msg: GMBCID.consultant {Consultant}",
    "consulting_firm": "msg: GMBCID.consulting_firm {Consulting Firm}",
    "contact_lenses_supplier": "msg: GMBCID.contact_lenses_supplier {Contact Lenses Supplier}",
    "container_service": "msg: GMBCID.container_service {Container service}",
    "container_supplier": "msg: GMBCID.container_supplier {Container Supplier}",
    "contemporary_louisiana_restaurant": "msg: GMBCID.contemporary_louisiana_restaurant {Contemporary Louisiana restaurant}",
    "continental_restaurant": "msg: GMBCID.continental_restaurant {Continental restaurant}",
    "contrabassoonist": "msg: GMBCID.contrabassoonist {Contrabassoonist}",
    "contralto": "msg: GMBCID.contralto {Contralto}",
    "convenience_store": "msg: GMBCID.convenience_store {Convenience Store}",
    "convention_information_bureau": "msg: GMBCID.convention_information_bureau {Convention information bureau}",
    "conveyancer": "msg: GMBCID.conveyancer {Conveyancer}",
    "cookie_shop": "msg: GMBCID.cookie_shop {Biscuit Shop}",
    "cooking_class": "msg: GMBCID.cooking_class {Cooking Class}",
    "cooking_school": "msg: GMBCID.cooking_school {Cooking school}",
    "cooking_show": "msg: GMBCID.cooking_show {Cooking Show}",
    "cooling_plant": "msg: GMBCID.cooling_plant {Cooling Plant}",
    "copier_repair_service": "msg: GMBCID.copier_repair_service {Copier repair service}",
    "copper_supplier": "msg: GMBCID.copper_supplier {Copper Supplier}",
    "corporate_gift_supplier": "msg: GMBCID.corporate_gift_supplier {Corporate Gift Supplier}",
    "corporate_lawyer": "msg: GMBCID.corporate_lawyer {Corporate Lawyer}",
    "cosmetic_dentist": "msg: GMBCID.cosmetic_dentist {Cosmetic Dentist}",
    "cosmetic_products_manufacturer": "msg: GMBCID.cosmetic_products_manufacturer {Cosmetic Products Manufacturer}",
    "cosmetic_surgeon": "msg: GMBCID.cosmetic_surgeon {Cosmetic surgeon}",
    "cosmetics_and_parfumes_supplier": "msg: GMBCID.cosmetics_and_parfumes_supplier {Cosmetics and Perfumes Supplier}",
    "cosmetics_industry": "msg: GMBCID.cosmetics_industry {Cosmetics industry}",
    "cosmetics_store": "msg: GMBCID.cosmetics_store {Cosmetics shop}",
    "cosmetics_wholesaler": "msg: GMBCID.cosmetics_wholesaler {Cosmetics Wholesaler}",
    "cosplay_cafe": "msg: GMBCID.cosplay_cafe {Cosplay cafe}",
    "costa_rican_restaurant": "msg: GMBCID.costa_rican_restaurant {Costa Rican restaurant}",
    "costume_design_portfolio": "msg: GMBCID.costume_design_portfolio {Costume Design Portfolio}",
    "costume_jewelry_shop": "msg: GMBCID.costume_jewelry_shop {Costume Jewellery Shop}",
    "costume_store": "msg: GMBCID.costume_store {Costume shop}",
    "cottage": "msg: GMBCID.cottage {Cottage}",
    "cottage_rental": "msg: GMBCID.cottage_rental {Cottage Rental}",
    "cottage_village": "msg: GMBCID.cottage_village {Cottage Village}",
    "cotton_exporter": "msg: GMBCID.cotton_exporter {Cotton Exporter}",
    "cotton_mill": "msg: GMBCID.cotton_mill {Cotton Mill}",
    "cotton_supplier": "msg: GMBCID.cotton_supplier {Cotton Supplier}",
    "counselor": "msg: GMBCID.counselor {Counsellor}",
    "countertop_store": "msg: GMBCID.countertop_store {Kitchenware Shop}",
    "country_club": "msg: GMBCID.country_club {Country Club}",
    "country_food_restaurant": "msg: GMBCID.country_food_restaurant {Country food restaurant}",
    "country_house": "msg: GMBCID.country_house {Country House}",
    "country_musician": "msg: GMBCID.country_musician {Country Musician}",
    "country_park": "msg: GMBCID.country_park {Country Park}",
    "courier_service": "msg: GMBCID.courier_service {Courier service}",
    "course_center": "msg: GMBCID.course_center {Course Center}",
    "court_executive_officer": "msg: GMBCID.court_executive_officer {Court executive officer}",
    "couscous_restaurant": "msg: GMBCID.couscous_restaurant {Couscous restaurant}",
    "couture_store": "msg: GMBCID.couture_store {Couture store}",
    "coworking_space": "msg: GMBCID.coworking_space {Co-working Space}",
    "crab_dish_restaurant": "msg: GMBCID.crab_dish_restaurant {Crab restaurant}",
    "craft_hobbyist": "msg: GMBCID.craft_hobbyist {Craft Hobbyist}",
    "craft_store": "msg: GMBCID.craft_store {Craft shop}",
    "crafts_portfolio": "msg: GMBCID.crafts_portfolio {Crafts Portfolio}",
    "cramming_school": "msg: GMBCID.cramming_school {Cramming school}",
    "crane_dealer": "msg: GMBCID.crane_dealer {Crane Dealer}",
    "crane_service": "msg: GMBCID.crane_service {Crane service}",
    "creative_portfolio": "msg: GMBCID.creative_portfolio {Creative Portfolio}",
    "credit_reporting_agency": "msg: GMBCID.credit_reporting_agency {Credit reporting agency}",
    "cremation_service": "msg: GMBCID.cremation_service {Cremation Service}",
    "creole_restaurant": "msg: GMBCID.creole_restaurant {Creole restaurant}",
    "creperie": "msg: GMBCID.creperie {Crêperie}",
    "cricket_club": "msg: GMBCID.cricket_club {Cricket club}",
    "cricket_ground": "msg: GMBCID.cricket_ground {Cricket ground}",
    "cricket_shop": "msg: GMBCID.cricket_shop {Cricket shop}",
    "criminal_defense_attorney": "msg: GMBCID.criminal_defense_attorney {Criminal Defense Attorney}",
    "criminal_law_attorney": "msg: GMBCID.criminal_law_attorney {Criminal Law Attorney}",
    "croatian_restaurant": "msg: GMBCID.croatian_restaurant {Croatian restaurant}",
    "croquet_club": "msg: GMBCID.croquet_club {Croquet club}",
    "cruise_agency": "msg: GMBCID.cruise_agency {Cruise Agency}",
    "crunch_gym": "msg: GMBCID.crunch_gym {Crunch Gym}",
    "crushed_stone_supplier": "msg: GMBCID.crushed_stone_supplier {Crushed Stone Supplier}",
    "cuban_restaurant": "msg: GMBCID.cuban_restaurant {Cuban restaurant}",
    "culinary_competition": "msg: GMBCID.culinary_competition {Culinary Competition}",
    "culinary_school": "msg: GMBCID.culinary_school {Cookery School}",
    "cultural_association": "msg: GMBCID.cultural_association {Cultural association}",
    "cupcake_shop": "msg: GMBCID.cupcake_shop {Cupcake shop}",
    "cupra_dealer": "msg: GMBCID.cupra_dealer {Cupra Dealer}",
    "cured_ham_bar": "msg: GMBCID.cured_ham_bar {Cured ham bar}",
    "cured_ham_store": "msg: GMBCID.cured_ham_store {Cured Ham Shop}",
    "cured_ham_warehouse": "msg: GMBCID.cured_ham_warehouse {Cured Ham Warehouse}",
    "curling_club": "msg: GMBCID.curling_club {Curling Club}",
    "curling_hall": "msg: GMBCID.curling_hall {Curling hall}",
    "currency_exchange_service": "msg: GMBCID.currency_exchange_service {Currency exchange service}",
    "curriculum_vitae": "msg: GMBCID.curriculum_vitae {Curriculum Vitae}",
    "curtain_and_upholstery_cleaner": "msg: GMBCID.curtain_and_upholstery_cleaner {Curtain and upholstery cleaning service}",
    "curtain_store": "msg: GMBCID.curtain_store {Curtain Shop}",
    "curtain_supplier_and_maker": "msg: GMBCID.curtain_supplier_and_maker {Curtain Supplier and Maker}",
    "custom_confiscated_goods_store": "msg: GMBCID.custom_confiscated_goods_store {Custom Confiscated Goods Store}",
    "custom_home_builder": "msg: GMBCID.custom_home_builder {Custom home builder}",
    "custom_label_printer": "msg: GMBCID.custom_label_printer {Custom label printer}",
    "custom_t_shirt_store": "msg: GMBCID.custom_t_shirt_store {Custom T-shirt Shop}",
    "customs_warehouse": "msg: GMBCID.customs_warehouse {Customs Warehouse}",
    "cutlery_store": "msg: GMBCID.cutlery_store {Cutlery Shop}",
    "cv": "msg: GMBCID.cv {CV}",
    "cv_service": "msg: GMBCID.cv_service {CV Service}",
    "cycling_enthusiast": "msg: GMBCID.cycling_enthusiast {Cycling Enthusiast}",
    "czech_restaurant": "msg: GMBCID.czech_restaurant {Czech restaurant}",
    "dacia_dealer": "msg: GMBCID.dacia_dealer {Dacia Dealer}",
    "daihatsu_dealer": "msg: GMBCID.daihatsu_dealer {Daihatsu Dealer}",
    "dairy": "msg: GMBCID.dairy {Dairy}",
    "dairy_farm": "msg: GMBCID.dairy_farm {Dairy Farm}",
    "dairy_farm_equipment_supplier": "msg: GMBCID.dairy_farm_equipment_supplier {Dairy Farm Equipment Supplier}",
    "dairy_store": "msg: GMBCID.dairy_store {Dairy Shop}",
    "dairy_supplier": "msg: GMBCID.dairy_supplier {Dairy Supplier}",
    "dan_dan_noodle_restaurant": "msg: GMBCID.dan_dan_noodle_restaurant {Dan Dan noodle restaurant}",
    "dance_and_performance_show": "msg: GMBCID.dance_and_performance_show {Dance And Performance Show}",
    "dance_competition": "msg: GMBCID.dance_competition {Dance Competition}",
    "dance_instructor": "msg: GMBCID.dance_instructor {Dance Instructor}",
    "dance_restaurant": "msg: GMBCID.dance_restaurant {Dance restaurant}",
    "dance_school": "msg: GMBCID.dance_school {Dance school}",
    "dance_store": "msg: GMBCID.dance_store {Dance Shop}",
    "danish_restaurant": "msg: GMBCID.danish_restaurant {Danish restaurant}",
    "data_entry_service": "msg: GMBCID.data_entry_service {Data Entry Service Provider}",
    "data_recovery_service": "msg: GMBCID.data_recovery_service {Data recovery service}",
    "database_management_company": "msg: GMBCID.database_management_company {Database Management Company}",
    "day_care_center": "msg: GMBCID.day_care_center {Day Care Center}",
    "day_spa": "msg: GMBCID.day_spa {Day spa}",
    "deaf_church": "msg: GMBCID.deaf_church {Deaf church}",
    "deaf_school": "msg: GMBCID.deaf_school {Deaf School}",
    "deaf_service": "msg: GMBCID.deaf_service {Deaf service}",
    "dealer_of_fiat_professional": "msg: GMBCID.dealer_of_fiat_professional {Dealer of Fiat Professional}",
    "debris_removal_service": "msg: GMBCID.debris_removal_service {Debris removal service}",
    "debt_collecting": "msg: GMBCID.debt_collecting {Debt collecting}",
    "debt_collection_agency": "msg: GMBCID.debt_collection_agency {Debt Collection Agency}",
    "decal_supplier": "msg: GMBCID.decal_supplier {Decal Supplier}",
    "deck_builder": "msg: GMBCID.deck_builder {Deck builder}",
    "deli": "msg: GMBCID.deli {Deli}",
    "delivery_chinese_restaurant": "msg: GMBCID.delivery_chinese_restaurant {Delivery Chinese restaurant}",
    "delivery_service": "msg: GMBCID.delivery_service {Delivery service}",
    "demolition_contractor": "msg: GMBCID.demolition_contractor {Demolition Contractor}",
    "denim_wear_store": "msg: GMBCID.denim_wear_store {Jeans shop}",
    "dental_clinic": "msg: GMBCID.dental_clinic {Dental Clinic}",
    "dental_hygienist": "msg: GMBCID.dental_hygienist {Dental Hygienist}",
    "dental_implants_provider": "msg: GMBCID.dental_implants_provider {Dental Implants Provider}",
    "dental_insurance_agency": "msg: GMBCID.dental_insurance_agency {Dental insurance agency}",
    "dental_lab": "msg: GMBCID.dental_lab {Dental Lab}",
    "dental_radiology": "msg: GMBCID.dental_radiology {Dental Radiology}",
    "dental_school": "msg: GMBCID.dental_school {Dental School}",
    "dental_supply_store": "msg: GMBCID.dental_supply_store {Dental Supply Shop}",
    "department_store": "msg: GMBCID.department_store {Department store}",
    "dermatologist": "msg: GMBCID.dermatologist {Dermatologist}",
    "desalination_plant": "msg: GMBCID.desalination_plant {Desalination Plant}",
    "design_agency": "msg: GMBCID.design_agency {Design agency}",
    "design_institute": "msg: GMBCID.design_institute {Design institute}",
    "dessert_restaurant": "msg: GMBCID.dessert_restaurant {Dessert restaurant}",
    "dessert_shop": "msg: GMBCID.dessert_shop {Dessert shop}",
    "developer_portfolio": "msg: GMBCID.developer_portfolio {Developer Portfolio}",
    "deviant_art_portfolio": "msg: GMBCID.deviant_art_portfolio {Deviant Art Portfolio}",
    "dhaba": "msg: GMBCID.dhaba {Dhaba}",
    "dhol_player": "msg: GMBCID.dhol_player {Dhol Player}",
    "diabetes_center": "msg: GMBCID.diabetes_center {Diabetes Centre}",
    "diabetes_equipment_supplier": "msg: GMBCID.diabetes_equipment_supplier {Diabetes Equipment Supplier}",
    "diabetologist": "msg: GMBCID.diabetologist {Diabetologist}",
    "diagnostic_center": "msg: GMBCID.diagnostic_center {Diagnostic Center}",
    "dialysis_center": "msg: GMBCID.dialysis_center {Dialysis Center}",
    "diamond_dealer": "msg: GMBCID.diamond_dealer {Diamond Dealer}",
    "diaper_service": "msg: GMBCID.diaper_service {Nappy Service}",
    "didgeridoo_player": "msg: GMBCID.didgeridoo_player {Didgeridoo Player}",
    "diesel_engine_dealer": "msg: GMBCID.diesel_engine_dealer {Diesel Engine Dealer}",
    "diesel_engine_repair_service": "msg: GMBCID.diesel_engine_repair_service {Diesel engine repair service}",
    "diesel_fuel_supplier": "msg: GMBCID.diesel_fuel_supplier {Diesel Fuel Supplier}",
    "dietitian": "msg: GMBCID.dietitian {Dietitian}",
    "digital_art_portfolio": "msg: GMBCID.digital_art_portfolio {Digital Art Portfolio}",
    "digital_illustration_portfolio": "msg: GMBCID.digital_illustration_portfolio {Digital Illustration Portfolio}",
    "digital_media_portfolio": "msg: GMBCID.digital_media_portfolio {Digital Media Portfolio}",
    "digital_networking_event": "msg: GMBCID.digital_networking_event {Digital Networking Event}",
    "digital_printer": "msg: GMBCID.digital_printer {Digital printer}",
    "digital_printing_service": "msg: GMBCID.digital_printing_service {Digital printing service}",
    "dim_sum_restaurant": "msg: GMBCID.dim_sum_restaurant {Dim Sum restaurant}",
    "diner": "msg: GMBCID.diner {Diner}",
    "direct_mail_advertising": "msg: GMBCID.direct_mail_advertising {Direct mail advertising}",
    "dirt_supplier": "msg: GMBCID.dirt_supplier {Dirt Supplier}",
    "disability_equipment_supplier": "msg: GMBCID.disability_equipment_supplier {Disability Equipment Supplier}",
    "disability_services_and_support_organization": "msg: GMBCID.disability_services_and_support_organization {Disability services and support organization}",
    "disabled_sports_center": "msg: GMBCID.disabled_sports_center {Disabled Sports Center}",
    "disc_golf_course": "msg: GMBCID.disc_golf_course {Disc golf course}",
    "disco": "msg: GMBCID.disco {Night Club}",
    "discount_store": "msg: GMBCID.discount_store {Discount Shop}",
    "discount_supermarket": "msg: GMBCID.discount_supermarket {Discount supermarket}",
    "display_stand_manufacturer": "msg: GMBCID.display_stand_manufacturer {Display Stand Manufacturer}",
    "distance_learning_center": "msg: GMBCID.distance_learning_center {Distance Learning Centre}",
    "dive_club": "msg: GMBCID.dive_club {Diving Club}",
    "dive_shop": "msg: GMBCID.dive_shop {Dive shop}",
    "divorce_attorney": "msg: GMBCID.divorce_attorney {Divorce Attorney}",
    "diy_home_improvement_hobbyist": "msg: GMBCID.diy_home_improvement_hobbyist {Diy Home Improvement Hobbyist}",
    "dj": "msg: GMBCID.dj {DJ}",
    "dj_supply_store": "msg: GMBCID.dj_supply_store {DJ Supply Shop}",
    "do_it_yourself_store": "msg: GMBCID.do_it_yourself_store {DIY Store}",
    "dobro_player": "msg: GMBCID.dobro_player {Dobro Player}",
    "doctor": "msg: GMBCID.doctor {Doctor}",
    "dodge_dealer": "msg: GMBCID.dodge_dealer {Dodge Dealer}",
    "dog_cafe": "msg: GMBCID.dog_cafe {Dog cafe}",
    "dog_park": "msg: GMBCID.dog_park {Dog Park}",
    "dog_trainer": "msg: GMBCID.dog_trainer {Dog Trainer}",
    "dog_walker": "msg: GMBCID.dog_walker {Dog walker}",
    "dogsled_ride_service": "msg: GMBCID.dogsled_ride_service {Dogsled ride service}",
    "dojo_restaurant": "msg: GMBCID.dojo_restaurant {Dojo restaurant}",
    "doll_store": "msg: GMBCID.doll_store {Doll Shop}",
    "dollar_store": "msg: GMBCID.dollar_store {Pound Shop}",
    "dominican_restaurant": "msg: GMBCID.dominican_restaurant {Dominican restaurant}",
    "donations_center": "msg: GMBCID.donations_center {Donations Centre}",
    "doner_kebab_restaurant": "msg: GMBCID.doner_kebab_restaurant {Doner kebab restaurant}",
    "donut_shop": "msg: GMBCID.donut_shop {Doughnut Shop}",
    "door_manufacturer": "msg: GMBCID.door_manufacturer {Door Manufacturer}",
    "door_shop": "msg: GMBCID.door_shop {Door shop}",
    "door_supplier": "msg: GMBCID.door_supplier {Door Supplier}",
    "door_warehouse": "msg: GMBCID.door_warehouse {Door Warehouse}",
    "double_bassist": "msg: GMBCID.double_bassist {Double Bassist}",
    "double_glazing_supplier": "msg: GMBCID.double_glazing_supplier {Double Glazing Supplier}",
    "doula": "msg: GMBCID.doula {Doula}",
    "down_home_cooking_restaurant": "msg: GMBCID.down_home_cooking_restaurant {Southern Comfort Food Restaurant}",
    "drafting_equipment_supplier": "msg: GMBCID.drafting_equipment_supplier {Drafting Equipment Supplier}",
    "drainage_service": "msg: GMBCID.drainage_service {Drainage Service}",
    "drama_school": "msg: GMBCID.drama_school {Drama school}",
    "drawing_lessons": "msg: GMBCID.drawing_lessons {Drawing Lessons}",
    "dress_shop": "msg: GMBCID.dress_shop {Dress Shop}",
    "dressmaker": "msg: GMBCID.dressmaker {Dressmaker}",
    "dried_flower_shop": "msg: GMBCID.dried_flower_shop {Dried flower shop}",
    "dried_seafood_store": "msg: GMBCID.dried_seafood_store {Dried Seafood Shop}",
    "drilling_contractor": "msg: GMBCID.drilling_contractor {Drilling contractor}",
    "drilling_equipment_supplier": "msg: GMBCID.drilling_equipment_supplier {Drilling Equipment Supplier}",
    "drivers_license_training_school": "msg: GMBCID.drivers_license_training_school {Driving Licence Training School}",
    "driveshaft_shop": "msg: GMBCID.driveshaft_shop {Driveshaft shop}",
    "driving_school": "msg: GMBCID.driving_school {Driving school}",
    "drone_music_artist": "msg: GMBCID.drone_music_artist {Drone Music Artist}",
    "drone_shop": "msg: GMBCID.drone_shop {Drone Shop}",
    "drug_testing_service": "msg: GMBCID.drug_testing_service {Drug Testing Service}",
    "drugstore": "msg: GMBCID.drugstore {Drug Shop}",
    "drum_school": "msg: GMBCID.drum_school {Drum school}",
    "drum_store": "msg: GMBCID.drum_store {Drum Shop}",
    "drummer": "msg: GMBCID.drummer {Drummer}",
    "dry_cleaner": "msg: GMBCID.dry_cleaner {Dry cleaner}",
    "dry_fruit_store": "msg: GMBCID.dry_fruit_store {Dry Fruit Shop}",
    "dry_ice_supplier": "msg: GMBCID.dry_ice_supplier {Dry Ice Supplier}",
    "dry_wall_contractor": "msg: GMBCID.dry_wall_contractor {Dry Wall Contractor}",
    "drywall_contractor": "msg: GMBCID.drywall_contractor {Drywall Contractor}",
    "dulcimer_player": "msg: GMBCID.dulcimer_player {Dulcimer Player}",
    "dump_truck_dealer": "msg: GMBCID.dump_truck_dealer {Dump Truck Dealer}",
    "dumpling_restaurant": "msg: GMBCID.dumpling_restaurant {Dumpling restaurant}",
    "durum_restaurant": "msg: GMBCID.durum_restaurant {Durum restaurant}",
    "dutch_restaurant": "msg: GMBCID.dutch_restaurant {Dutch restaurant}",
    "duty_free_store": "msg: GMBCID.duty_free_store {Duty Free Shop}",
    "dvd_store": "msg: GMBCID.dvd_store {DVD Shop}",
    "dye_store": "msg: GMBCID.dye_store {Dye Store}",
    "dynamometer_supplier": "msg: GMBCID.dynamometer_supplier {Dynamometer Supplier}",
    "e_sports_show": "msg: GMBCID.e_sports_show {E Sports Show}",
    "e_sports_tournament": "msg: GMBCID.e_sports_tournament {E Sports Tournament}",
    "ear_piercing_service": "msg: GMBCID.ear_piercing_service {Ear-Piercing Service}",
    "east_african_restaurant": "msg: GMBCID.east_african_restaurant {East African restaurant}",
    "east_javanese_restaurant": "msg: GMBCID.east_javanese_restaurant {East Javanese restaurant}",
    "eastern_european_restaurant": "msg: GMBCID.eastern_european_restaurant {Eastern European restaurant}",
    "eastern_orthodox_church": "msg: GMBCID.eastern_orthodox_church {Eastern Orthodox Church}",
    "eating_disorder_treatment_center": "msg: GMBCID.eating_disorder_treatment_center {Eating Disorder Treatment Center}",
    "eclectic_restaurant": "msg: GMBCID.eclectic_restaurant {Eclectic restaurant}",
    "ecological_park": "msg: GMBCID.ecological_park {Ecological park}",
    "ecologists_association": "msg: GMBCID.ecologists_association {Ecologists association}",
    "ecommerce": "msg: GMBCID.ecommerce {Ecommerce}",
    "economic_consultant": "msg: GMBCID.economic_consultant {Economic Consultant}",
    "ecuadorian_restaurant": "msg: GMBCID.ecuadorian_restaurant {Ecuadorian restaurant}",
    "education_center": "msg: GMBCID.education_center {Education Centre}",
    "educational_consultant": "msg: GMBCID.educational_consultant {Educational consultant}",
    "educational_institution": "msg: GMBCID.educational_institution {Educational Institution}",
    "educational_project": "msg: GMBCID.educational_project {Educational Project}",
    "educational_supply_store": "msg: GMBCID.educational_supply_store {Educational Supply Shop}",
    "educational_testing_service": "msg: GMBCID.educational_testing_service {Educational testing service}",
    "eftpos_equipment_supplier": "msg: GMBCID.eftpos_equipment_supplier {EFTPOS Equipment Supplier}",
    "egg_supplier": "msg: GMBCID.egg_supplier {Egg Supplier}",
    "egyptian_restaurant": "msg: GMBCID.egyptian_restaurant {Egyptian restaurant}",
    "electric_motor_scooter_dealer": "msg: GMBCID.electric_motor_scooter_dealer {Electric Motor Scooter Dealer}",
    "electric_motor_store": "msg: GMBCID.electric_motor_store {Electric Motor Shop}",
    "electric_motor_vehicle_dealer": "msg: GMBCID.electric_motor_vehicle_dealer {Electric Motor Vehicle Dealer}",
    "electric_motorcycle_dealer": "msg: GMBCID.electric_motorcycle_dealer {Electric Motorcycle Dealer}",
    "electric_utility_manufacturer": "msg: GMBCID.electric_utility_manufacturer {Electric Utility Manufacturer}",
    "electric_vehicle_charging_station_contractor": "msg: GMBCID.electric_vehicle_charging_station_contractor {Electric vehicle charging station contractor}",
    "electrical_appliance_wholesaler": "msg: GMBCID.electrical_appliance_wholesaler {Electrical Appliance Wholesaler}",
    "electrical_engineer": "msg: GMBCID.electrical_engineer {Electrical engineer}",
    "electrical_equipment_supplier": "msg: GMBCID.electrical_equipment_supplier {Electrical Equipment Supplier}",
    "electrical_installation_service": "msg: GMBCID.electrical_installation_service {Electrical installation service}",
    "electrical_supply_store": "msg: GMBCID.electrical_supply_store {Electrical supply shop}",
    "electrical_wholesaler": "msg: GMBCID.electrical_wholesaler {Electrical Wholesaler}",
    "electrician": "msg: GMBCID.electrician {Electrician}",
    "electrolysis_hair_removal_service": "msg: GMBCID.electrolysis_hair_removal_service {Electrolysis hair removal service}",
    "electronic_dance_music_edm_artist": "msg: GMBCID.electronic_dance_music_edm_artist {Electronic Dance Music Edm Artist}",
    "electronic_music_artist": "msg: GMBCID.electronic_music_artist {Electronic Music Artist}",
    "electronic_parts_supplier": "msg: GMBCID.electronic_parts_supplier {Electronic Parts Supplier}",
    "electronics_accessories_wholesaler": "msg: GMBCID.electronics_accessories_wholesaler {Electronics Accessories Wholesaler}",
    "electronics_company": "msg: GMBCID.electronics_company {Electronics Company}",
    "electronics_exporter": "msg: GMBCID.electronics_exporter {Electronics Exporter}",
    "electronics_manufacturer": "msg: GMBCID.electronics_manufacturer {Electronics Manufacturer}",
    "electronics_store": "msg: GMBCID.electronics_store {Electronics Shop}",
    "electronics_wholesaler": "msg: GMBCID.electronics_wholesaler {Electronics Wholesaler}",
    "elementary_school": "msg: GMBCID.elementary_school {Elementary School}",
    "elevator_service": "msg: GMBCID.elevator_service {Lift Service}",
    "embossing_service": "msg: GMBCID.embossing_service {Embossing Service}",
    "embroidery_service": "msg: GMBCID.embroidery_service {Embroidery service}",
    "embroidery_shop": "msg: GMBCID.embroidery_shop {Embroidery shop}",
    "emergency_care_physician": "msg: GMBCID.emergency_care_physician {Emergency Care Physician}",
    "emergency_care_service": "msg: GMBCID.emergency_care_service {Emergency Care Service}",
    "emergency_dental_service": "msg: GMBCID.emergency_dental_service {Emergency Dental Service}",
    "emergency_locksmith_service": "msg: GMBCID.emergency_locksmith_service {Emergency locksmith service}",
    "emergency_room": "msg: GMBCID.emergency_room {Emergency Room}",
    "emergency_training_school": "msg: GMBCID.emergency_training_school {Emergency training school}",
    "emergency_veterinarian_service": "msg: GMBCID.emergency_veterinarian_service {Emergency Veterinarian Service}",
    "employment_attorney": "msg: GMBCID.employment_attorney {Employment Attorney}",
    "endocrinologist": "msg: GMBCID.endocrinologist {Endocrinologist}",
    "endodontist": "msg: GMBCID.endodontist {Endodontist}",
    "endoscopist": "msg: GMBCID.endoscopist {Endoscopist}",
    "energy_equipment_and_solutions": "msg: GMBCID.energy_equipment_and_solutions {Energy Equipment and Solutions}",
    "energy_supplier": "msg: GMBCID.energy_supplier {Energy Supplier}",
    "engagement": "msg: GMBCID.engagement {Engagement}",
    "engine_rebuilding_service": "msg: GMBCID.engine_rebuilding_service {Engine Rebuilding Service}",
    "engineering_consultant": "msg: GMBCID.engineering_consultant {Engineering consultant}",
    "engineering_school": "msg: GMBCID.engineering_school {Engineering School}",
    "english_horn_player": "msg: GMBCID.english_horn_player {English Horn Player}",
    "english_language_camp": "msg: GMBCID.english_language_camp {English language camp}",
    "english_language_instructor": "msg: GMBCID.english_language_instructor {English Language Instructor}",
    "english_language_school": "msg: GMBCID.english_language_school {English language school}",
    "english_restaurant": "msg: GMBCID.english_restaurant {English restaurant}",
    "engraver": "msg: GMBCID.engraver {Engraver}",
    "envelope_supplier": "msg: GMBCID.envelope_supplier {Envelope Supplier}",
    "environmental_and_conservation_event": "msg: GMBCID.environmental_and_conservation_event {Environmental And Conservation Event}",
    "environmental_engineer": "msg: GMBCID.environmental_engineer {Environmental engineer}",
    "environmental_health_service": "msg: GMBCID.environmental_health_service {Environmental Health Service}",
    "environmental_organization": "msg: GMBCID.environmental_organization {Environmental organization}",
    "environmental_protection_organization": "msg: GMBCID.environmental_protection_organization {Environmental Protection Organization}",
    "episcopal_church": "msg: GMBCID.episcopal_church {Church of England Church}",
    "equestrian_club": "msg: GMBCID.equestrian_club {Equestrian Club}",
    "equestrian_store": "msg: GMBCID.equestrian_store {Equestrian Shop}",
    "equipment_exporter": "msg: GMBCID.equipment_exporter {Equipment Exporter}",
    "equipment_importer": "msg: GMBCID.equipment_importer {Equipment Importer}",
    "equipment_supplier": "msg: GMBCID.equipment_supplier {Equipment Supplier}",
    "erhu_player": "msg: GMBCID.erhu_player {Erhu Player}",
    "eritrean_restaurant": "msg: GMBCID.eritrean_restaurant {Eritrean restaurant}",
    "espresso_bar": "msg: GMBCID.espresso_bar {Espresso bar}",
    "estate_planning_attorney": "msg: GMBCID.estate_planning_attorney {Estate Planning Attorney}",
    "ethiopian_restaurant": "msg: GMBCID.ethiopian_restaurant {Ethiopian restaurant}",
    "ethnic_restaurant": "msg: GMBCID.ethnic_restaurant {Ethnic restaurant}",
    "ethnographic_museum": "msg: GMBCID.ethnographic_museum {Ethnographic museum}",
    "euphonium_player": "msg: GMBCID.euphonium_player {Euphonium Player}",
    "european_institution": "msg: GMBCID.european_institution {European Institution}",
    "european_restaurant": "msg: GMBCID.european_restaurant {European restaurant}",
    "evangelical_church": "msg: GMBCID.evangelical_church {Evangelical church}",
    "evening_class": "msg: GMBCID.evening_class {Evening Class}",
    "evening_school": "msg: GMBCID.evening_school {Evening School}",
    "event": "msg: GMBCID.event {Event}",
    "event_planner_portfolio": "msg: GMBCID.event_planner_portfolio {Event Planner Portfolio}",
    "executive_search_firm": "msg: GMBCID.executive_search_firm {Executive search firm}",
    "executive_suite_rental_agency": "msg: GMBCID.executive_suite_rental_agency {Executive Suite Rental Agency}",
    "exhibit": "msg: GMBCID.exhibit {Exhibit}",
    "exhibition_and_trade_center": "msg: GMBCID.exhibition_and_trade_center {Exhibition and trade centre}",
    "experimental_musician": "msg: GMBCID.experimental_musician {Experimental Musician}",
    "extended_stay_hotel": "msg: GMBCID.extended_stay_hotel {Extended Stay Hotel}",
    "extraction_supplier_company": "msg: GMBCID.extraction_supplier_company {Extraction Supplier Company}",
    "extremadurian_restaurant": "msg: GMBCID.extremadurian_restaurant {Extremaduran restaurant}",
    "eye_care_center": "msg: GMBCID.eye_care_center {Eye Care Center}",
    "eyebrow_bar": "msg: GMBCID.eyebrow_bar {Eyebrow bar}",
    "eyebrow_master_portfolio": "msg: GMBCID.eyebrow_master_portfolio {Eyebrow Master Portfolio}",
    "eyelash_salon": "msg: GMBCID.eyelash_salon {Eyelash salon}",
    "fabric_product_manufacturer": "msg: GMBCID.fabric_product_manufacturer {Fabric Product Manufacturer}",
    "fabric_store": "msg: GMBCID.fabric_store {Fabric Shop}",
    "fabric_wholesaler": "msg: GMBCID.fabric_wholesaler {Fabric Wholesaler}",
    "fabrication_engineer": "msg: GMBCID.fabrication_engineer {Fabrication Engineer}",
    "facial_spa": "msg: GMBCID.facial_spa {Facial spa}",
    "factory_equipment_supplier": "msg: GMBCID.factory_equipment_supplier {Factory Equipment Supplier}",
    "faculty_of_arts": "msg: GMBCID.faculty_of_arts {Faculty of Arts}",
    "faculty_of_law": "msg: GMBCID.faculty_of_law {Faculty of Law}",
    "faculty_of_pharmacy": "msg: GMBCID.faculty_of_pharmacy {Faculty of Pharmacy}",
    "faculty_of_psychology": "msg: GMBCID.faculty_of_psychology {Faculty of Psychology}",
    "faculty_of_science": "msg: GMBCID.faculty_of_science {Faculty of Science}",
    "faculty_of_sports": "msg: GMBCID.faculty_of_sports {Faculty of Sports}",
    "fair_trade_organization": "msg: GMBCID.fair_trade_organization {Fair Trade Organization}",
    "fairground": "msg: GMBCID.fairground {Fairground}",
    "falafel_restaurant": "msg: GMBCID.falafel_restaurant {Falafel restaurant}",
    "family_and_couple_portfolio": "msg: GMBCID.family_and_couple_portfolio {Family And Couple Portfolio}",
    "family_counselor": "msg: GMBCID.family_counselor {Family counsellor}",
    "family_day_care_service": "msg: GMBCID.family_day_care_service {Family day care service}",
    "family_law_attorney": "msg: GMBCID.family_law_attorney {Family Law Attorney}",
    "family_planning_center": "msg: GMBCID.family_planning_center {Family Planning Center}",
    "family_planning_counselor": "msg: GMBCID.family_planning_counselor {Family Planning Counselor}",
    "family_practice_physician": "msg: GMBCID.family_practice_physician {Family Practice Physician}",
    "family_restaurant": "msg: GMBCID.family_restaurant {Family restaurant}",
    "family_trip": "msg: GMBCID.family_trip {Family Trip}",
    "family_website": "msg: GMBCID.family_website {Family Website}",
    "farewell_party": "msg: GMBCID.farewell_party {Farewell Party}",
    "farm_equipment_repair_service": "msg: GMBCID.farm_equipment_repair_service {Farm Equipment Repair Service}",
    "farm_equipment_supplier": "msg: GMBCID.farm_equipment_supplier {Farm Equipment Supplier}",
    "farm_school": "msg: GMBCID.farm_school {Farm school}",
    "farm_shop": "msg: GMBCID.farm_shop {Farm shop}",
    "farmstay": "msg: GMBCID.farmstay {Farm Stay}",
    "fashion_accessories_store": "msg: GMBCID.fashion_accessories_store {Fashion Accessories Shop}",
    "fashion_design_school": "msg: GMBCID.fashion_design_school {Fashion design school}",
    "fashion_designer": "msg: GMBCID.fashion_designer {Fashion designer}",
    "fashion_designer_portfolio": "msg: GMBCID.fashion_designer_portfolio {Fashion Designer Portfolio}",
    "fashion_enthusiast": "msg: GMBCID.fashion_enthusiast {Fashion Enthusiast}",
    "fashion_photography_portfolio": "msg: GMBCID.fashion_photography_portfolio {Fashion Photography Portfolio}",
    "fashion_portfolio": "msg: GMBCID.fashion_portfolio {Fashion Portfolio}",
    "fast_food_restaurant": "msg: GMBCID.fast_food_restaurant {Fast food restaurant}",
    "fastener_supplier": "msg: GMBCID.fastener_supplier {Fastener Supplier}",
    "fax_service": "msg: GMBCID.fax_service {Fax service}",
    "feed_manufacturer": "msg: GMBCID.feed_manufacturer {Feed Manufacturer}",
    "feed_store": "msg: GMBCID.feed_store {Animal feed shop}",
    "felt_boots_store": "msg: GMBCID.felt_boots_store {Felt Boots Shop}",
    "fence_contractor": "msg: GMBCID.fence_contractor {Fence contractor}",
    "fencing_school": "msg: GMBCID.fencing_school {Fencing school}",
    "feng_shui_shop": "msg: GMBCID.feng_shui_shop {Feng Shui Shop}",
    "ferrari_dealer": "msg: GMBCID.ferrari_dealer {Ferrari Dealer}",
    "ferry_service": "msg: GMBCID.ferry_service {Ferry service}",
    "fertility_clinic": "msg: GMBCID.fertility_clinic {Fertility Clinic}",
    "fertility_physician": "msg: GMBCID.fertility_physician {Fertility Physician}",
    "fertilizer_supplier": "msg: GMBCID.fertilizer_supplier {Fertilizer Supplier}",
    "festival": "msg: GMBCID.festival {Festival}",
    "festival_hall": "msg: GMBCID.festival_hall {Festival hall}",
    "fiat_dealer": "msg: GMBCID.fiat_dealer {Fiat Dealer}",
    "fiber_optic_products_supplier": "msg: GMBCID.fiber_optic_products_supplier {Fiber Optic Products Supplier}",
    "fiberglass_repair_service": "msg: GMBCID.fiberglass_repair_service {Fiberglass Repair Service}",
    "fiberglass_supplier": "msg: GMBCID.fiberglass_supplier {Fiberglass Supplier}",
    "fiddler": "msg: GMBCID.fiddler {Fiddler}",
    "figurine_shop": "msg: GMBCID.figurine_shop {Figurine shop}",
    "filipino_grocery_store": "msg: GMBCID.filipino_grocery_store {Filipino grocery store}",
    "filipino_restaurant": "msg: GMBCID.filipino_restaurant {Filipino restaurant}",
    "film_and_photograph_library": "msg: GMBCID.film_and_photograph_library {Film and Photograph Library}",
    "film_production_company": "msg: GMBCID.film_production_company {Film Production Company}",
    "film_student_portfolio": "msg: GMBCID.film_student_portfolio {Film Student Portfolio}",
    "film_tvseries_binging": "msg: GMBCID.film_tvseries_binging {Film TV series Binging}",
    "financial_advisor": "msg: GMBCID.financial_advisor {Financial Advisor}",
    "financial_consultant": "msg: GMBCID.financial_consultant {Financial Consultant}",
    "financial_institution": "msg: GMBCID.financial_institution {Financial Institution}",
    "financial_planner": "msg: GMBCID.financial_planner {Financial Planner}",
    "fine_dining_restaurant": "msg: GMBCID.fine_dining_restaurant {Fine dining restaurant}",
    "fingerprinting_service": "msg: GMBCID.fingerprinting_service {Fingerprinting Service}",
    "finishing_materials_supplier": "msg: GMBCID.finishing_materials_supplier {Finishing Materials Supplier}",
    "finnish_restaurant": "msg: GMBCID.finnish_restaurant {Finnish restaurant}",
    "fire_alarm_supplier": "msg: GMBCID.fire_alarm_supplier {Fire Alarm Supplier}",
    "fire_damage_restoration_service": "msg: GMBCID.fire_damage_restoration_service {Fire damage restoration service}",
    "fire_department_equipment_supplier": "msg: GMBCID.fire_department_equipment_supplier {Fire Department Equipment Supplier}",
    "fire_protection_equipment_supplier": "msg: GMBCID.fire_protection_equipment_supplier {Fire Protection Equipment Supplier}",
    "fire_protection_service": "msg: GMBCID.fire_protection_service {Fire protection service}",
    "fire_protection_system_supplier": "msg: GMBCID.fire_protection_system_supplier {Fire Protection System Supplier}",
    "fireplace_manufacturer": "msg: GMBCID.fireplace_manufacturer {Fireplace Manufacturer}",
    "fireplace_store": "msg: GMBCID.fireplace_store {Fireplace shop}",
    "firewood_supplier": "msg: GMBCID.firewood_supplier {Firewood Supplier}",
    "fireworks_store": "msg: GMBCID.fireworks_store {Fireworks shop}",
    "fireworks_supplier": "msg: GMBCID.fireworks_supplier {Fireworks Supplier}",
    "first_aid": "msg: GMBCID.first_aid {First Aid}",
    "fish_and_chips_restaurant": "msg: GMBCID.fish_and_chips_restaurant {Fish & chips shop}",
    "fish_and_chips_takeaway": "msg: GMBCID.fish_and_chips_takeaway {Fish and chips takeaway}",
    "fish_farm": "msg: GMBCID.fish_farm {Fish Farm}",
    "fish_processing": "msg: GMBCID.fish_processing {Fish Processing}",
    "fish_restaurant": "msg: GMBCID.fish_restaurant {Fish restaurant}",
    "fish_spa": "msg: GMBCID.fish_spa {Fish spa}",
    "fish_store": "msg: GMBCID.fish_store {Fish Shop}",
    "fishing_club": "msg: GMBCID.fishing_club {Fishing club}",
    "fishing_enthusiast": "msg: GMBCID.fishing_enthusiast {Fishing Enthusiast}",
    "fishing_hobbyist": "msg: GMBCID.fishing_hobbyist {Fishing Hobbyist}",
    "fishing_pond": "msg: GMBCID.fishing_pond {Fishing Pond}",
    "fishing_store": "msg: GMBCID.fishing_store {Fishing Shop}",
    "fitness_center": "msg: GMBCID.fitness_center {Fitness centre}",
    "fitness_equipment_store": "msg: GMBCID.fitness_equipment_store {Exercise Equipment Shop}",
    "fitness_equipment_wholesaler": "msg: GMBCID.fitness_equipment_wholesaler {Fitness Equipment Wholesaler}",
    "fitness_freak": "msg: GMBCID.fitness_freak {Fitness Freak}",
    "fixed_base_operator": "msg: GMBCID.fixed_base_operator {Fixed-base operator}",
    "flag_store": "msg: GMBCID.flag_store {Flag Shop}",
    "flamenco_dance_store": "msg: GMBCID.flamenco_dance_store {Flamenco dance store}",
    "flamenco_guitarist": "msg: GMBCID.flamenco_guitarist {Flamenco Guitarist}",
    "flamenco_school": "msg: GMBCID.flamenco_school {Flamenco school}",
    "flamenco_theater": "msg: GMBCID.flamenco_theater {Flamenco Theatre}",
    "flavours_fragrances_and_aroma_supplier": "msg: GMBCID.flavours_fragrances_and_aroma_supplier {Flavours Fragrances and Aroma Supplier}",
    "flight_school": "msg: GMBCID.flight_school {Flight school}",
    "floor_refinishing_service": "msg: GMBCID.floor_refinishing_service {Floor refinishing service}",
    "floor_sanding_and_polishing_service": "msg: GMBCID.floor_sanding_and_polishing_service {Floor sanding and polishing service}",
    "flooring_contractor": "msg: GMBCID.flooring_contractor {Flooring contractor}",
    "flooring_store": "msg: GMBCID.flooring_store {Flooring Shop}",
    "floridian_restaurant": "msg: GMBCID.floridian_restaurant {Floridian restaurant}",
    "florist": "msg: GMBCID.florist {Florist}",
    "flour_mill": "msg: GMBCID.flour_mill {Flour Mill}",
    "flower_designer": "msg: GMBCID.flower_designer {Flower designer}",
    "flutist": "msg: GMBCID.flutist {Flutist}",
    "fmcg_goods_wholesaler": "msg: GMBCID.fmcg_goods_wholesaler {FMCG Goods Wholesaler}",
    "fmcg_manufacturer": "msg: GMBCID.fmcg_manufacturer {FMCG Manufacturer}",
    "foam_rubber_producer": "msg: GMBCID.foam_rubber_producer {Foam Rubber Producer}",
    "foam_rubber_supplier": "msg: GMBCID.foam_rubber_supplier {Foam Rubber Supplier}",
    "foie_gras_producer": "msg: GMBCID.foie_gras_producer {Foie Gras Producer}",
    "folk_high_school": "msg: GMBCID.folk_high_school {Folk High School}",
    "folk_musician": "msg: GMBCID.folk_musician {Folk Musician}",
    "fondue_restaurant": "msg: GMBCID.fondue_restaurant {Fondue restaurant}",
    "food_and_beverage_exporter": "msg: GMBCID.food_and_beverage_exporter {Food and Beverage Exporter}",
    "food_bank": "msg: GMBCID.food_bank {Foodbank}",
    "food_broker": "msg: GMBCID.food_broker {Food broker}",
    "food_cooking_enthusiast": "msg: GMBCID.food_cooking_enthusiast {Food Cooking Enthusiast}",
    "food_machinery_supplier": "msg: GMBCID.food_machinery_supplier {Food Machinery Supplier}",
    "food_manufacturer": "msg: GMBCID.food_manufacturer {Food Manufacturer}",
    "food_manufacturing_supply": "msg: GMBCID.food_manufacturing_supply {Food Manufacturing Supply}",
    "food_photography_portfolio": "msg: GMBCID.food_photography_portfolio {Food Photography Portfolio}",
    "food_processing_company": "msg: GMBCID.food_processing_company {Food Processing Company}",
    "food_processing_equipment": "msg: GMBCID.food_processing_equipment {Food Processing Equipment}",
    "food_producer": "msg: GMBCID.food_producer {Food Producer}",
    "food_products_supplier": "msg: GMBCID.food_products_supplier {Food Products Supplier}",
    "food_seasoning_manufacturer": "msg: GMBCID.food_seasoning_manufacturer {Food Seasoning Manufacturer}",
    "foot_care": "msg: GMBCID.foot_care {Foot Care}",
    "foot_massage_parlor": "msg: GMBCID.foot_massage_parlor {Foot Massage Parlour}",
    "football_club": "msg: GMBCID.football_club {Football Club}",
    "football_field": "msg: GMBCID.football_field {Football Field}",
    "ford_dealer": "msg: GMBCID.ford_dealer {Ford Dealer}",
    "foreign_exchange_students_organization": "msg: GMBCID.foreign_exchange_students_organization {Foreign Exchange Students Organization}",
    "foreign_languages_program_school": "msg: GMBCID.foreign_languages_program_school {Foreign Languages Program School}",
    "foreign_trade_consultant": "msg: GMBCID.foreign_trade_consultant {Foreign Trade Consultant}",
    "foreman_builders_association": "msg: GMBCID.foreman_builders_association {Foreman builders association}",
    "forensic_consultant": "msg: GMBCID.forensic_consultant {Forensic Consultant}",
    "forestry_service": "msg: GMBCID.forestry_service {Forestry service}",
    "forklift_dealer": "msg: GMBCID.forklift_dealer {Forklift Dealer}",
    "formal_clothing_store": "msg: GMBCID.formal_clothing_store {Formal Wear Shop}",
    "foundation": "msg: GMBCID.foundation {Foundation}",
    "foundry": "msg: GMBCID.foundry {Foundry}",
    "fountain_contractor": "msg: GMBCID.fountain_contractor {Fountain contractor}",
    "foursquare_church": "msg: GMBCID.foursquare_church {Foursquare church}",
    "franconian_restaurant": "msg: GMBCID.franconian_restaurant {Franconian restaurant}",
    "fraternal_organization": "msg: GMBCID.fraternal_organization {Fraternal organization}",
    "free_clinic": "msg: GMBCID.free_clinic {Free Clinic}",
    "freight_forwarding_service": "msg: GMBCID.freight_forwarding_service {Freight Forwarding Service}",
    "french_horn_player": "msg: GMBCID.french_horn_player {French Horn Player}",
    "french_language_school": "msg: GMBCID.french_language_school {French language school}",
    "french_restaurant": "msg: GMBCID.french_restaurant {French restaurant}",
    "french_steakhouse_restaurant": "msg: GMBCID.french_steakhouse_restaurant {French steak restaurant}",
    "fried_chicken_takeaway": "msg: GMBCID.fried_chicken_takeaway {Fried chicken takeaway}",
    "friends_church": "msg: GMBCID.friends_church {Friends church}",
    "frozen_dessert_supplier": "msg: GMBCID.frozen_dessert_supplier {Frozen Dessert Supplier}",
    "frozen_food_manufacturer": "msg: GMBCID.frozen_food_manufacturer {Frozen Food Manufacturer}",
    "frozen_food_store": "msg: GMBCID.frozen_food_store {Frozen food store}",
    "fruit_and_vegetable_store": "msg: GMBCID.fruit_and_vegetable_store {Fruit and Vegetable Shop}",
    "fruit_and_vegetable_wholesaler": "msg: GMBCID.fruit_and_vegetable_wholesaler {Fruit and Vegetable Wholesaler}",
    "fruit_parlor": "msg: GMBCID.fruit_parlor {Fruit Parlour}",
    "fruit_wholesaler": "msg: GMBCID.fruit_wholesaler {Fruit Wholesaler}",
    "fu_jian_restaurant": "msg: GMBCID.fu_jian_restaurant {Fujian restaurant}",
    "fuel_supplier": "msg: GMBCID.fuel_supplier {Fuel Supplier}",
    "fugu_restaurant": "msg: GMBCID.fugu_restaurant {Fugu restaurant}",
    "full_gospel_church": "msg: GMBCID.full_gospel_church {Full Gospel church}",
    "fund_management_company": "msg: GMBCID.fund_management_company {Fund management company}",
    "fundraising_project": "msg: GMBCID.fundraising_project {Fundraising Project}",
    "funeral_celebrant_service": "msg: GMBCID.funeral_celebrant_service {Funeral celebrant service}",
    "fur_coat_shop": "msg: GMBCID.fur_coat_shop {Fur coat shop}",
    "fur_manufacturer": "msg: GMBCID.fur_manufacturer {Fur Manufacturer}",
    "fur_service": "msg: GMBCID.fur_service {Fur service}",
    "furnace_parts_supplier": "msg: GMBCID.furnace_parts_supplier {Furnace Parts Supplier}",
    "furnace_repair_service": "msg: GMBCID.furnace_repair_service {Furnace Repair Service}",
    "furnace_store": "msg: GMBCID.furnace_store {Furnace Shop}",
    "furnished_apartment_building": "msg: GMBCID.furnished_apartment_building {Furnished Apartment Building}",
    "furniture_accessories_supplier": "msg: GMBCID.furniture_accessories_supplier {Furniture accessories supplier}",
    "furniture_maker": "msg: GMBCID.furniture_maker {Furniture maker}",
    "furniture_manufacturer": "msg: GMBCID.furniture_manufacturer {Furniture Manufacturer}",
    "furniture_store": "msg: GMBCID.furniture_store {Home Furniture Shop}",
    "furniture_wholesaler": "msg: GMBCID.furniture_wholesaler {Furniture Wholesaler}",
    "fusion_restaurant": "msg: GMBCID.fusion_restaurant {Fusion restaurant}",
    "futon_store": "msg: GMBCID.futon_store {Futon store}",
    "futsal_field": "msg: GMBCID.futsal_field {Futsal court}",
    "galician_restaurant": "msg: GMBCID.galician_restaurant {Galician restaurant}",
    "gambling_instructor": "msg: GMBCID.gambling_instructor {Gambling Instructor}",
    "game_designer_portfolio": "msg: GMBCID.game_designer_portfolio {Game Designer Portfolio}",
    "game_store": "msg: GMBCID.game_store {Game Shop}",
    "gamer": "msg: GMBCID.gamer {Gamer}",
    "garage_door_supplier": "msg: GMBCID.garage_door_supplier {Garage Door Supplier}",
    "garbage_collection_service": "msg: GMBCID.garbage_collection_service {Waste Collection Service}",
    "garbage_dump_service": "msg: GMBCID.garbage_dump_service {Rubbish Dump Service}",
    "garden": "msg: GMBCID.garden {Garden}",
    "garden_furniture_store": "msg: GMBCID.garden_furniture_store {Garden Furniture Shop}",
    "garden_machinery": "msg: GMBCID.garden_machinery {Garden Machinery}",
    "gardener": "msg: GMBCID.gardener {Gardener}",
    "gardening_enthusiast": "msg: GMBCID.gardening_enthusiast {Gardening Enthusiast}",
    "gardening_project": "msg: GMBCID.gardening_project {Gardening Project}",
    "gas_cylinders_supplier": "msg: GMBCID.gas_cylinders_supplier {Gas Cylinders Supplier}",
    "gas_installation_service": "msg: GMBCID.gas_installation_service {Gas installation service}",
    "gas_logs_supplier": "msg: GMBCID.gas_logs_supplier {Gas Logs Supplier}",
    "gas_shop": "msg: GMBCID.gas_shop {Gas shop}",
    "gasket_manufacturer": "msg: GMBCID.gasket_manufacturer {Gasket Manufacturer}",
    "gastropub": "msg: GMBCID.gastropub {Gastropub}",
    "gay_and_lesbian_organization": "msg: GMBCID.gay_and_lesbian_organization {Gay & Lesbian Organisation}",
    "gay_bar": "msg: GMBCID.gay_bar {Gay bar}",
    "gay_night_club": "msg: GMBCID.gay_night_club {Gay night club}",
    "gemologist": "msg: GMBCID.gemologist {Gemologist}",
    "general_education_school": "msg: GMBCID.general_education_school {General Education School}",
    "general_hospital": "msg: GMBCID.general_hospital {General Hospital}",
    "general_practice_attorney": "msg: GMBCID.general_practice_attorney {General Practice Attorney}",
    "general_practitioner": "msg: GMBCID.general_practitioner {General Practitioner}",
    "general_store": "msg: GMBCID.general_store {General Store}",
    "generator_shop": "msg: GMBCID.generator_shop {Generator Shop}",
    "genesis_dealer": "msg: GMBCID.genesis_dealer {Genesis Dealer}",
    "geography_and_history_faculty": "msg: GMBCID.geography_and_history_faculty {Geography and History Faculty}",
    "geological_research_company": "msg: GMBCID.geological_research_company {Geological research company}",
    "geological_service": "msg: GMBCID.geological_service {Geological service}",
    "geologist": "msg: GMBCID.geologist {Geologist}",
    "georgian_restaurant": "msg: GMBCID.georgian_restaurant {Georgian restaurant}",
    "geotechnical_engineer": "msg: GMBCID.geotechnical_engineer {Geotechnical Engineer}",
    "geriatrician": "msg: GMBCID.geriatrician {Geriatrician}",
    "german_language_school": "msg: GMBCID.german_language_school {German language school}",
    "german_restaurant": "msg: GMBCID.german_restaurant {German restaurant}",
    "ghazal_singer": "msg: GMBCID.ghazal_singer {Ghazal Singer}",
    "gift_basket_store": "msg: GMBCID.gift_basket_store {Gift basket shop}",
    "gift_shop": "msg: GMBCID.gift_shop {Gift shop}",
    "gift_wrap_store": "msg: GMBCID.gift_wrap_store {Wrapping Paper Shop}",
    "girl_bar": "msg: GMBCID.girl_bar {Girl bar}",
    "girls_secondary_school": "msg: GMBCID.girls_secondary_school {Girl's Secondary School}",
    "glass_and_mirror_shop": "msg: GMBCID.glass_and_mirror_shop {Glass & mirror shop}",
    "glass_block_supplier": "msg: GMBCID.glass_block_supplier {Glass Block Supplier}",
    "glass_blower": "msg: GMBCID.glass_blower {Glass blower}",
    "glass_cutting_service": "msg: GMBCID.glass_cutting_service {Glass cutting service}",
    "glass_engraving": "msg: GMBCID.glass_engraving {Glass Engraving}",
    "glass_etching_service": "msg: GMBCID.glass_etching_service {Glass etching service}",
    "glass_industry": "msg: GMBCID.glass_industry {Glass Industry}",
    "glass_manufacturer": "msg: GMBCID.glass_manufacturer {Glass Manufacturer}",
    "glass_repair_service": "msg: GMBCID.glass_repair_service {Glass repair service}",
    "glass_shop": "msg: GMBCID.glass_shop {Glass shop}",
    "glasses_repair_service": "msg: GMBCID.glasses_repair_service {Glasses repair service}",
    "glassware_manufacturer": "msg: GMBCID.glassware_manufacturer {Glassware Manufacturer}",
    "glassware_store": "msg: GMBCID.glassware_store {Glassware Shop}",
    "glassware_wholesaler": "msg: GMBCID.glassware_wholesaler {Glassware Wholesaler}",
    "glazier": "msg: GMBCID.glazier {Glazier}",
    "gluten_free_restaurant": "msg: GMBCID.gluten_free_restaurant {Gluten-free restaurant}",
    "gmc_dealer": "msg: GMBCID.gmc_dealer {GMC Dealer}",
    "goan_restaurant": "msg: GMBCID.goan_restaurant {Goan Restaurant}",
    "gold_dealer": "msg: GMBCID.gold_dealer {Gold Dealer}",
    "goldfish_store": "msg: GMBCID.goldfish_store {Goldfish store}",
    "golf_cart_dealer": "msg: GMBCID.golf_cart_dealer {Golf Cart Dealer}",
    "golf_club": "msg: GMBCID.golf_club {Golf club}",
    "golf_course": "msg: GMBCID.golf_course {Golf course}",
    "golf_course_builder": "msg: GMBCID.golf_course_builder {Golf course builder}",
    "golf_driving_range": "msg: GMBCID.golf_driving_range {Golf driving range}",
    "golf_instructor": "msg: GMBCID.golf_instructor {Golf Instructor}",
    "golf_shop": "msg: GMBCID.golf_shop {Golf shop}",
    "gospel_church": "msg: GMBCID.gospel_church {Gospel church}",
    "gospel_singer": "msg: GMBCID.gospel_singer {Gospel Singer}",
    "gourmet_grocery_store": "msg: GMBCID.gourmet_grocery_store {Gourmet Grocery Shop}",
    "government_economic_program": "msg: GMBCID.government_economic_program {Government economic program}",
    "government_hospital": "msg: GMBCID.government_hospital {Government Hospital}",
    "gps_supplier": "msg: GMBCID.gps_supplier {GPS Supplier}",
    "graduate_school": "msg: GMBCID.graduate_school {Graduate School}",
    "graduation": "msg: GMBCID.graduation {Graduation}",
    "graffiti_removal_service": "msg: GMBCID.graffiti_removal_service {Graffiti removal service}",
    "grain_elevator": "msg: GMBCID.grain_elevator {Grain Elevator}",
    "grammar_school": "msg: GMBCID.grammar_school {Grammar school}",
    "granite_supplier": "msg: GMBCID.granite_supplier {Granite Supplier}",
    "graphic_design_portfolio": "msg: GMBCID.graphic_design_portfolio {Graphic Design Portfolio}",
    "gravel_pit": "msg: GMBCID.gravel_pit {Gravel Pit}",
    "gravel_plant": "msg: GMBCID.gravel_plant {Gravel Plant}",
    "greek_orthodox_church": "msg: GMBCID.greek_orthodox_church {Greek Orthodox church}",
    "greek_restaurant": "msg: GMBCID.greek_restaurant {Greek restaurant}",
    "green_energy_supplier": "msg: GMBCID.green_energy_supplier {Green Energy Supplier}",
    "greenhouse": "msg: GMBCID.greenhouse {Greenhouse}",
    "greeting_card_shop": "msg: GMBCID.greeting_card_shop {Greetings Card Shop}",
    "grill_store": "msg: GMBCID.grill_store {Grill Shop}",
    "grocery_delivery_service": "msg: GMBCID.grocery_delivery_service {Grocery delivery service}",
    "grocery_store": "msg: GMBCID.grocery_store {Grocery Store}",
    "group_accommodation": "msg: GMBCID.group_accommodation {Group Accommodation}",
    "guatemalan_restaurant": "msg: GMBCID.guatemalan_restaurant {Guatemalan restaurant}",
    "guest_house": "msg: GMBCID.guest_house {Guest House}",
    "gui_zhou_restaurant": "msg: GMBCID.gui_zhou_restaurant {Guizhou restaurant}",
    "guitar_instructor": "msg: GMBCID.guitar_instructor {Guitar Instructor}",
    "guitar_store": "msg: GMBCID.guitar_store {Guitar Shop}",
    "guitarist": "msg: GMBCID.guitarist {Guitarist}",
    "gujarati_restaurant": "msg: GMBCID.gujarati_restaurant {Gujarati Restaurant}",
    "gun_club": "msg: GMBCID.gun_club {Gun club}",
    "gun_shop": "msg: GMBCID.gun_shop {Gun shop}",
    "guts_barbecue_restaurant": "msg: GMBCID.guts_barbecue_restaurant {Offal barbecue restaurant}",
    "gutter_cleaning_service": "msg: GMBCID.gutter_cleaning_service {Gutter Cleaning Service}",
    "gym": "msg: GMBCID.gym {Gym}",
    "gymnasium_school": "msg: GMBCID.gymnasium_school {Gymnasium School}",
    "gymnastics_center": "msg: GMBCID.gymnastics_center {Gymnastics academy}",
    "gymnastics_club": "msg: GMBCID.gymnastics_club {Gymnastics Club}",
    "gynecologist": "msg: GMBCID.gynecologist {Gynecologist}",
    "gypsum_product_supplier": "msg: GMBCID.gypsum_product_supplier {Gypsum Product Supplier}",
    "gyro_restaurant": "msg: GMBCID.gyro_restaurant {Gyro restaurant}",
    "hair_extension_technician": "msg: GMBCID.hair_extension_technician {Hair extension technician}",
    "hair_removal_service": "msg: GMBCID.hair_removal_service {Hair removal service}",
    "hair_replacement_service": "msg: GMBCID.hair_replacement_service {Hair replacement service}",
    "hair_salon": "msg: GMBCID.hair_salon {Hair Salon}",
    "hair_stylist_portfolio": "msg: GMBCID.hair_stylist_portfolio {Hair Stylist Portfolio}",
    "hair_transplantation_clinic": "msg: GMBCID.hair_transplantation_clinic {Hair transplantation clinic}",
    "haitian_restaurant": "msg: GMBCID.haitian_restaurant {Haitian restaurant}",
    "hakka_restaurant": "msg: GMBCID.hakka_restaurant {Hakka Restaurant}",
    "halal_restaurant": "msg: GMBCID.halal_restaurant {Halal restaurant}",
    "haleem_restaurant": "msg: GMBCID.haleem_restaurant {Haleem restaurant}",
    "hamburger_restaurant": "msg: GMBCID.hamburger_restaurant {Hamburger restaurant}",
    "hand_surgeon": "msg: GMBCID.hand_surgeon {Hand Surgeon}",
    "handbags_shop": "msg: GMBCID.handbags_shop {Handbags Shop}",
    "handball_club": "msg: GMBCID.handball_club {Handball club}",
    "handcrafted_art_portfolio": "msg: GMBCID.handcrafted_art_portfolio {Handcrafted Art Portfolio}",
    "handcrafted_portfolio": "msg: GMBCID.handcrafted_portfolio {Handcrafted Portfolio}",
    "handicapped_transportation_service": "msg: GMBCID.handicapped_transportation_service {Disability Transportation Service}",
    "handicraft_exporter": "msg: GMBCID.handicraft_exporter {Handicraft Exporter}",
    "handicraft_museum": "msg: GMBCID.handicraft_museum {Handicraft museum}",
    "handicraft_school": "msg: GMBCID.handicraft_school {Handicraft school}",
    "handicrafts_wholesaler": "msg: GMBCID.handicrafts_wholesaler {Handicrafts Wholesaler}",
    "handyman": "msg: GMBCID.handyman {Handyman}",
    "hang_drum_player": "msg: GMBCID.hang_drum_player {Hang Drum Player}",
    "hardware_store": "msg: GMBCID.hardware_store {Hardware Shop}",
    "hardware_training_institute": "msg: GMBCID.hardware_training_institute {Hardware Training Institute}",
    "harley_davidson_dealer": "msg: GMBCID.harley_davidson_dealer {Harley-Davidson Dealer}",
    "harmonica_player": "msg: GMBCID.harmonica_player {Harmonica Player}",
    "harmonium_player": "msg: GMBCID.harmonium_player {Harmonium Player}",
    "harpist": "msg: GMBCID.harpist {Harpist}",
    "harpsichordist": "msg: GMBCID.harpsichordist {Harpsichordist}",
    "hat_shop": "msg: GMBCID.hat_shop {Hat shop}",
    "hauptschule": "msg: GMBCID.hauptschule {Hauptschule}",
    "haute_french_restaurant": "msg: GMBCID.haute_french_restaurant {French haute cuisine restaurant}",
    "hawaiian_goods_store": "msg: GMBCID.hawaiian_goods_store {Hawaiian Goods Shop}",
    "hawaiian_restaurant": "msg: GMBCID.hawaiian_restaurant {Hawaiian restaurant}",
    "hawker_centre": "msg: GMBCID.hawker_centre {Hawker Centre}",
    "hawker_stall": "msg: GMBCID.hawker_stall {Hawker Stall}",
    "hay_supplier": "msg: GMBCID.hay_supplier {Hay Supplier}",
    "health_and_beauty_shop": "msg: GMBCID.health_and_beauty_shop {Health and beauty shop}",
    "health_club": "msg: GMBCID.health_club {Health Club}",
    "health_consultant": "msg: GMBCID.health_consultant {Health consultant}",
    "health_counselor": "msg: GMBCID.health_counselor {Health counselor}",
    "health_fitness_enthusiast": "msg: GMBCID.health_fitness_enthusiast {Health Fitness Enthusiast}",
    "health_food_restaurant": "msg: GMBCID.health_food_restaurant {Health food restaurant}",
    "health_food_store": "msg: GMBCID.health_food_store {Health food shop}",
    "health_spa": "msg: GMBCID.health_spa {Health spa}",
    "hearing_aid_repair_service": "msg: GMBCID.hearing_aid_repair_service {Hearing aid repair service}",
    "hearing_aid_store": "msg: GMBCID.hearing_aid_store {Hearing Aid Shop}",
    "heart_hospital": "msg: GMBCID.heart_hospital {Heart Hospital}",
    "heat_plant": "msg: GMBCID.heat_plant {Heat Plant}",
    "heating_contractor": "msg: GMBCID.heating_contractor {Central Heating Service}",
    "heating_equipment_supplier": "msg: GMBCID.heating_equipment_supplier {Heating Equipment Supplier}",
    "heating_oil_supplier": "msg: GMBCID.heating_oil_supplier {Heating Oil Supplier}",
    "helicopter_tour_agency": "msg: GMBCID.helicopter_tour_agency {Helicopter Tour Agency}",
    "helium_gas_supplier": "msg: GMBCID.helium_gas_supplier {Helium Gas Supplier}",
    "hematologist": "msg: GMBCID.hematologist {Hematologist}",
    "hepatologist": "msg: GMBCID.hepatologist {Hepatologist}",
    "herb_shop": "msg: GMBCID.herb_shop {Herb shop}",
    "herbal_medicine_store": "msg: GMBCID.herbal_medicine_store {Herbal Medicine Shop}",
    "herbalist": "msg: GMBCID.herbalist {Herbalist}",
    "heritage_museum": "msg: GMBCID.heritage_museum {Heritage museum}",
    "high_ropes_course": "msg: GMBCID.high_ropes_course {High ropes course}",
    "high_school": "msg: GMBCID.high_school {High School}",
    "higher_secondary_school": "msg: GMBCID.higher_secondary_school {Higher Secondary School}",
    "hiking_area": "msg: GMBCID.hiking_area {Hiking Area}",
    "hiking_enthusiast": "msg: GMBCID.hiking_enthusiast {Hiking Enthusiast}",
    "hindu_temple": "msg: GMBCID.hindu_temple {Hindu temple}",
    "hip_hop_dance_class": "msg: GMBCID.hip_hop_dance_class {Hip Hop Dance Class}",
    "hispanic_church": "msg: GMBCID.hispanic_church {Hispanic church}",
    "historical_place_museum": "msg: GMBCID.historical_place_museum {Historical place museum}",
    "historical_society": "msg: GMBCID.historical_society {Historical society}",
    "history_museum": "msg: GMBCID.history_museum {History museum}",
    "hiv_testing_center": "msg: GMBCID.hiv_testing_center {HIV Testing Center}",
    "hoagie_restaurant": "msg: GMBCID.hoagie_restaurant {Hoagie restaurant}",
    "hobby": "msg: GMBCID.hobby {Hobby}",
    "hobby_club": "msg: GMBCID.hobby_club {Hobby Club}",
    "hobby_interest": "msg: GMBCID.hobby_interest {Hobby Interest}",
    "hobby_store": "msg: GMBCID.hobby_store {Hobby shop}",
    "hockey_club": "msg: GMBCID.hockey_club {Hockey club}",
    "hockey_field": "msg: GMBCID.hockey_field {Hockey Pitch}",
    "hockey_rink": "msg: GMBCID.hockey_rink {Hockey Rink}",
    "hockey_supply_store": "msg: GMBCID.hockey_supply_store {Hockey Supply Shop}",
    "holiday_apartment_rental": "msg: GMBCID.holiday_apartment_rental {Holiday Apartment Rental}",
    "holiday_home": "msg: GMBCID.holiday_home {Holiday Home}",
    "holistic_medicine_practitioner": "msg: GMBCID.holistic_medicine_practitioner {Holistic Medicine Practitioner}",
    "home_builder": "msg: GMBCID.home_builder {Home builder}",
    "home_goods_store": "msg: GMBCID.home_goods_store {Homewares Shop}",
    "home_hairdresser": "msg: GMBCID.home_hairdresser {Home hairdresser}",
    "home_help_service_agency": "msg: GMBCID.home_help_service_agency {Home help service agency}",
    "home_improvement_store": "msg: GMBCID.home_improvement_store {Home improvement shop}",
    "home_inspector": "msg: GMBCID.home_inspector {Home Inspector}",
    "home_theater_store": "msg: GMBCID.home_theater_store {Home Theatre Shop}",
    "homeless_shelter": "msg: GMBCID.homeless_shelter {Homeless shelter}",
    "homeopath": "msg: GMBCID.homeopath {Homeopath}",
    "homeowners_association": "msg: GMBCID.homeowners_association {Homeowners' association}",
    "honda_dealer": "msg: GMBCID.honda_dealer {Honda Dealer}",
    "honduran_restaurant": "msg: GMBCID.honduran_restaurant {Honduran restaurant}",
    "hong_kong_style_cafe": "msg: GMBCID.hong_kong_style_cafe {Cha chaan teng (Hong Kong-style cafe)}",
    "hong_kong_style_fast_food_restaurant": "msg: GMBCID.hong_kong_style_fast_food_restaurant {Hong Kong style fast food restaurant}",
    "hookah_bar": "msg: GMBCID.hookah_bar {Shisha bar}",
    "hookah_store": "msg: GMBCID.hookah_store {Hookah Shop}",
    "horse_riding_field": "msg: GMBCID.horse_riding_field {Horse riding field}",
    "horse_riding_school": "msg: GMBCID.horse_riding_school {Horse riding school}",
    "horse_trailer_dealer": "msg: GMBCID.horse_trailer_dealer {Horse Trailer Dealer}",
    "horse_trainer": "msg: GMBCID.horse_trainer {Horse Trainer}",
    "hose_supplier": "msg: GMBCID.hose_supplier {Hose Supplier}",
    "hospice": "msg: GMBCID.hospice {Hospice}",
    "hospital": "msg: GMBCID.hospital {Hospital}",
    "hospital_and_equipment_supplies": "msg: GMBCID.hospital_and_equipment_supplies {Hospital and Equipment Supplies}",
    "hospital_department": "msg: GMBCID.hospital_department {Hospital Department}",
    "hospitality_and_tourism_school": "msg: GMBCID.hospitality_and_tourism_school {Hospitality and Tourism School}",
    "hospitality_high_school": "msg: GMBCID.hospitality_high_school {Hospitality High School}",
    "hostel": "msg: GMBCID.hostel {Hostel}",
    "hot_bedstone_spa": "msg: GMBCID.hot_bedstone_spa {Hot bedstone spa}",
    "hot_dog_restaurant": "msg: GMBCID.hot_dog_restaurant {Hot dog restaurant}",
    "hot_dog_stand": "msg: GMBCID.hot_dog_stand {Hot dog stand}",
    "hot_pot_restaurant": "msg: GMBCID.hot_pot_restaurant {Hot pot restaurant}",
    "hot_spring_hotel": "msg: GMBCID.hot_spring_hotel {Hot Spring Hotel}",
    "hot_tub_repair_service": "msg: GMBCID.hot_tub_repair_service {Hot Tub Repair Service}",
    "hot_tub_store": "msg: GMBCID.hot_tub_store {Hot Tub Shop}",
    "hot_water_system_supplier": "msg: GMBCID.hot_water_system_supplier {Hot Water System Supplier}",
    "hotel": "msg: GMBCID.hotel {Hotel}",
    "hotel_management_school": "msg: GMBCID.hotel_management_school {Hotel Management School}",
    "hotel_supply_store": "msg: GMBCID.hotel_supply_store {Hotel Supply Shop}",
    "house_cleaning_service": "msg: GMBCID.house_cleaning_service {House cleaning service}",
    "house_clearance_service": "msg: GMBCID.house_clearance_service {House clearance service}",
    "household_chemicals_supplier": "msg: GMBCID.household_chemicals_supplier {Household Chemicals Supplier}",
    "household_goods_wholesaler": "msg: GMBCID.household_goods_wholesaler {Household Goods Wholesaler}",
    "housewarming": "msg: GMBCID.housewarming {Housewarming}",
    "housing_association": "msg: GMBCID.housing_association {Housing association}",
    "housing_complex": "msg: GMBCID.housing_complex {Housing Complex}",
    "housing_development": "msg: GMBCID.housing_development {Housing Development}",
    "housing_society": "msg: GMBCID.housing_society {Housing society}",
    "housing_utility_company": "msg: GMBCID.housing_utility_company {Housing utility company}",
    "hua_gong_shop": "msg: GMBCID.hua_gong_shop {Hua gong shop}",
    "hub_cap_supplier": "msg: GMBCID.hub_cap_supplier {Hub Cap Supplier}",
    "hunan_style_restaurant": "msg: GMBCID.hunan_style_restaurant {Hunan restaurant}",
    "hungarian_restaurant": "msg: GMBCID.hungarian_restaurant {Hungarian restaurant}",
    "hunting_and_fishing_store": "msg: GMBCID.hunting_and_fishing_store {Hunting and Fishing Shop}",
    "hunting_club": "msg: GMBCID.hunting_club {Hunting club}",
    "hunting_store": "msg: GMBCID.hunting_store {Hunting Shop}",
    "hvac_contractor": "msg: GMBCID.hvac_contractor {HVAC contractor}",
    "hyderabadi_restaurant": "msg: GMBCID.hyderabadi_restaurant {Hyderabadi Restaurant}",
    "hydraulic_equipment_supplier": "msg: GMBCID.hydraulic_equipment_supplier {Hydraulic Equipment Supplier}",
    "hydraulic_repair_service": "msg: GMBCID.hydraulic_repair_service {Hydraulic repair service}",
    "hydroelectric_power_plant": "msg: GMBCID.hydroelectric_power_plant {Hydroelectric Power Plant}",
    "hydroponics_equipment_supplier": "msg: GMBCID.hydroponics_equipment_supplier {Hydroponics Equipment Supplier}",
    "hygiene_articles_wholesaler": "msg: GMBCID.hygiene_articles_wholesaler {Hygiene Articles Wholesaler}",
    "hyperbaric_medicine_physician": "msg: GMBCID.hyperbaric_medicine_physician {Hyperbaric Medicine Physician}",
    "hypermarket": "msg: GMBCID.hypermarket {Hypermarket}",
    "hyundai_dealer": "msg: GMBCID.hyundai_dealer {Hyundai Dealer}",
    "ice_cream_equipment_supplier": "msg: GMBCID.ice_cream_equipment_supplier {Ice Cream Equipment Supplier}",
    "ice_cream_shop": "msg: GMBCID.ice_cream_shop {Ice cream shop}",
    "ice_hockey_club": "msg: GMBCID.ice_hockey_club {Ice hockey club}",
    "ice_skating_club": "msg: GMBCID.ice_skating_club {Ice skating club}",
    "ice_skating_instructor": "msg: GMBCID.ice_skating_instructor {Ice Skating Instructor}",
    "ice_skating_rink": "msg: GMBCID.ice_skating_rink {Ice skating rink}",
    "ice_supplier": "msg: GMBCID.ice_supplier {Ice Supplier}",
    "icelandic_restaurant": "msg: GMBCID.icelandic_restaurant {Icelandic restaurant}",
    "icse_school": "msg: GMBCID.icse_school {ICSE School}",
    "ikan_bakar_restaurant": "msg: GMBCID.ikan_bakar_restaurant {Ikan bakar restaurant}",
    "illustrator_portfolio": "msg: GMBCID.illustrator_portfolio {Illustrator Portfolio}",
    "image_consultant": "msg: GMBCID.image_consultant {Image Consultant}",
    "immigration_attorney": "msg: GMBCID.immigration_attorney {Immigration Attorney}",
    "immunologist": "msg: GMBCID.immunologist {Immunologist}",
    "importer": "msg: GMBCID.importer {Importer}",
    "income_tax_help_association": "msg: GMBCID.income_tax_help_association {Income tax help association}",
    "independent_school": "msg: GMBCID.independent_school {Independent School}",
    "indian_grocery_store": "msg: GMBCID.indian_grocery_store {Indian Grocery Shop}",
    "indian_motorcycle_dealer": "msg: GMBCID.indian_motorcycle_dealer {Indian Motorcycle Dealer}",
    "indian_muslim_restaurant": "msg: GMBCID.indian_muslim_restaurant {Indian Muslim Restaurant}",
    "indian_restaurant": "msg: GMBCID.indian_restaurant {Indian restaurant}",
    "indian_sizzler_restaurant": "msg: GMBCID.indian_sizzler_restaurant {Indian sizzler restaurant}",
    "indian_takeaway": "msg: GMBCID.indian_takeaway {Indian takeaway}",
    "indie_artist": "msg: GMBCID.indie_artist {Indie Artist}",
    "indonesian_restaurant": "msg: GMBCID.indonesian_restaurant {Indonesian restaurant}",
    "indoor_golf_course": "msg: GMBCID.indoor_golf_course {Indoor golf course}",
    "indoor_lodging": "msg: GMBCID.indoor_lodging {Indoor Lodging}",
    "indoor_playground": "msg: GMBCID.indoor_playground {Indoor playground}",
    "indoor_snowcenter": "msg: GMBCID.indoor_snowcenter {Indoor Snowcentre}",
    "indoor_swimming_pool": "msg: GMBCID.indoor_swimming_pool {Indoor swimming pool}",
    "industrial_chemicals_wholesaler": "msg: GMBCID.industrial_chemicals_wholesaler {Industrial Chemicals Wholesaler}",
    "industrial_design_company": "msg: GMBCID.industrial_design_company {Industrial design company}",
    "industrial_door_supplier": "msg: GMBCID.industrial_door_supplier {Industrial Door Supplier}",
    "industrial_engineers_association": "msg: GMBCID.industrial_engineers_association {Industrial engineers association}",
    "industrial_equipment_supplier": "msg: GMBCID.industrial_equipment_supplier {Industrial Equipment Supplier}",
    "industrial_gas_supplier": "msg: GMBCID.industrial_gas_supplier {Industrial Gas Supplier}",
    "industrial_supermarket": "msg: GMBCID.industrial_supermarket {Industrial supermarket}",
    "industrial_technical_engineers_association": "msg: GMBCID.industrial_technical_engineers_association {Industrial technical engineers association}",
    "industrial_vacuum_equipment_supplier": "msg: GMBCID.industrial_vacuum_equipment_supplier {Industrial Vacuum Equipment Supplier}",
    "infectious_disease_physician": "msg: GMBCID.infectious_disease_physician {Infectious Disease Physician}",
    "infiniti_dealer": "msg: GMBCID.infiniti_dealer {Infiniti Dealer}",
    "inn": "msg: GMBCID.inn {Inn}",
    "institute_of_geography_and_statistics": "msg: GMBCID.institute_of_geography_and_statistics {Institute of Geography and Statistics}",
    "instrumentalist": "msg: GMBCID.instrumentalist {Instrumentalist}",
    "insulation_contractor": "msg: GMBCID.insulation_contractor {Insulation Contractor}",
    "insulation_materials_store": "msg: GMBCID.insulation_materials_store {Insulation Materials Shop}",
    "insulator_supplier": "msg: GMBCID.insulator_supplier {Insulator Supplier}",
    "insurance_agency": "msg: GMBCID.insurance_agency {Insurance agency}",
    "insurance_school": "msg: GMBCID.insurance_school {Insurance School}",
    "intellectual_property_attorney": "msg: GMBCID.intellectual_property_attorney {Intellectual Property Attorney}",
    "intellectual_property_registry": "msg: GMBCID.intellectual_property_registry {Intellectual property registry}",
    "intensivist": "msg: GMBCID.intensivist {Intensivist}",
    "interior_architect_office": "msg: GMBCID.interior_architect_office {Interior architect office}",
    "interior_decorator": "msg: GMBCID.interior_decorator {Interior Decorator}",
    "interior_designer": "msg: GMBCID.interior_designer {Interior designer}",
    "interior_designer_portfolio": "msg: GMBCID.interior_designer_portfolio {Interior Designer Portfolio}",
    "interior_door": "msg: GMBCID.interior_door {Interior door shop}",
    "interior_plant_service": "msg: GMBCID.interior_plant_service {Interior Plant Hire Service}",
    "internal_medicine_ward": "msg: GMBCID.internal_medicine_ward {Internal Medicine Ward}",
    "international_school": "msg: GMBCID.international_school {International School}",
    "internist": "msg: GMBCID.internist {Internist}",
    "investment_bank": "msg: GMBCID.investment_bank {Investment Bank}",
    "invitation_printing_service": "msg: GMBCID.invitation_printing_service {Invitation printing service}",
    "irish_goods_store": "msg: GMBCID.irish_goods_store {Irish Goods Shop}",
    "irish_pub": "msg: GMBCID.irish_pub {Irish pub}",
    "irish_restaurant": "msg: GMBCID.irish_restaurant {Irish restaurant}",
    "irrigation_equipment_supplier": "msg: GMBCID.irrigation_equipment_supplier {Irrigation Equipment Supplier}",
    "israeli_restaurant": "msg: GMBCID.israeli_restaurant {Israeli restaurant}",
    "isuzu_dealer": "msg: GMBCID.isuzu_dealer {Isuzu Dealer}",
    "italian_grocery_store": "msg: GMBCID.italian_grocery_store {Italian Grocery Shop}",
    "italian_restaurant": "msg: GMBCID.italian_restaurant {Italian restaurant}",
    "iut": "msg: GMBCID.iut {Institute of technology}",
    "jaguar_dealer": "msg: GMBCID.jaguar_dealer {Jaguar Dealer}",
    "jain_temple": "msg: GMBCID.jain_temple {Jain temple}",
    "jamaican_restaurant": "msg: GMBCID.jamaican_restaurant {Jamaican restaurant}",
    "janitorial_equipment_supplier": "msg: GMBCID.janitorial_equipment_supplier {Janitorial Equipment Supplier}",
    "janitorial_service": "msg: GMBCID.janitorial_service {Janitorial service}",
    "japanese_authentic_restaurant": "msg: GMBCID.japanese_authentic_restaurant {Authentic Japanese Restaurant}",
    "japanese_cheap_sweets_shop": "msg: GMBCID.japanese_cheap_sweets_shop {Japanese cheap sweets shop}",
    "japanese_curry_restaurant": "msg: GMBCID.japanese_curry_restaurant {Japanese curry restaurant}",
    "japanese_grocery_store": "msg: GMBCID.japanese_grocery_store {Japanese Grocery Shop}",
    "japanese_high_quality_restaurant": "msg: GMBCID.japanese_high_quality_restaurant {Ryotei restaurant}",
    "japanese_inns": "msg: GMBCID.japanese_inns {Japanese Inns}",
    "japanese_izakaya_restaurant": "msg: GMBCID.japanese_izakaya_restaurant {Izakaya restaurant}",
    "japanese_language_instructor": "msg: GMBCID.japanese_language_instructor {Japanese Language Instructor}",
    "japanese_regional_restaurant": "msg: GMBCID.japanese_regional_restaurant {Japanese regional restaurant}",
    "japanese_restaurant": "msg: GMBCID.japanese_restaurant {Japanese restaurant}",
    "japanese_sweets_restaurant": "msg: GMBCID.japanese_sweets_restaurant {Japanese sweets restaurant}",
    "japanized_western_food_restaurant": "msg: GMBCID.japanized_western_food_restaurant {Japanized western restaurant}",
    "javanese_restaurant": "msg: GMBCID.javanese_restaurant {Javanese restaurant}",
    "jazz_club": "msg: GMBCID.jazz_club {Jazz club}",
    "jazz_musician": "msg: GMBCID.jazz_musician {Jazz Musician}",
    "jehovahs_witness_church": "msg: GMBCID.jehovahs_witness_church {Jehovah's Witness Kingdom Hall}",
    "jewelry_appraiser": "msg: GMBCID.jewelry_appraiser {Jewelry Appraiser}",
    "jewelry_design_portfolio": "msg: GMBCID.jewelry_design_portfolio {Jewelry Design Portfolio}",
    "jewelry_designer": "msg: GMBCID.jewelry_designer {Jewellery Designer}",
    "jewelry_engraver": "msg: GMBCID.jewelry_engraver {Jewellery Engraver}",
    "jewelry_equipment_supplier": "msg: GMBCID.jewelry_equipment_supplier {Jewelry Equipment Supplier}",
    "jewelry_exporter": "msg: GMBCID.jewelry_exporter {Jewelry Exporter}",
    "jewelry_manufacturer": "msg: GMBCID.jewelry_manufacturer {Jewelry Manufacturer}",
    "jewelry_repair_service": "msg: GMBCID.jewelry_repair_service {Jewellery Repair Service}",
    "jewelry_store": "msg: GMBCID.jewelry_store {Jewellery Store}",
    "jewish_restaurant": "msg: GMBCID.jewish_restaurant {Jewish restaurant}",
    "jiang_su_restaurant": "msg: GMBCID.jiang_su_restaurant {Jiangsu restaurant}",
    "judo_club": "msg: GMBCID.judo_club {Judo club}",
    "judo_school": "msg: GMBCID.judo_school {Judo school}",
    "juice_shop": "msg: GMBCID.juice_shop {Juice bar}",
    "jujitsu_school": "msg: GMBCID.jujitsu_school {Jiu jitsu school}",
    "junior_college": "msg: GMBCID.junior_college {Junior College}",
    "junk_store": "msg: GMBCID.junk_store {Junk Store}",
    "jute_exporter": "msg: GMBCID.jute_exporter {Jute Exporter}",
    "jute_mill": "msg: GMBCID.jute_mill {Jute Mill}",
    "kabaddi_club": "msg: GMBCID.kabaddi_club {Kabaddi Club}",
    "kaiseki_restaurant": "msg: GMBCID.kaiseki_restaurant {Kaiseki restaurant}",
    "kalle_pache_restaurant": "msg: GMBCID.kalle_pache_restaurant {Kalle pache restaurant}",
    "karaoke_bar": "msg: GMBCID.karaoke_bar {Karaoke bar}",
    "karate_club": "msg: GMBCID.karate_club {Karate club}",
    "karate_school": "msg: GMBCID.karate_school {Karate school}",
    "karnataka_restaurant": "msg: GMBCID.karnataka_restaurant {Karnataka Restaurant}",
    "kashmiri_restaurant": "msg: GMBCID.kashmiri_restaurant {Kashmiri restaurant}",
    "kazakhstani_restaurant": "msg: GMBCID.kazakhstani_restaurant {Kazakhstani restaurant}",
    "kazoo_player": "msg: GMBCID.kazoo_player {Kazoo Player}",
    "kebab_shop": "msg: GMBCID.kebab_shop {Kebab Shop}",
    "kerala_restaurant": "msg: GMBCID.kerala_restaurant {Kerala Restaurant}",
    "kerosene_supplier": "msg: GMBCID.kerosene_supplier {Kerosene Supplier}",
    "key_duplication_service": "msg: GMBCID.key_duplication_service {Key Duplication Service}",
    "kia_dealer": "msg: GMBCID.kia_dealer {Kia Dealer}",
    "kickboxing_school": "msg: GMBCID.kickboxing_school {Kickboxing school}",
    "kids_and_newborn_photography_portfolio": "msg: GMBCID.kids_and_newborn_photography_portfolio {Kids And Newborn Photography Portfolio}",
    "kilt_shop_and_hire": "msg: GMBCID.kilt_shop_and_hire {Kilt shop and hire}",
    "kimono_store": "msg: GMBCID.kimono_store {Kimono Shop}",
    "kindergarten": "msg: GMBCID.kindergarten {Kindergarten}",
    "kinesiotherapist": "msg: GMBCID.kinesiotherapist {Kinesiotherapist}",
    "kitchen_furniture_store": "msg: GMBCID.kitchen_furniture_store {Kitchen Furniture Shop}",
    "kitchen_remodeler": "msg: GMBCID.kitchen_remodeler {Kitchen Remodeler}",
    "kitchen_supply_store": "msg: GMBCID.kitchen_supply_store {Kitchen supply shop}",
    "kite_shop": "msg: GMBCID.kite_shop {Kite shop}",
    "klezmer_musician": "msg: GMBCID.klezmer_musician {Klezmer Musician}",
    "knife_manufacturing": "msg: GMBCID.knife_manufacturing {Knife Manufacturing}",
    "knife_store": "msg: GMBCID.knife_store {Knife Shop}",
    "knit_shop": "msg: GMBCID.knit_shop {Knit shop}",
    "knitting_instructor": "msg: GMBCID.knitting_instructor {Knitting Instructor}",
    "knitwear_manufacturer": "msg: GMBCID.knitwear_manufacturer {Knitwear Manufacturer}",
    "kofta_restaurant": "msg: GMBCID.kofta_restaurant {Kofta restaurant}",
    "konkani_restaurant": "msg: GMBCID.konkani_restaurant {Konkani Restaurant}",
    "kora_player": "msg: GMBCID.kora_player {Kora Player}",
    "korean_barbecue_restaurant": "msg: GMBCID.korean_barbecue_restaurant {Korean barbecue restaurant}",
    "korean_beef_restaurant": "msg: GMBCID.korean_beef_restaurant {Korean beef restaurant}",
    "korean_church": "msg: GMBCID.korean_church {Korean church}",
    "korean_grocery_store": "msg: GMBCID.korean_grocery_store {Korean Grocery Shop}",
    "korean_restaurant": "msg: GMBCID.korean_restaurant {Korean restaurant}",
    "korean_rib_restaurant": "msg: GMBCID.korean_rib_restaurant {Korean rib restaurant}",
    "koshari_restaurant": "msg: GMBCID.koshari_restaurant {Koshari restaurant}",
    "kosher_grocery_store": "msg: GMBCID.kosher_grocery_store {Kosher Grocery Shop}",
    "kosher_restaurant": "msg: GMBCID.kosher_restaurant {Kosher restaurant}",
    "kung_fu_school": "msg: GMBCID.kung_fu_school {Kung fu school}",
    "kushiyaki_restaurant": "msg: GMBCID.kushiyaki_restaurant {Kushiyaki restaurant}",
    "kyoto_cuisine_restaurant": "msg: GMBCID.kyoto_cuisine_restaurant {Kyoto style Japanese restaurant}",
    "labor_relations_attorney": "msg: GMBCID.labor_relations_attorney {Labor Relations Attorney}",
    "laboratory_equipment_supplier": "msg: GMBCID.laboratory_equipment_supplier {Laboratory Equipment Supplier}",
    "labour_club": "msg: GMBCID.labour_club {Labour Club}",
    "laminating_equipment_supplier": "msg: GMBCID.laminating_equipment_supplier {Laminating Equipment Supplier}",
    "lamination_service": "msg: GMBCID.lamination_service {Lamination service}",
    "lamp_repair_service": "msg: GMBCID.lamp_repair_service {Lamp repair service}",
    "lamp_shade_supplier": "msg: GMBCID.lamp_shade_supplier {Lamp Shade Supplier}",
    "land_reform_institute": "msg: GMBCID.land_reform_institute {Land reform institute}",
    "land_surveying_office": "msg: GMBCID.land_surveying_office {Land surveying office}",
    "land_surveyor": "msg: GMBCID.land_surveyor {Land surveyor}",
    "landscape_architect": "msg: GMBCID.landscape_architect {Landscape architect}",
    "landscape_designer": "msg: GMBCID.landscape_designer {Landscape designer}",
    "landscape_lighting_designer": "msg: GMBCID.landscape_lighting_designer {Landscape lighting designer}",
    "landscape_photographer": "msg: GMBCID.landscape_photographer {Landscape Photographer}",
    "landscaper": "msg: GMBCID.landscaper {Landscape Gardener}",
    "language_enthusiast": "msg: GMBCID.language_enthusiast {Language Enthusiast}",
    "language_school": "msg: GMBCID.language_school {Language school}",
    "laotian_restaurant": "msg: GMBCID.laotian_restaurant {Laotian restaurant}",
    "lapidary": "msg: GMBCID.lapidary {Lapidary}",
    "laser_cutting_service": "msg: GMBCID.laser_cutting_service {Laser Cutting Service}",
    "laser_equipment_supplier": "msg: GMBCID.laser_equipment_supplier {Laser Equipment Supplier}",
    "laser_hair_removal_service": "msg: GMBCID.laser_hair_removal_service {Laser hair removal service}",
    "lasik_surgeon": "msg: GMBCID.lasik_surgeon {Lasik Surgeon}",
    "latin_american_restaurant": "msg: GMBCID.latin_american_restaurant {Latin American restaurant}",
    "laundry_service": "msg: GMBCID.laundry_service {Laundry service}",
    "law_book_store": "msg: GMBCID.law_book_store {Law Book Shop}",
    "law_library": "msg: GMBCID.law_library {Law Library}",
    "law_school": "msg: GMBCID.law_school {Law School}",
    "lawn_bowls_club": "msg: GMBCID.lawn_bowls_club {Lawn bowls club}",
    "lawn_care_service": "msg: GMBCID.lawn_care_service {Lawn care service}",
    "lawn_irrigation_equipment_supplier": "msg: GMBCID.lawn_irrigation_equipment_supplier {Lawn Irrigation Equipment Supplier}",
    "lawn_mower_repair_service": "msg: GMBCID.lawn_mower_repair_service {Lawn mower repair service}",
    "lawn_mower_store": "msg: GMBCID.lawn_mower_store {Lawn Mower Shop}",
    "lawyers_association": "msg: GMBCID.lawyers_association {Lawyers association}",
    "leagues_club": "msg: GMBCID.leagues_club {Leagues Club}",
    "learning_center": "msg: GMBCID.learning_center {Learning Centre}",
    "leather_cleaning_service": "msg: GMBCID.leather_cleaning_service {Leather cleaning service}",
    "leather_coats_store": "msg: GMBCID.leather_coats_store {Leather Coats Shop}",
    "leather_exporter": "msg: GMBCID.leather_exporter {Leather Exporter}",
    "leather_goods_manufacturer": "msg: GMBCID.leather_goods_manufacturer {Leather Goods Manufacturer}",
    "leather_goods_store": "msg: GMBCID.leather_goods_store {Leather Goods Shop}",
    "leather_goods_supplier": "msg: GMBCID.leather_goods_supplier {Leather Goods Supplier}",
    "leather_goods_wholesaler": "msg: GMBCID.leather_goods_wholesaler {Leather Goods Wholesaler}",
    "leather_repair_service": "msg: GMBCID.leather_repair_service {Leather repair service}",
    "leather_wholesaler": "msg: GMBCID.leather_wholesaler {Leather Wholesaler}",
    "lebanese_restaurant": "msg: GMBCID.lebanese_restaurant {Lebanese restaurant}",
    "lechon_restaurant": "msg: GMBCID.lechon_restaurant {Lechon restaurant}",
    "liaison_office": "msg: GMBCID.liaison_office {Liaison Office}",
    "library": "msg: GMBCID.library {Library}",
    "license_bureau": "msg: GMBCID.license_bureau {License Bureau}",
    "license_plate_frames_supplier": "msg: GMBCID.license_plate_frames_supplier {License Plate Frames Supplier}",
    "life_coach": "msg: GMBCID.life_coach {Life Coach}",
    "life_insurance_agency": "msg: GMBCID.life_insurance_agency {Life Insurance Agency}",
    "lifestyle_photography_portfolio": "msg: GMBCID.lifestyle_photography_portfolio {Lifestyle Photography Portfolio}",
    "light_bulb_supplier": "msg: GMBCID.light_bulb_supplier {Light Bulb Supplier}",
    "lighting_contractor": "msg: GMBCID.lighting_contractor {Lighting contractor}",
    "lighting_manufacturer": "msg: GMBCID.lighting_manufacturer {Lighting Manufacturer}",
    "lighting_store": "msg: GMBCID.lighting_store {Lighting Shop}",
    "lighting_wholesaler": "msg: GMBCID.lighting_wholesaler {Lighting Wholesaler}",
    "ligurian_restaurant": "msg: GMBCID.ligurian_restaurant {Ligurian restaurant}",
    "line_marking_service": "msg: GMBCID.line_marking_service {Line marking service}",
    "linens_store": "msg: GMBCID.linens_store {Linen Shop}",
    "lingerie_manufacturer": "msg: GMBCID.lingerie_manufacturer {Lingerie Manufacturer}",
    "lingerie_store": "msg: GMBCID.lingerie_store {Lingerie shop}",
    "lingerie_wholesaler": "msg: GMBCID.lingerie_wholesaler {Lingerie Wholesaler}",
    "linoleum_store": "msg: GMBCID.linoleum_store {Linoleum Shop}",
    "liquor_store": "msg: GMBCID.liquor_store {Liquor Shop}",
    "liquor_wholesaler": "msg: GMBCID.liquor_wholesaler {Liquor Wholesaler}",
    "literacy_program": "msg: GMBCID.literacy_program {Literacy Program}",
    "lithuanian_restaurant": "msg: GMBCID.lithuanian_restaurant {Lithuanian restaurant}",
    "little_league_club": "msg: GMBCID.little_league_club {Little League Club}",
    "live_music_bar": "msg: GMBCID.live_music_bar {Live music bar}",
    "live_music_venue": "msg: GMBCID.live_music_venue {Live music venue}",
    "livestock_producer": "msg: GMBCID.livestock_producer {Livestock Producer}",
    "loan_agency": "msg: GMBCID.loan_agency {Loan agency}",
    "local_government_office": "msg: GMBCID.local_government_office {Local Government Office}",
    "local_history_museum": "msg: GMBCID.local_history_museum {Local history museum}",
    "lock_store": "msg: GMBCID.lock_store {Lock Store}",
    "lodge": "msg: GMBCID.lodge {Lodge}",
    "log_cabins": "msg: GMBCID.log_cabins {Log Cabins}",
    "log_home_builder": "msg: GMBCID.log_home_builder {Log home builder}",
    "logging_contractor": "msg: GMBCID.logging_contractor {Logging contractor}",
    "logistics_service": "msg: GMBCID.logistics_service {Logistics service}",
    "lombardian_restaurant": "msg: GMBCID.lombardian_restaurant {Lombardian restaurant}",
    "loss_adjuster": "msg: GMBCID.loss_adjuster {Loss adjuster}",
    "lottery_shop": "msg: GMBCID.lottery_shop {Lottery shop}",
    "love_hotel": "msg: GMBCID.love_hotel {Love Hotel}",
    "luggage_repair_service": "msg: GMBCID.luggage_repair_service {Luggage repair service}",
    "luggage_store": "msg: GMBCID.luggage_store {Luggage Shop}",
    "luggage_wholesaler": "msg: GMBCID.luggage_wholesaler {Luggage Wholesaler}",
    "lumber_store": "msg: GMBCID.lumber_store {Timber merchant}",
    "lunch_restaurant": "msg: GMBCID.lunch_restaurant {Lunch restaurant}",
    "lutenist": "msg: GMBCID.lutenist {Lutenist}",
    "lutheran_church": "msg: GMBCID.lutheran_church {Lutheran church}",
    "lymph_drainage": "msg: GMBCID.lymph_drainage {Lymph Drainage}",
    "lyricist": "msg: GMBCID.lyricist {Lyricist}",
    "machine_knife_supplier": "msg: GMBCID.machine_knife_supplier {Machine Knife Supplier}",
    "machine_maintenance": "msg: GMBCID.machine_maintenance {Machine maintenance}",
    "machine_repair_service": "msg: GMBCID.machine_repair_service {Machine Repair Service}",
    "machinery_parts_manufacturer": "msg: GMBCID.machinery_parts_manufacturer {Machinery Parts Manufacturer}",
    "macro_photography_portfolio": "msg: GMBCID.macro_photography_portfolio {Macro Photography Portfolio}",
    "macrobiotic_restaurant": "msg: GMBCID.macrobiotic_restaurant {Macrobiotic restaurant}",
    "madrilian_restaurant": "msg: GMBCID.madrilian_restaurant {Madrilian restaurant}",
    "magazine_store": "msg: GMBCID.magazine_store {Newsagent}",
    "magic_store": "msg: GMBCID.magic_store {Magic Shop}",
    "mailing_machine_supplier": "msg: GMBCID.mailing_machine_supplier {Mailing Machine Supplier}",
    "mailing_service": "msg: GMBCID.mailing_service {Post Office/Courier}",
    "majorcan_restaurant": "msg: GMBCID.majorcan_restaurant {Mallorcan restaurant}",
    "makeup_artist": "msg: GMBCID.makeup_artist {Make-up artist}",
    "makeup_artist_portfolio": "msg: GMBCID.makeup_artist_portfolio {Makeup Artist Portfolio}",
    "malaysian_restaurant": "msg: GMBCID.malaysian_restaurant {Malaysian restaurant}",
    "maltese_restaurant": "msg: GMBCID.maltese_restaurant {Maltese restaurant}",
    "manado_restaurant": "msg: GMBCID.manado_restaurant {Manado restaurant}",
    "management_school": "msg: GMBCID.management_school {Management School}",
    "managing_agent": "msg: GMBCID.managing_agent {Managing Agent}",
    "mandarin_restaurant": "msg: GMBCID.mandarin_restaurant {Mandarin restaurant}",
    "mandolin_player": "msg: GMBCID.mandolin_player {Mandolin Player}",
    "manufacturer": "msg: GMBCID.manufacturer {Manufacturer}",
    "maori_organization": "msg: GMBCID.maori_organization {Maori Organisation}",
    "map_store": "msg: GMBCID.map_store {Map shop}",
    "mapping_service": "msg: GMBCID.mapping_service {Mapping service}",
    "marathi_restaurant": "msg: GMBCID.marathi_restaurant {Maharashtrian Restaurant}",
    "marble_contractor": "msg: GMBCID.marble_contractor {Marble contractor}",
    "marble_supplier": "msg: GMBCID.marble_supplier {Marble Supplier}",
    "marche_restaurant": "msg: GMBCID.marche_restaurant {Marche restaurant}",
    "marimbist": "msg: GMBCID.marimbist {Marimbist}",
    "marina": "msg: GMBCID.marina {Marina}",
    "maritime_museum": "msg: GMBCID.maritime_museum {Maritime museum}",
    "market": "msg: GMBCID.market {Market}",
    "market_researcher": "msg: GMBCID.market_researcher {Market Researcher}",
    "marketing_agency": "msg: GMBCID.marketing_agency {Marketing agency}",
    "marketing_consultant": "msg: GMBCID.marketing_consultant {Marketing consultant}",
    "marketing_portfolio": "msg: GMBCID.marketing_portfolio {Marketing Portfolio}",
    "marquee_hire_service": "msg: GMBCID.marquee_hire_service {Marquee Hire Service}",
    "marriage_celebrant": "msg: GMBCID.marriage_celebrant {Marriage Celebrant}",
    "marriage_counselor": "msg: GMBCID.marriage_counselor {Marriage Counselor}",
    "marriage_license_bureau": "msg: GMBCID.marriage_license_bureau {Marriage License Bureau}",
    "martial_arts_club": "msg: GMBCID.martial_arts_club {Martial arts club}",
    "martial_arts_competition": "msg: GMBCID.martial_arts_competition {Martial Arts Competition}",
    "martial_arts_school": "msg: GMBCID.martial_arts_school {Martial arts school}",
    "masonry_contractor": "msg: GMBCID.masonry_contractor {Masonry contractor}",
    "massage": "msg: GMBCID.massage {Massage}",
    "massage_school": "msg: GMBCID.massage_school {Massage school}",
    "massage_spa": "msg: GMBCID.massage_spa {Massage spa}",
    "massage_therapist": "msg: GMBCID.massage_therapist {Massage therapist}",
    "match_box_manufacturer": "msg: GMBCID.match_box_manufacturer {Match Box Manufacturer}",
    "material_handling_equipment_supplier": "msg: GMBCID.material_handling_equipment_supplier {Material Handling Equipment Supplier}",
    "maternity_hospital": "msg: GMBCID.maternity_hospital {Maternity Hospital}",
    "maternity_store": "msg: GMBCID.maternity_store {Maternity Shop}",
    "mathematics_school": "msg: GMBCID.mathematics_school {Mathematics School}",
    "mattress_store": "msg: GMBCID.mattress_store {Mattress Shop}",
    "mausoleum_builder": "msg: GMBCID.mausoleum_builder {Mausoleum builder}",
    "maybach_dealer": "msg: GMBCID.maybach_dealer {Maybach Dealer}",
    "mazda_dealer": "msg: GMBCID.mazda_dealer {Mazda Dealer}",
    "meal_delivery": "msg: GMBCID.meal_delivery {Meal Delivery}",
    "meal_takeaway": "msg: GMBCID.meal_takeaway {Takeaway}",
    "measuring_instruments_supplier": "msg: GMBCID.measuring_instruments_supplier {Measuring Instruments Supplier}",
    "meat_packer": "msg: GMBCID.meat_packer {Meat packer}",
    "meat_processor": "msg: GMBCID.meat_processor {Meat processor}",
    "meat_products": "msg: GMBCID.meat_products {Meat products}",
    "meat_restaurant": "msg: GMBCID.meat_restaurant {Meat dish restaurant}",
    "meat_wholesaler": "msg: GMBCID.meat_wholesaler {Meat Wholesaler}",
    "mechanic": "msg: GMBCID.mechanic {Mechanic}",
    "mechanical_contractor": "msg: GMBCID.mechanical_contractor {Mechanical contractor}",
    "media_and_information_sciences_faculty": "msg: GMBCID.media_and_information_sciences_faculty {Media and Information Sciences Faculty}",
    "media_company": "msg: GMBCID.media_company {Media company}",
    "media_consultant": "msg: GMBCID.media_consultant {Media Consultant}",
    "media_house": "msg: GMBCID.media_house {Media house}",
    "mediation_service": "msg: GMBCID.mediation_service {Mediation service}",
    "medical_billing_service": "msg: GMBCID.medical_billing_service {Medical billing service}",
    "medical_book_store": "msg: GMBCID.medical_book_store {Medical Book Shop}",
    "medical_center": "msg: GMBCID.medical_center {Medical Center}",
    "medical_certificate_service": "msg: GMBCID.medical_certificate_service {Medical certificate service}",
    "medical_clinic": "msg: GMBCID.medical_clinic {Medical Clinic}",
    "medical_conference": "msg: GMBCID.medical_conference {Medical Conference}",
    "medical_diagnostic_imaging_center": "msg: GMBCID.medical_diagnostic_imaging_center {Medical Diagnostic Imaging Center}",
    "medical_equipment_manufacturer": "msg: GMBCID.medical_equipment_manufacturer {Medical Equipment Manufacturer}",
    "medical_equipment_supplier": "msg: GMBCID.medical_equipment_supplier {Medical Equipment Supplier}",
    "medical_examiner": "msg: GMBCID.medical_examiner {Medical Examiner}",
    "medical_lab": "msg: GMBCID.medical_lab {Medical Lab}",
    "medical_lawyer": "msg: GMBCID.medical_lawyer {Medical lawyer}",
    "medical_office": "msg: GMBCID.medical_office {Medical office}",
    "medical_school": "msg: GMBCID.medical_school {Medical School}",
    "medical_spa": "msg: GMBCID.medical_spa {Medical spa}",
    "medical_supply_store": "msg: GMBCID.medical_supply_store {Medical supply store}",
    "medical_technology_manufacturer": "msg: GMBCID.medical_technology_manufacturer {Medical Technology Manufacturer}",
    "medical_transcription_service": "msg: GMBCID.medical_transcription_service {Medical transcription service}",
    "medicine_exporter": "msg: GMBCID.medicine_exporter {Medicine Exporter}",
    "meditation_center": "msg: GMBCID.meditation_center {Meditation Centre}",
    "meditation_instructor": "msg: GMBCID.meditation_instructor {Meditation Instructor}",
    "mediterranean_restaurant": "msg: GMBCID.mediterranean_restaurant {Mediterranean restaurant}",
    "meeting_planning_service": "msg: GMBCID.meeting_planning_service {Meeting planning service}",
    "mehandi_class": "msg: GMBCID.mehandi_class {Mehandi Class}",
    "mehndi_designer": "msg: GMBCID.mehndi_designer {Mehndi Designer}",
    "memorial": "msg: GMBCID.memorial {Memorial}",
    "mennonite_church": "msg: GMBCID.mennonite_church {Mennonite church}",
    "mens_clothing_store": "msg: GMBCID.mens_clothing_store {Men's Clothes Shop}",
    "mens_health_physician": "msg: GMBCID.mens_health_physician {Men's Health Physician}",
    "mental_health_clinic": "msg: GMBCID.mental_health_clinic {Mental Health Clinic}",
    "messianic_synagogue": "msg: GMBCID.messianic_synagogue {Messianic synagogue}",
    "metal_detecting_equipment_supplier": "msg: GMBCID.metal_detecting_equipment_supplier {Metal Detecting Equipment Supplier}",
    "metal_fabricator": "msg: GMBCID.metal_fabricator {Metal fabricator}",
    "metal_finisher": "msg: GMBCID.metal_finisher {Metal finisher}",
    "metal_heat_treating_service": "msg: GMBCID.metal_heat_treating_service {Metal heat treating service}",
    "metal_industry_suppliers": "msg: GMBCID.metal_industry_suppliers {Metal Industry Suppliers}",
    "metal_machinery_supplier": "msg: GMBCID.metal_machinery_supplier {Metal Machinery Supplier}",
    "metal_polishing_service": "msg: GMBCID.metal_polishing_service {Metal Polisher}",
    "metal_stamping_service": "msg: GMBCID.metal_stamping_service {Metal stamping service}",
    "metal_supplier": "msg: GMBCID.metal_supplier {Metal Supplier}",
    "metalhead_or_metal_musician": "msg: GMBCID.metalhead_or_metal_musician {Metalhead Or Metal Musician}",
    "metalware_producer": "msg: GMBCID.metalware_producer {Metalware Producer}",
    "metaphysical_supply_store": "msg: GMBCID.metaphysical_supply_store {Metaphysical Supply Shop}",
    "methodist_church": "msg: GMBCID.methodist_church {Methodist church}",
    "mexican_goods_store": "msg: GMBCID.mexican_goods_store {Mexican Goods Shop}",
    "mexican_grocery_store": "msg: GMBCID.mexican_grocery_store {Mexican Grocery Shop}",
    "mexican_restaurant": "msg: GMBCID.mexican_restaurant {Mexican restaurant}",
    "mexican_torta_restaurant": "msg: GMBCID.mexican_torta_restaurant {Mexican torta restaurant}",
    "mezzo_soprano": "msg: GMBCID.mezzo_soprano {Mezzo Soprano}",
    "mfr": "msg: GMBCID.mfr {Mfr}",
    "mg_dealer": "msg: GMBCID.mg_dealer {MG Dealer}",
    "microwave_oven_repair_service": "msg: GMBCID.microwave_oven_repair_service {Microwave Oven Repair Service}",
    "mid_atlantic_us_restaurant": "msg: GMBCID.mid_atlantic_us_restaurant {Mid-Atlantic restaurant (US)}",
    "middle_eastern_restaurant": "msg: GMBCID.middle_eastern_restaurant {Middle Eastern restaurant}",
    "middle_school": "msg: GMBCID.middle_school {Middle School}",
    "midwife": "msg: GMBCID.midwife {Midwife}",
    "military_hospital": "msg: GMBCID.military_hospital {Military Hospital}",
    "military_school": "msg: GMBCID.military_school {Military School}",
    "milk_delivery_service": "msg: GMBCID.milk_delivery_service {Milk delivery service}",
    "mill": "msg: GMBCID.mill {Mill}",
    "mine": "msg: GMBCID.mine {Mine}",
    "mineral_water_wholesale": "msg: GMBCID.mineral_water_wholesale {Mineral Water Wholesale}",
    "mini_dealer": "msg: GMBCID.mini_dealer {MINI Dealer}",
    "miniatures_store": "msg: GMBCID.miniatures_store {Miniatures Shop}",
    "minimalist_musician": "msg: GMBCID.minimalist_musician {Minimalist Musician}",
    "mining_equipment": "msg: GMBCID.mining_equipment {Mining Equipment}",
    "mirror_shop": "msg: GMBCID.mirror_shop {Mirror shop}",
    "miso_cutlet_restaurant": "msg: GMBCID.miso_cutlet_restaurant {Miso cutlet restaurant}",
    "missing_persons_organization": "msg: GMBCID.missing_persons_organization {Missing Persons Organization}",
    "mobile_catering": "msg: GMBCID.mobile_catering {Mobile caterer}",
    "mobile_hairdresser": "msg: GMBCID.mobile_hairdresser {Mobile hairdresser}",
    "mobile_home_supply_store": "msg: GMBCID.mobile_home_supply_store {Mobile Home Supply Shop}",
    "mobile_money_agent": "msg: GMBCID.mobile_money_agent {Mobile money agent}",
    "mobile_network_operator": "msg: GMBCID.mobile_network_operator {Mobile network operator}",
    "mobility_equipment_supplier": "msg: GMBCID.mobility_equipment_supplier {Mobility Equipment Supplier}",
    "model_design_company": "msg: GMBCID.model_design_company {Model design company}",
    "model_portfolio_studio": "msg: GMBCID.model_portfolio_studio {Model portfolio studio}",
    "model_train_store": "msg: GMBCID.model_train_store {Model Train Shop}",
    "modeling_agency": "msg: GMBCID.modeling_agency {Modelling agency}",
    "modeling_school": "msg: GMBCID.modeling_school {Modelling School}",
    "modern_art_museum": "msg: GMBCID.modern_art_museum {Modern art museum}",
    "modern_british_restaurant": "msg: GMBCID.modern_british_restaurant {Modern British restaurant}",
    "modern_european_restaurant": "msg: GMBCID.modern_european_restaurant {Modern European restaurant}",
    "modern_french_restaurant": "msg: GMBCID.modern_french_restaurant {Modern French restaurant}",
    "modern_indian_restaurant": "msg: GMBCID.modern_indian_restaurant {Modern Indian restaurant}",
    "modular_home_builder": "msg: GMBCID.modular_home_builder {Modular home builder}",
    "modular_home_dealer": "msg: GMBCID.modular_home_dealer {Modular Home Dealer}",
    "molding_supplier": "msg: GMBCID.molding_supplier {Moulding Supplier}",
    "momo_restaurant": "msg: GMBCID.momo_restaurant {Momo restaurant}",
    "money_order_service": "msg: GMBCID.money_order_service {Money order service}",
    "money_transfer_service": "msg: GMBCID.money_transfer_service {Money transfer service}",
    "mongolian_barbecue_restaurant": "msg: GMBCID.mongolian_barbecue_restaurant {Mongolian barbecue restaurant}",
    "monja_restaurant": "msg: GMBCID.monja_restaurant {Monjayaki restaurant}",
    "monogramming_service": "msg: GMBCID.monogramming_service {Monogramming service}",
    "montessori_school": "msg: GMBCID.montessori_school {Montessori School}",
    "monument_maker": "msg: GMBCID.monument_maker {Monument maker}",
    "moped_dealer": "msg: GMBCID.moped_dealer {Moped Dealer}",
    "moravian_church": "msg: GMBCID.moravian_church {Moravian church}",
    "mordern_izakaya_restaurants": "msg: GMBCID.mordern_izakaya_restaurants {Modern izakaya restaurant}",
    "moroccan_restaurant": "msg: GMBCID.moroccan_restaurant {Moroccan restaurant}",
    "mortgage_broker": "msg: GMBCID.mortgage_broker {Mortgage broker}",
    "mortgage_lender": "msg: GMBCID.mortgage_lender {Mortgage lender}",
    "motel": "msg: GMBCID.motel {Motel}",
    "motor_scooter_dealer": "msg: GMBCID.motor_scooter_dealer {Motor Scooter Dealer}",
    "motorcycle_dealer": "msg: GMBCID.motorcycle_dealer {Motorcycle Dealer}",
    "motorcycle_driving_school": "msg: GMBCID.motorcycle_driving_school {Motorcycle Driving School}",
    "motorcycle_insurance_agency": "msg: GMBCID.motorcycle_insurance_agency {Motorcycle Insurance Agency}",
    "motorcycle_parts_store": "msg: GMBCID.motorcycle_parts_store {Motorbike parts shop}",
    "motorcycle_shop": "msg: GMBCID.motorcycle_shop {Motorcycle shop}",
    "motoring_club": "msg: GMBCID.motoring_club {Car Club}",
    "motorsports_store": "msg: GMBCID.motorsports_store {Motorsports Shop}",
    "mountaineering_class": "msg: GMBCID.mountaineering_class {Mountaineering Class}",
    "movie_theater": "msg: GMBCID.movie_theater {Cinema}",
    "moving_and_storage_service": "msg: GMBCID.moving_and_storage_service {Moving and Storage Service}",
    "moving_company": "msg: GMBCID.moving_company {Moving Company}",
    "mri_center": "msg: GMBCID.mri_center {MRI Center}",
    "muay_thai_boxing_gym": "msg: GMBCID.muay_thai_boxing_gym {Muay Thai boxing gym}",
    "muffler_shop": "msg: GMBCID.muffler_shop {Exhaust Silencer Shop}",
    "mughlai_restaurant": "msg: GMBCID.mughlai_restaurant {Mughlai Restaurant}",
    "mulch_supplier": "msg: GMBCID.mulch_supplier {Mulch Supplier}",
    "murtabak_restaurant": "msg: GMBCID.murtabak_restaurant {Murtabak restaurant}",
    "museum": "msg: GMBCID.museum {Museum}",
    "museum_of_space_history": "msg: GMBCID.museum_of_space_history {Museum of space history}",
    "museum_of_zoology": "msg: GMBCID.museum_of_zoology {Museum of zoology}",
    "music_and_entertainment_show": "msg: GMBCID.music_and_entertainment_show {Music And Entertainment Show}",
    "music_box_store": "msg: GMBCID.music_box_store {Music Box Shop}",
    "music_college": "msg: GMBCID.music_college {Music College}",
    "music_hobbyist": "msg: GMBCID.music_hobbyist {Music Hobbyist}",
    "music_instructor": "msg: GMBCID.music_instructor {Music Instructor}",
    "music_management_and_promotion": "msg: GMBCID.music_management_and_promotion {Music management and promotion}",
    "music_producer": "msg: GMBCID.music_producer {Music producer}",
    "music_publisher": "msg: GMBCID.music_publisher {Music publisher}",
    "music_school": "msg: GMBCID.music_school {Music school}",
    "music_store": "msg: GMBCID.music_store {Music shop}",
    "musical_instrument_manufacturer": "msg: GMBCID.musical_instrument_manufacturer {Musical Instrument Manufacturer}",
    "musical_instrument_store": "msg: GMBCID.musical_instrument_store {Musical Instrument Shop}",
    "musician": "msg: GMBCID.musician {Musician}",
    "musician_and_composer": "msg: GMBCID.musician_and_composer {Musician and composer}",
    "mutton_barbecue_restaurant": "msg: GMBCID.mutton_barbecue_restaurant {Mutton barbecue restaurant}",
    "nail_artist_portfolio": "msg: GMBCID.nail_artist_portfolio {Nail Artist Portfolio}",
    "nail_salon": "msg: GMBCID.nail_salon {Nail salon}",
    "nasi_goreng_restaurant": "msg: GMBCID.nasi_goreng_restaurant {Nasi goreng restaurant}",
    "nasi_restaurant": "msg: GMBCID.nasi_restaurant {Nasi restaurant}",
    "nasi_uduk_restaurant": "msg: GMBCID.nasi_uduk_restaurant {Nasi uduk restaurant}",
    "national_forest": "msg: GMBCID.national_forest {National Forest}",
    "national_health_foundation": "msg: GMBCID.national_health_foundation {National health foundation}",
    "national_library": "msg: GMBCID.national_library {National Library}",
    "national_museum": "msg: GMBCID.national_museum {National museum}",
    "national_park": "msg: GMBCID.national_park {National Park}",
    "national_reserve": "msg: GMBCID.national_reserve {National Reserve}",
    "native_american_flute_player": "msg: GMBCID.native_american_flute_player {Native American Flute Player}",
    "native_american_goods_store": "msg: GMBCID.native_american_goods_store {Native American Goods Shop}",
    "native_american_restaurant": "msg: GMBCID.native_american_restaurant {Native American restaurant}",
    "natural_history_museum": "msg: GMBCID.natural_history_museum {Natural history museum}",
    "natural_stone_exporter": "msg: GMBCID.natural_stone_exporter {Natural Stone Exporter}",
    "natural_stone_supplier": "msg: GMBCID.natural_stone_supplier {Natural Stone Supplier}",
    "natural_stone_wholesaler": "msg: GMBCID.natural_stone_wholesaler {Natural Stone Wholesaler}",
    "nature_and_wildlife_photography_portfolio": "msg: GMBCID.nature_and_wildlife_photography_portfolio {Nature And Wildlife Photography Portfolio}",
    "nature_preserve": "msg: GMBCID.nature_preserve {Nature reserve}",
    "naturopathic_practitioner": "msg: GMBCID.naturopathic_practitioner {Naturopathic Practitioner}",
    "navarraise_restaurant": "msg: GMBCID.navarraise_restaurant {Navarraise restaurant}",
    "neapolitan_restaurant": "msg: GMBCID.neapolitan_restaurant {Neapolitan restaurant}",
    "needlework_shop": "msg: GMBCID.needlework_shop {Needlework shop}",
    "neon_sign_shop": "msg: GMBCID.neon_sign_shop {Neon sign shop}",
    "neonatal_physician": "msg: GMBCID.neonatal_physician {Neonatal Physician}",
    "nepalese_restaurant": "msg: GMBCID.nepalese_restaurant {Nepalese restaurant}",
    "nephrologist": "msg: GMBCID.nephrologist {Nephrologist}",
    "netball_club": "msg: GMBCID.netball_club {Netball Club}",
    "neurologist": "msg: GMBCID.neurologist {Neurologist}",
    "neurophysiologist": "msg: GMBCID.neurophysiologist {Neurophysiologist}",
    "neuropsychologist": "msg: GMBCID.neuropsychologist {Neuropsychologist}",
    "neurosurgeon": "msg: GMBCID.neurosurgeon {Neurosurgeon}",
    "new_age_artist": "msg: GMBCID.new_age_artist {New Age Artist}",
    "new_age_church": "msg: GMBCID.new_age_church {New Age church}",
    "new_england_restaurant": "msg: GMBCID.new_england_restaurant {New England restaurant}",
    "new_us_american_restaurant": "msg: GMBCID.new_us_american_restaurant {New American restaurant}",
    "new_years_tree_market": "msg: GMBCID.new_years_tree_market {New Years tree market}",
    "new_zealand_restaurant": "msg: GMBCID.new_zealand_restaurant {New Zealand restaurant}",
    "newspaper_advertising_department": "msg: GMBCID.newspaper_advertising_department {Newspaper advertising department}",
    "newspaper_publisher": "msg: GMBCID.newspaper_publisher {Newspaper publisher}",
    "nft_artist_portfolio": "msg: GMBCID.nft_artist_portfolio {Nft Artist Portfolio}",
    "nicaraguan_restaurant": "msg: GMBCID.nicaraguan_restaurant {Nicaraguan restaurant}",
    "night_club": "msg: GMBCID.night_club {Nightclub}",
    "night_market": "msg: GMBCID.night_market {Night market}",
    "noise_music_artist": "msg: GMBCID.noise_music_artist {Noise Music Artist}",
    "non_denominational_church": "msg: GMBCID.non_denominational_church {Non-denominational church}",
    "non_governmental_organization": "msg: GMBCID.non_governmental_organization {Non-Governmental Organisation}",
    "non_profit_organization": "msg: GMBCID.non_profit_organization {Non-profit organisation}",
    "non_smoking_holiday_home": "msg: GMBCID.non_smoking_holiday_home {Non Smoking Holiday Home}",
    "non_vegetarian_restaurant": "msg: GMBCID.non_vegetarian_restaurant {Non Vegetarian Restaurant}",
    "noodle_shop": "msg: GMBCID.noodle_shop {Noodle shop}",
    "north_african_restaurant": "msg: GMBCID.north_african_restaurant {North African restaurant}",
    "north_eastern_indian_restaurant": "msg: GMBCID.north_eastern_indian_restaurant {North Eastern Indian restaurant}",
    "north_indian_restaurant": "msg: GMBCID.north_indian_restaurant {North Indian Restaurant}",
    "northern_italian_restaurant": "msg: GMBCID.northern_italian_restaurant {Northern Italian restaurant}",
    "norwegian_restaurant": "msg: GMBCID.norwegian_restaurant {Norwegian restaurant}",
    "nose_flute_player": "msg: GMBCID.nose_flute_player {Nose Flute Player}",
    "notaries_association": "msg: GMBCID.notaries_association {Notaries association}",
    "notary_public": "msg: GMBCID.notary_public {Notary public}",
    "notions_store": "msg: GMBCID.notions_store {Needlecraft shop}",
    "novelties_wholesaler": "msg: GMBCID.novelties_wholesaler {Novelties Wholesaler}",
    "novelty_store": "msg: GMBCID.novelty_store {Novelty shop}",
    "nuclear_engineer": "msg: GMBCID.nuclear_engineer {Nuclear Engineer}",
    "nudist_club": "msg: GMBCID.nudist_club {Nudist club}",
    "nuevo_latino_restaurant": "msg: GMBCID.nuevo_latino_restaurant {Nuevo Latino restaurant}",
    "numerologist": "msg: GMBCID.numerologist {Numerologist}",
    "nunnery": "msg: GMBCID.nunnery {Convent}",
    "nurse_practitioner": "msg: GMBCID.nurse_practitioner {Nurse Practitioner}",
    "nursery_school": "msg: GMBCID.nursery_school {Nursery school}",
    "nursing_agency": "msg: GMBCID.nursing_agency {Nursing agency}",
    "nursing_association": "msg: GMBCID.nursing_association {Nursing association}",
    "nursing_home": "msg: GMBCID.nursing_home {Nursing home}",
    "nursing_school": "msg: GMBCID.nursing_school {Nursing college}",
    "nut_store": "msg: GMBCID.nut_store {Nut Shop}",
    "nutritionist": "msg: GMBCID.nutritionist {Nutritionist}",
    "nyonya_restaurant": "msg: GMBCID.nyonya_restaurant {Nyonya restaurant}",
    "oaxacan_restaurant": "msg: GMBCID.oaxacan_restaurant {Oaxacan restaurant}",
    "obanzai_cuisine": "msg: GMBCID.obanzai_cuisine {Obanzai restaurant}",
    "oboist": "msg: GMBCID.oboist {Oboist}",
    "observation_deck": "msg: GMBCID.observation_deck {Observation Deck}",
    "observatory": "msg: GMBCID.observatory {Observatory}",
    "obstetrics_gynecology_clinic": "msg: GMBCID.obstetrics_gynecology_clinic {Obstetrics Gynecology Clinic}",
    "occupational_health_service": "msg: GMBCID.occupational_health_service {Occupational Health Service}",
    "occupational_medical_physician": "msg: GMBCID.occupational_medical_physician {Occupational Medical Physician}",
    "occupational_safety_and_health": "msg: GMBCID.occupational_safety_and_health {Occupational Safety and Health}",
    "occupational_therapist": "msg: GMBCID.occupational_therapist {Occupational Therapist}",
    "oden_restaurant": "msg: GMBCID.oden_restaurant {Oden restaurant}",
    "odia_restaurant": "msg: GMBCID.odia_restaurant {Odia restaurant}",
    "off_road_race_track": "msg: GMBCID.off_road_race_track {Off-road race track}",
    "offal_pot_cooking": "msg: GMBCID.offal_pot_cooking {Offal pot cooking restaurant}",
    "office_accessories_wholesaler": "msg: GMBCID.office_accessories_wholesaler {Office Accessories Wholesaler}",
    "office_equipment_repair_service": "msg: GMBCID.office_equipment_repair_service {Office equipment repair service}",
    "office_equipment_supplier": "msg: GMBCID.office_equipment_supplier {Office Equipment Supplier}",
    "office_furniture_store": "msg: GMBCID.office_furniture_store {Office Furniture Shop}",
    "office_refurbishment_service": "msg: GMBCID.office_refurbishment_service {Office refurbishment service}",
    "office_supply_wholesaler": "msg: GMBCID.office_supply_wholesaler {Office Supply Wholesaler}",
    "oil_and_gas_exploration_service": "msg: GMBCID.oil_and_gas_exploration_service {Oil and gas exploration service}",
    "oil_change_service": "msg: GMBCID.oil_change_service {Oil change service}",
    "oil_field_equipment_supplier": "msg: GMBCID.oil_field_equipment_supplier {Oil Field Equipment Supplier}",
    "oil_store": "msg: GMBCID.oil_store {Oil Store}",
    "oil_wholesaler": "msg: GMBCID.oil_wholesaler {Oil Wholesaler}",
    "oilfield": "msg: GMBCID.oilfield {Oilfield}",
    "okonomiyaki_restaurant": "msg: GMBCID.okonomiyaki_restaurant {Okonomiyaki restaurant}",
    "olive_oil_bottling_company": "msg: GMBCID.olive_oil_bottling_company {Olive Oil Bottling Company}",
    "olive_oil_cooperative": "msg: GMBCID.olive_oil_cooperative {Olive Oil Cooperative}",
    "olive_oil_manufacturer": "msg: GMBCID.olive_oil_manufacturer {Olive Oil Manufacturer}",
    "oncologist": "msg: GMBCID.oncologist {Oncologist}",
    "online_conference": "msg: GMBCID.online_conference {Online Conference}",
    "online_education_event": "msg: GMBCID.online_education_event {Online Education Event}",
    "online_event": "msg: GMBCID.online_event {Online Event}",
    "online_shop": "msg: GMBCID.online_shop {Online Shop}",
    "online_workshop": "msg: GMBCID.online_workshop {Online Workshop}",
    "open_air_museum": "msg: GMBCID.open_air_museum {Open-Air Museum}",
    "open_university": "msg: GMBCID.open_university {Open University}",
    "opera_house": "msg: GMBCID.opera_house {Opera house}",
    "opera_singer": "msg: GMBCID.opera_singer {Opera Singer}",
    "ophthalmologist": "msg: GMBCID.ophthalmologist {Ophthalmologist}",
    "ophthalmology_clinic": "msg: GMBCID.ophthalmology_clinic {Ophthalmology Clinic}",
    "optical_instrument_repair_service": "msg: GMBCID.optical_instrument_repair_service {Optical instrument repair service}",
    "optical_products_manufacturer": "msg: GMBCID.optical_products_manufacturer {Optical Products Manufacturer}",
    "optical_wholesaler": "msg: GMBCID.optical_wholesaler {Optical Wholesaler}",
    "optician": "msg: GMBCID.optician {Optician}",
    "optometrist": "msg: GMBCID.optometrist {Optometrist}",
    "oral_maxillofacial_surgeon": "msg: GMBCID.oral_maxillofacial_surgeon {Oral and Maxillofacial Surgeon}",
    "oral_surgeon": "msg: GMBCID.oral_surgeon {Oral Surgeon}",
    "orchard": "msg: GMBCID.orchard {Orchard}",
    "orchestra": "msg: GMBCID.orchestra {Musical Band and Orchestra}",
    "orchestra_member": "msg: GMBCID.orchestra_member {Orchestra Member}",
    "orchid_grower": "msg: GMBCID.orchid_grower {Orchid Grower}",
    "organic_food_store": "msg: GMBCID.organic_food_store {Organic Food Shop}",
    "organic_restaurant": "msg: GMBCID.organic_restaurant {Organic restaurant}",
    "organic_store": "msg: GMBCID.organic_store {Organic Shop}",
    "organisation": "msg: GMBCID.organisation {Organisation}",
    "organist": "msg: GMBCID.organist {Organist}",
    "organization": "msg: GMBCID.organization {Organization}",
    "oriental_goods_store": "msg: GMBCID.oriental_goods_store {Oriental Goods Shop}",
    "oriental_medicine_store": "msg: GMBCID.oriental_medicine_store {Oriental medicine store}",
    "oriental_rug_store": "msg: GMBCID.oriental_rug_store {Oriental Rug Shop}",
    "orphan_asylum": "msg: GMBCID.orphan_asylum {Orphan asylum}",
    "orphanage": "msg: GMBCID.orphanage {Orphanage}",
    "orthodontist": "msg: GMBCID.orthodontist {Orthodontist}",
    "orthodox_church": "msg: GMBCID.orthodox_church {Orthodox church}",
    "orthopedic_clinic": "msg: GMBCID.orthopedic_clinic {Orthopedic Clinic}",
    "orthopedic_shoe_store": "msg: GMBCID.orthopedic_shoe_store {Orthopaedic Shoe Shop}",
    "orthopedic_supplies_store": "msg: GMBCID.orthopedic_supplies_store {Orthopedic supplies store}",
    "orthopedic_surgeon": "msg: GMBCID.orthopedic_surgeon {Orthopedic Surgeon}",
    "orthoptist": "msg: GMBCID.orthoptist {Orthoptist}",
    "orthotics_and_prosthetics_service": "msg: GMBCID.orthotics_and_prosthetics_service {Orthotics and Prosthetics Service}",
    "osteopath": "msg: GMBCID.osteopath {Osteopath}",
    "otolaryngologist": "msg: GMBCID.otolaryngologist {Otolaryngologist}",
    "otolaryngology_clinic": "msg: GMBCID.otolaryngology_clinic {Otolaryngology Clinic}",
    "outboard_motor_store": "msg: GMBCID.outboard_motor_store {Outboard Motor Shop}",
    "outdoor_activity_organizer": "msg: GMBCID.outdoor_activity_organizer {Outdoor activity organiser}",
    "outdoor_clothing_and_equipment_shop": "msg: GMBCID.outdoor_clothing_and_equipment_shop {Outdoor clothing and equipment shop}",
    "outdoor_equestrian_facility": "msg: GMBCID.outdoor_equestrian_facility {Outdoor equestrian facility}",
    "outdoor_furniture_store": "msg: GMBCID.outdoor_furniture_store {Outdoor furniture shop}",
    "outdoor_movie_theatre": "msg: GMBCID.outdoor_movie_theatre {Outdoor cinema}",
    "outdoor_sports_store": "msg: GMBCID.outdoor_sports_store {Outdoor Sports Shop}",
    "outdoor_swimming_pool": "msg: GMBCID.outdoor_swimming_pool {Outdoor Swimming Pool}",
    "outerwear_store": "msg: GMBCID.outerwear_store {Outerwear Shop}",
    "outlet_store": "msg: GMBCID.outlet_store {Factory outlet shop}",
    "oxygen_equipment_supplier": "msg: GMBCID.oxygen_equipment_supplier {Oxygen Equipment Supplier}",
    "oyster_bar_restaurant": "msg: GMBCID.oyster_bar_restaurant {Oyster bar restaurant}",
    "oyster_supplier": "msg: GMBCID.oyster_supplier {Oyster Supplier}",
    "paan_shop": "msg: GMBCID.paan_shop {Paan shop}",
    "pacific_rim_restaurant": "msg: GMBCID.pacific_rim_restaurant {Pacific Rim restaurant}",
    "packaging_company": "msg: GMBCID.packaging_company {Packaging Company}",
    "packaging_machinery": "msg: GMBCID.packaging_machinery {Packaging Machinery}",
    "padang_restaurant": "msg: GMBCID.padang_restaurant {Padang restaurant}",
    "padel_club": "msg: GMBCID.padel_club {Padel club}",
    "pain_management_physician": "msg: GMBCID.pain_management_physician {Pain Management Physician}",
    "paint_manufacturer": "msg: GMBCID.paint_manufacturer {Paint Manufacturer}",
    "paint_store": "msg: GMBCID.paint_store {Paint shop}",
    "paint_stripping_company": "msg: GMBCID.paint_stripping_company {Paint Stripping Company}",
    "paintball_center": "msg: GMBCID.paintball_center {Paintball centre}",
    "paintball_store": "msg: GMBCID.paintball_store {Paintball Shop}",
    "painter": "msg: GMBCID.painter {Painter and Decorator}",
    "painter_portfolio": "msg: GMBCID.painter_portfolio {Painter Portfolio}",
    "painting": "msg: GMBCID.painting {Painting}",
    "painting_lessons": "msg: GMBCID.painting_lessons {Painting Lessons}",
    "painting_studio": "msg: GMBCID.painting_studio {Painting studio}",
    "paintings_store": "msg: GMBCID.paintings_store {Paintings store}",
    "paisa_restaurant": "msg: GMBCID.paisa_restaurant {Paisa restaurant}",
    "pakistani_restaurant": "msg: GMBCID.pakistani_restaurant {Pakistani restaurant}",
    "palatine_restaurant": "msg: GMBCID.palatine_restaurant {Palatine restaurant}",
    "pallet_supplier": "msg: GMBCID.pallet_supplier {Pallet Supplier}",
    "pan_asian_restaurant": "msg: GMBCID.pan_asian_restaurant {Pan-Asian restaurant}",
    "pan_flute_player": "msg: GMBCID.pan_flute_player {Pan Flute Player}",
    "pan_latin_restaurant": "msg: GMBCID.pan_latin_restaurant {Pan-Latin restaurant}",
    "pancake_house": "msg: GMBCID.pancake_house {Pancake restaurant}",
    "paper_bag_supplier": "msg: GMBCID.paper_bag_supplier {Paper Bag Supplier}",
    "paper_distributor": "msg: GMBCID.paper_distributor {Paper Distributor}",
    "paper_exporter": "msg: GMBCID.paper_exporter {Paper Exporter}",
    "paper_mill": "msg: GMBCID.paper_mill {Paper Mill}",
    "paper_shredding_machine_supplier": "msg: GMBCID.paper_shredding_machine_supplier {Paper Shredding Machine Supplier}",
    "paper_store": "msg: GMBCID.paper_store {Paper Shop}",
    "parade": "msg: GMBCID.parade {Parade}",
    "paraguayan_restaurant": "msg: GMBCID.paraguayan_restaurant {Paraguayan restaurant}",
    "parapharmacy": "msg: GMBCID.parapharmacy {Parapharmacy}",
    "park": "msg: GMBCID.park {Park}",
    "park_and_ride": "msg: GMBCID.park_and_ride {Park and Ride}",
    "parochial_school": "msg: GMBCID.parochial_school {Parochial School}",
    "parsi_restaurant": "msg: GMBCID.parsi_restaurant {Parsi restaurant}",
    "party": "msg: GMBCID.party {Party}",
    "party_store": "msg: GMBCID.party_store {Party shop}",
    "pasta_shop": "msg: GMBCID.pasta_shop {Pasta shop}",
    "pastry_shop": "msg: GMBCID.pastry_shop {Pastry shop}",
    "patent_attorney": "msg: GMBCID.patent_attorney {Patent Attorney}",
    "pathologist": "msg: GMBCID.pathologist {Pathologist}",
    "patient_association": "msg: GMBCID.patient_association {Patient Association}",
    "patients_support_association": "msg: GMBCID.patients_support_association {Patients support association}",
    "patio_enclosure_supplier": "msg: GMBCID.patio_enclosure_supplier {Patio Enclosure Supplier}",
    "patisserie": "msg: GMBCID.patisserie {Patisserie}",
    "paving_contractor": "msg: GMBCID.paving_contractor {Paving contractor}",
    "paving_materials_supplier": "msg: GMBCID.paving_materials_supplier {Paving Materials Supplier}",
    "pawn_shop": "msg: GMBCID.pawn_shop {Pawn shop}",
    "pay_by_weight_restaurant": "msg: GMBCID.pay_by_weight_restaurant {Pay by weight restaurant}",
    "pecel_lele_restaurant": "msg: GMBCID.pecel_lele_restaurant {Pecel lele restaurant}",
    "pedal_steel_guitarist": "msg: GMBCID.pedal_steel_guitarist {Pedal Steel Guitarist}",
    "pediatric_clinic": "msg: GMBCID.pediatric_clinic {Pediatric Clinic}",
    "pediatric_dentist": "msg: GMBCID.pediatric_dentist {Paediatric Dentist}",
    "pediatric_dermatologist": "msg: GMBCID.pediatric_dermatologist {Pediatric Dermatologist}",
    "pediatric_endocrinologist": "msg: GMBCID.pediatric_endocrinologist {Pediatric Endocrinologist}",
    "pediatric_gastroenterologist": "msg: GMBCID.pediatric_gastroenterologist {Pediatric Gastroenterologist}",
    "pediatric_hematologist": "msg: GMBCID.pediatric_hematologist {Pediatric Hematologist}",
    "pediatric_nephrologist": "msg: GMBCID.pediatric_nephrologist {Pediatric Nephrologist}",
    "pediatric_neurologist": "msg: GMBCID.pediatric_neurologist {Pediatric Neurologist}",
    "pediatric_oncologist": "msg: GMBCID.pediatric_oncologist {Pediatric Oncologist}",
    "pediatric_ophthalmologist": "msg: GMBCID.pediatric_ophthalmologist {Paediatric Ophthalmologist}",
    "pediatric_orthopedic_surgeon": "msg: GMBCID.pediatric_orthopedic_surgeon {Pediatric Orthopedic Surgeon}",
    "pediatric_pulmonologist": "msg: GMBCID.pediatric_pulmonologist {Pediatric Pulmonologist}",
    "pediatric_rheumatologist": "msg: GMBCID.pediatric_rheumatologist {Pediatric Rheumatologist}",
    "pediatric_surgeon": "msg: GMBCID.pediatric_surgeon {Pediatric Surgeon}",
    "pediatric_urologist": "msg: GMBCID.pediatric_urologist {Pediatric Urologist}",
    "pediatrician": "msg: GMBCID.pediatrician {Pediatrician}",
    "pedorthist": "msg: GMBCID.pedorthist {Pedorthist}",
    "pempek_restaurant": "msg: GMBCID.pempek_restaurant {Pempek restaurant}",
    "pen_store": "msg: GMBCID.pen_store {Pen store}",
    "pennsylvania_dutch_restaurant": "msg: GMBCID.pennsylvania_dutch_restaurant {Pennsylvania Dutch restaurant}",
    "pensioners_club": "msg: GMBCID.pensioners_club {Pensioners Club}",
    "pentecostal_church": "msg: GMBCID.pentecostal_church {Pentecostal church}",
    "percussionist": "msg: GMBCID.percussionist {Percussionist}",
    "performer": "msg: GMBCID.performer {Performer}",
    "performing_arts_group": "msg: GMBCID.performing_arts_group {Performing arts group}",
    "performing_arts_theater": "msg: GMBCID.performing_arts_theater {Performing arts theatre}",
    "perfume_store": "msg: GMBCID.perfume_store {Perfume store}",
    "perinatal_center": "msg: GMBCID.perinatal_center {Perinatal Center}",
    "periodontist": "msg: GMBCID.periodontist {Periodontist}",
    "permanent_make_up_clinic": "msg: GMBCID.permanent_make_up_clinic {Permanent make-up clinic}",
    "persian_restaurant": "msg: GMBCID.persian_restaurant {Persian restaurant}",
    "personal_injury_lawyer": "msg: GMBCID.personal_injury_lawyer {Personal Injury Lawyer}",
    "personal_project": "msg: GMBCID.personal_project {Personal Project}",
    "personal_trainer": "msg: GMBCID.personal_trainer {Personal Trainer}",
    "personal_watercraft_dealer": "msg: GMBCID.personal_watercraft_dealer {Personal Watercraft Dealer}",
    "personal_website": "msg: GMBCID.personal_website {Personal Website}",
    "peruvian_restaurant": "msg: GMBCID.peruvian_restaurant {Peruvian restaurant}",
    "pest_control_service": "msg: GMBCID.pest_control_service {Pest control service}",
    "pet_funeral_services": "msg: GMBCID.pet_funeral_services {Pet Funeral Services}",
    "pet_keeping_hobbyist": "msg: GMBCID.pet_keeping_hobbyist {Pet Keeping Hobbyist}",
    "pet_moving_service": "msg: GMBCID.pet_moving_service {Pet Moving Service}",
    "pet_shop": "msg: GMBCID.pet_shop {Pet Shop}",
    "pet_store": "msg: GMBCID.pet_store {Pet Store}",
    "pet_supply_store": "msg: GMBCID.pet_supply_store {Pet Care Store}",
    "pet_website": "msg: GMBCID.pet_website {Pet Website}",
    "petrochemical_engineer": "msg: GMBCID.petrochemical_engineer {Petrochemical Engineer}",
    "peugeot_dealer": "msg: GMBCID.peugeot_dealer {Peugeot Dealer}",
    "pharmaceutical_lab": "msg: GMBCID.pharmaceutical_lab {Pharmaceutical Lab}",
    "pharmaceutical_products_wholesaler": "msg: GMBCID.pharmaceutical_products_wholesaler {Pharmaceutical Products Wholesaler}",
    "pho_restaurant": "msg: GMBCID.pho_restaurant {Pho restaurant}",
    "phone_repair_service": "msg: GMBCID.phone_repair_service {Phone repair service}",
    "photo_agency": "msg: GMBCID.photo_agency {Photo agency}",
    "photo_lab": "msg: GMBCID.photo_lab {Photo lab}",
    "photo_restoration_service": "msg: GMBCID.photo_restoration_service {Photo Restoration Service}",
    "photocopiers_supplier": "msg: GMBCID.photocopiers_supplier {Photocopiers Supplier}",
    "photographer": "msg: GMBCID.photographer {Photographer}",
    "photography_class": "msg: GMBCID.photography_class {Photography Class}",
    "photography_portfolio": "msg: GMBCID.photography_portfolio {Photography Portfolio}",
    "photography_school": "msg: GMBCID.photography_school {Photography Institute}",
    "photography_service": "msg: GMBCID.photography_service {Photography Service}",
    "photography_studio": "msg: GMBCID.photography_studio {Photography studio}",
    "physiatrist": "msg: GMBCID.physiatrist {Physiatrist}",
    "physical_examination_center": "msg: GMBCID.physical_examination_center {Physical Examination Center}",
    "physical_fitness_program": "msg: GMBCID.physical_fitness_program {Physical Fitness Programme}",
    "physician_assistant": "msg: GMBCID.physician_assistant {Physician Assistant}",
    "physician_referral_service": "msg: GMBCID.physician_referral_service {Physician Referral Service}",
    "physiotherapist": "msg: GMBCID.physiotherapist {Physiotherapist}",
    "physiotherapy_center": "msg: GMBCID.physiotherapy_center {Physiotherapy Center}",
    "physiotherapy_equip_supplier": "msg: GMBCID.physiotherapy_equip_supplier {Physiotherapy Equip Supplier}",
    "piadina_restaurant": "msg: GMBCID.piadina_restaurant {Piadina restaurant}",
    "pianist": "msg: GMBCID.pianist {Pianist}",
    "piano_bar": "msg: GMBCID.piano_bar {Piano bar}",
    "piano_instructor": "msg: GMBCID.piano_instructor {Piano Instructor}",
    "piano_maker": "msg: GMBCID.piano_maker {Piano Maker}",
    "piano_moving_service": "msg: GMBCID.piano_moving_service {Piano moving service}",
    "piano_repair_service": "msg: GMBCID.piano_repair_service {Piano repair service}",
    "piano_store": "msg: GMBCID.piano_store {Piano Shop}",
    "piano_tuning_service": "msg: GMBCID.piano_tuning_service {Piano Tuning Service}",
    "piccolo_player": "msg: GMBCID.piccolo_player {Piccolo Player}",
    "picnic_ground": "msg: GMBCID.picnic_ground {Picnic Ground}",
    "picture_frame_shop": "msg: GMBCID.picture_frame_shop {Picture framing shop}",
    "pie_shop": "msg: GMBCID.pie_shop {Pie shop}",
    "piedmontese_restaurant": "msg: GMBCID.piedmontese_restaurant {Piedmontese restaurant}",
    "pilaf_restaurant": "msg: GMBCID.pilaf_restaurant {Pilaf restaurant}",
    "pilates_studio": "msg: GMBCID.pilates_studio {Pilates studio}",
    "pile_driver": "msg: GMBCID.pile_driver {Pile Driver}",
    "pinball_machine_supplier": "msg: GMBCID.pinball_machine_supplier {Pinball Machine Supplier}",
    "pine_furniture_shop": "msg: GMBCID.pine_furniture_shop {Pine furniture shop}",
    "pipe_supplier": "msg: GMBCID.pipe_supplier {Pipe Supplier}",
    "pizza_delivery_service": "msg: GMBCID.pizza_delivery_service {Pizza delivery}",
    "pizza_restaurant": "msg: GMBCID.pizza_restaurant {Pizza restaurant}",
    "pizza_takeaway": "msg: GMBCID.pizza_takeaway {Pizza Takeaway}",
    "place_of_worship": "msg: GMBCID.place_of_worship {Place of worship}",
    "planetarium": "msg: GMBCID.planetarium {Planetarium}",
    "plant_and_machinery_hire": "msg: GMBCID.plant_and_machinery_hire {Plant and machinery hire}",
    "plast_window_store": "msg: GMBCID.plast_window_store {Plast Window Shop}",
    "plaster_contractor": "msg: GMBCID.plaster_contractor {Plasterer}",
    "plastic_bag_supplier": "msg: GMBCID.plastic_bag_supplier {Plastic Bag Supplier}",
    "plastic_bags_wholesaler": "msg: GMBCID.plastic_bags_wholesaler {Plastic Bags Wholesaler}",
    "plastic_injection_molding_service": "msg: GMBCID.plastic_injection_molding_service {Plastic Injection Molding Service}",
    "plastic_products_supplier": "msg: GMBCID.plastic_products_supplier {Plastic Products Supplier}",
    "plastic_resin_manufacturer": "msg: GMBCID.plastic_resin_manufacturer {Plastic Resin Manufacturer}",
    "plastic_surgeon": "msg: GMBCID.plastic_surgeon {Plastic Surgeon}",
    "plastic_surgery_clinic": "msg: GMBCID.plastic_surgery_clinic {Plastic Surgery Clinic}",
    "plastic_wholesaler": "msg: GMBCID.plastic_wholesaler {Plastic Wholesaler}",
    "plating_service": "msg: GMBCID.plating_service {Plating Service}",
    "play_school": "msg: GMBCID.play_school {Play School}",
    "playground": "msg: GMBCID.playground {Playground}",
    "playground_equipment_supplier": "msg: GMBCID.playground_equipment_supplier {Playground Equipment Supplier}",
    "plumber": "msg: GMBCID.plumber {Plumber}",
    "plus_size_clothing_store": "msg: GMBCID.plus_size_clothing_store {Plus Size Clothing Shop}",
    "plywood_supplier": "msg: GMBCID.plywood_supplier {Plywood Supplier}",
    "pneumatic_tools_supplier": "msg: GMBCID.pneumatic_tools_supplier {Pneumatic Tools Supplier}",
    "po_boys_restaurant": "msg: GMBCID.po_boys_restaurant {Po' boy restaurant}",
    "podcaster": "msg: GMBCID.podcaster {Podcaster}",
    "podiatrist": "msg: GMBCID.podiatrist {Podiatrist}",
    "polish_restaurant": "msg: GMBCID.polish_restaurant {Polish restaurant}",
    "polo_club": "msg: GMBCID.polo_club {Polo Club}",
    "polygraph_service": "msg: GMBCID.polygraph_service {Polygraph service}",
    "polymer_supplier": "msg: GMBCID.polymer_supplier {Polymer Supplier}",
    "polynesian_restaurant": "msg: GMBCID.polynesian_restaurant {Polynesian restaurant}",
    "polytechnic_school": "msg: GMBCID.polytechnic_school {Polytechnic School}",
    "polythene_and_plastic_sheeting_supplier": "msg: GMBCID.polythene_and_plastic_sheeting_supplier {Polythene and Plastic Sheeting Supplier}",
    "pond_fish_supplier": "msg: GMBCID.pond_fish_supplier {Pond Fish Supplier}",
    "pond_supply_store": "msg: GMBCID.pond_supply_store {Pond Supply Shop}",
    "pony_club": "msg: GMBCID.pony_club {Pony club}",
    "pool_academy": "msg: GMBCID.pool_academy {Pool Academy}",
    "pool_cleaning_service": "msg: GMBCID.pool_cleaning_service {Pool cleaning service}",
    "pop_star": "msg: GMBCID.pop_star {Pop Star}",
    "popcorn_store": "msg: GMBCID.popcorn_store {Popcorn Shop}",
    "pork_cutlet_rice_bowl_restaurant": "msg: GMBCID.pork_cutlet_rice_bowl_restaurant {Katsudon restaurant}",
    "porridge_restaurant": "msg: GMBCID.porridge_restaurant {Porridge restaurant}",
    "port_operating_company": "msg: GMBCID.port_operating_company {Port operating company}",
    "portable_building_manufacturer": "msg: GMBCID.portable_building_manufacturer {Portable Building Manufacturer}",
    "portable_toilet_supplier": "msg: GMBCID.portable_toilet_supplier {Portable Toilet Supplier}",
    "portfolio": "msg: GMBCID.portfolio {Portfolio}",
    "portrait_photography_portfolio": "msg: GMBCID.portrait_photography_portfolio {Portrait Photography Portfolio}",
    "portrait_studio": "msg: GMBCID.portrait_studio {Portrait studio}",
    "portuguese_restaurant": "msg: GMBCID.portuguese_restaurant {Portuguese restaurant}",
    "post_office": "msg: GMBCID.post_office {Post Office}",
    "poster_store": "msg: GMBCID.poster_store {Poster Shop}",
    "pottery_classes": "msg: GMBCID.pottery_classes {Pottery Classes}",
    "pottery_manufacturer": "msg: GMBCID.pottery_manufacturer {Pottery Manufacturer}",
    "pottery_store": "msg: GMBCID.pottery_store {Pottery shop}",
    "poultry_farm": "msg: GMBCID.poultry_farm {Poultry Farm}",
    "poultry_store": "msg: GMBCID.poultry_store {Poultry Shop}",
    "powder_coating_service": "msg: GMBCID.powder_coating_service {Powder coating service}",
    "power_plant_equipment_supplier": "msg: GMBCID.power_plant_equipment_supplier {Power Plant Equipment Supplier}",
    "powersports_vehicle_dealer": "msg: GMBCID.powersports_vehicle_dealer {Powersports Vehicle Dealer}",
    "pozole_restaurant": "msg: GMBCID.pozole_restaurant {Pozole restaurant}",
    "pr_portfolio": "msg: GMBCID.pr_portfolio {Pr Portfolio}",
    "pre_gymnasium_school": "msg: GMBCID.pre_gymnasium_school {Pre Gymnasium School}",
    "precision_engineer": "msg: GMBCID.precision_engineer {Precision engineer}",
    "pregnancy_care_center": "msg: GMBCID.pregnancy_care_center {Pregnancy Care Center}",
    "preparatory_school": "msg: GMBCID.preparatory_school {Preparatory school}",
    "presbyterian_church": "msg: GMBCID.presbyterian_church {Presbyterian church}",
    "preschool": "msg: GMBCID.preschool {Preschool}",
    "preschooleratory_school": "msg: GMBCID.preschooleratory_school {Preschooleratory School}",
    "press_advisory": "msg: GMBCID.press_advisory {Press advisory}",
    "pressure_washing_service": "msg: GMBCID.pressure_washing_service {Pressure washing service}",
    "pretzel_store": "msg: GMBCID.pretzel_store {Pretzel Shop}",
    "primary_school": "msg: GMBCID.primary_school {Primary School}",
    "printer_ink_refill_store": "msg: GMBCID.printer_ink_refill_store {Printer Ink Refill Shop}",
    "printer_repair_service": "msg: GMBCID.printer_repair_service {Printer repair service}",
    "printing_equipment_and_supplies": "msg: GMBCID.printing_equipment_and_supplies {Printing Equipment and Supplies}",
    "printing_equipment_supplier": "msg: GMBCID.printing_equipment_supplier {Printing Equipment Supplier}",
    "private_club": "msg: GMBCID.private_club {Private Club}",
    "private_college": "msg: GMBCID.private_college {Private College}",
    "private_equity_firm": "msg: GMBCID.private_equity_firm {Private equity firm}",
    "private_golf_course": "msg: GMBCID.private_golf_course {Private golf course}",
    "private_guest_room": "msg: GMBCID.private_guest_room {Private Guest Room}",
    "private_hospital": "msg: GMBCID.private_hospital {Private Hospital}",
    "private_investigator": "msg: GMBCID.private_investigator {Private investigator}",
    "private_school": "msg: GMBCID.private_school {Private School}",
    "private_sector_bank": "msg: GMBCID.private_sector_bank {Private Sector Bank}",
    "private_tutor": "msg: GMBCID.private_tutor {Private Tutor}",
    "proctologist": "msg: GMBCID.proctologist {Proctologist}",
    "produce_wholesaler": "msg: GMBCID.produce_wholesaler {Produce Wholesaler}",
    "professional_and_hobby_associations": "msg: GMBCID.professional_and_hobby_associations {Professional and hobby associations}",
    "professional_organizer": "msg: GMBCID.professional_organizer {Professional Organiser}",
    "professional_profile": "msg: GMBCID.professional_profile {Professional Profile}",
    "project": "msg: GMBCID.project {Project}",
    "project_management": "msg: GMBCID.project_management {Project Management}",
    "promotion_and_achievement_party": "msg: GMBCID.promotion_and_achievement_party {Promotion And Achievement Party}",
    "propane_supplier": "msg: GMBCID.propane_supplier {Propane Supplier}",
    "propeller_shop": "msg: GMBCID.propeller_shop {Propeller shop}",
    "property_administrator": "msg: GMBCID.property_administrator {Property administrator}",
    "property_investment": "msg: GMBCID.property_investment {Property Investment}",
    "property_maintenance": "msg: GMBCID.property_maintenance {Property maintenance}",
    "property_management_company": "msg: GMBCID.property_management_company {Property management company}",
    "prosthetics": "msg: GMBCID.prosthetics {Prosthetics}",
    "prosthodontist": "msg: GMBCID.prosthodontist {Prosthodontist}",
    "protective_clothing_supplier": "msg: GMBCID.protective_clothing_supplier {Protective Clothing Supplier}",
    "protestant_church": "msg: GMBCID.protestant_church {Protestant church}",
    "provence_restaurant": "msg: GMBCID.provence_restaurant {Provencal restaurant}",
    "psaltery_player": "msg: GMBCID.psaltery_player {Psaltery Player}",
    "psychiatric_hospital": "msg: GMBCID.psychiatric_hospital {Psychiatric Hospital}",
    "psychiatrist": "msg: GMBCID.psychiatrist {Psychiatrist}",
    "psychologist": "msg: GMBCID.psychologist {Psychologist}",
    "psychoneurological_specialized_clinic": "msg: GMBCID.psychoneurological_specialized_clinic {Psychoneurological Specialized Clinic}",
    "psychopedagogy_clinic": "msg: GMBCID.psychopedagogy_clinic {Psychopedagogy Clinic}",
    "psychosomatic_medical_practitioner": "msg: GMBCID.psychosomatic_medical_practitioner {Psychosomatic Medical Practitioner}",
    "psychotherapist": "msg: GMBCID.psychotherapist {Psychotherapist}",
    "pub": "msg: GMBCID.pub {Pub}",
    "public_bath": "msg: GMBCID.public_bath {Public Baths}",
    "public_golf_course": "msg: GMBCID.public_golf_course {Public golf course}",
    "public_library": "msg: GMBCID.public_library {Public Library}",
    "public_medical_center": "msg: GMBCID.public_medical_center {Public Medical Center}",
    "public_parking_space": "msg: GMBCID.public_parking_space {Public parking space}",
    "public_relations_consultant_portfolio": "msg: GMBCID.public_relations_consultant_portfolio {Public Relations Consultant Portfolio}",
    "public_sauna": "msg: GMBCID.public_sauna {Public sauna}",
    "public_school": "msg: GMBCID.public_school {Public School}",
    "pueblan_restaurant": "msg: GMBCID.pueblan_restaurant {Pueblan restaurant}",
    "puerto_rican_restaurant": "msg: GMBCID.puerto_rican_restaurant {Puerto Rican restaurant}",
    "pulmonologist": "msg: GMBCID.pulmonologist {Pulmonologist}",
    "pump_supplier": "msg: GMBCID.pump_supplier {Pump Supplier}",
    "pumping_equipment_and_service": "msg: GMBCID.pumping_equipment_and_service {Pumping Equipment & Service}",
    "punjabi_restaurant": "msg: GMBCID.punjabi_restaurant {Punjabi restaurant}",
    "punk_rocker": "msg: GMBCID.punk_rocker {Punk Rocker}",
    "pvc_windows_supplier": "msg: GMBCID.pvc_windows_supplier {PVC Windows Supplier}",
    "qawwali_singer": "msg: GMBCID.qawwali_singer {Qawwali Singer}",
    "quaker_church": "msg: GMBCID.quaker_church {Quaker church}",
    "quantity_surveyor": "msg: GMBCID.quantity_surveyor {Quantity surveyor}",
    "quebecois_restaurant": "msg: GMBCID.quebecois_restaurant {Québécois restaurant}",
    "quilt_shop": "msg: GMBCID.quilt_shop {Quilt shop}",
    "r_and_b_artist": "msg: GMBCID.r_and_b_artist {R And B Artist}",
    "race_course": "msg: GMBCID.race_course {Race Course}",
    "racing_car_parts_store": "msg: GMBCID.racing_car_parts_store {Racing Car Parts Shop}",
    "raclette_restaurant": "msg: GMBCID.raclette_restaurant {Raclette restaurant}",
    "racquetball_club": "msg: GMBCID.racquetball_club {Racketball Club}",
    "radiator_repair_service": "msg: GMBCID.radiator_repair_service {Radiator repair service}",
    "radiator_shop": "msg: GMBCID.radiator_shop {Radiator shop}",
    "radiologist": "msg: GMBCID.radiologist {Radiologist}",
    "radiotherapist": "msg: GMBCID.radiotherapist {Radiotherapist}",
    "rafting": "msg: GMBCID.rafting {Rafting}",
    "rai_musician": "msg: GMBCID.rai_musician {Rai Musician}",
    "rail_museum": "msg: GMBCID.rail_museum {Rail museum}",
    "railing_contractor": "msg: GMBCID.railing_contractor {Railing contractor}",
    "railroad_contractor": "msg: GMBCID.railroad_contractor {Railroad Contractor}",
    "railroad_equipment_supplier": "msg: GMBCID.railroad_equipment_supplier {Railroad Equipment Supplier}",
    "railroad_ties_supplier": "msg: GMBCID.railroad_ties_supplier {Railroad Ties Supplier}",
    "rainwater_tank_supplier": "msg: GMBCID.rainwater_tank_supplier {Rainwater Tank Supplier}",
    "rajasthani_restaurant": "msg: GMBCID.rajasthani_restaurant {Rajasthani Restaurant}",
    "ram_dealer": "msg: GMBCID.ram_dealer {Ram Dealer}",
    "ramen_restaurant": "msg: GMBCID.ramen_restaurant {Ramen restaurant}",
    "ranch": "msg: GMBCID.ranch {Ranch}",
    "rapper": "msg: GMBCID.rapper {Rapper}",
    "rare_book_store": "msg: GMBCID.rare_book_store {Rare Book Shop}",
    "raw_food_restaurant": "msg: GMBCID.raw_food_restaurant {Raw food restaurant}",
    "reading_enthusiast": "msg: GMBCID.reading_enthusiast {Reading Enthusiast}",
    "ready_mix_concrete_supplier": "msg: GMBCID.ready_mix_concrete_supplier {Ready-Mix Concrete Supplier}",
    "real_estate_attorney": "msg: GMBCID.real_estate_attorney {Real Estate Attorney}",
    "real_estate_consultant": "msg: GMBCID.real_estate_consultant {Real Estate Consultant}",
    "real_estate_lawyer": "msg: GMBCID.real_estate_lawyer {Real Estate Lawyer}",
    "real_estate_school": "msg: GMBCID.real_estate_school {Real estate college}",
    "real_estate_surveyor": "msg: GMBCID.real_estate_surveyor {Real Estate Surveyor}",
    "record_store": "msg: GMBCID.record_store {Record Shop}",
    "recorder_player": "msg: GMBCID.recorder_player {Recorder Player}",
    "recruiter": "msg: GMBCID.recruiter {Recruiter}",
    "reenactment_site": "msg: GMBCID.reenactment_site {Reenactment site}",
    "reflexologist": "msg: GMBCID.reflexologist {Reflexologist}",
    "reform_synagogue": "msg: GMBCID.reform_synagogue {Reform synagogue}",
    "reformed_church": "msg: GMBCID.reformed_church {Reformed church}",
    "refrigerated_transport_service": "msg: GMBCID.refrigerated_transport_service {Refrigerated transport service}",
    "refrigerator_repair_service": "msg: GMBCID.refrigerator_repair_service {Refrigerator repair service}",
    "refrigerator_store": "msg: GMBCID.refrigerator_store {Refrigerator Shop}",
    "refugee_camp": "msg: GMBCID.refugee_camp {Refugee camp}",
    "reggae_artist": "msg: GMBCID.reggae_artist {Reggae Artist}",
    "registered_general_nurse": "msg: GMBCID.registered_general_nurse {Registered General Nurse}",
    "rehabilitation_center": "msg: GMBCID.rehabilitation_center {Rehabilitation Center}",
    "reiki_therapist": "msg: GMBCID.reiki_therapist {Reiki Therapist}",
    "religious_book_store": "msg: GMBCID.religious_book_store {Religious Book Shop}",
    "religious_goods_store": "msg: GMBCID.religious_goods_store {Religious Goods Shop}",
    "religious_institution": "msg: GMBCID.religious_institution {Religious institution}",
    "religious_milestone": "msg: GMBCID.religious_milestone {Religious Milestone}",
    "religious_organization": "msg: GMBCID.religious_organization {Religious organisation}",
    "religious_school": "msg: GMBCID.religious_school {Religious School}",
    "repair_service": "msg: GMBCID.repair_service {Repair Service}",
    "reproductive_health_clinic": "msg: GMBCID.reproductive_health_clinic {Reproductive Health Clinic}",
    "reptile_store": "msg: GMBCID.reptile_store {Reptile Shop}",
    "research_engineer": "msg: GMBCID.research_engineer {Research Engineer}",
    "research_foundation": "msg: GMBCID.research_foundation {Research foundation}",
    "research_institute": "msg: GMBCID.research_institute {Research institute}",
    "research_project": "msg: GMBCID.research_project {Research Project}",
    "residential_college": "msg: GMBCID.residential_college {Residential College}",
    "residents_association": "msg: GMBCID.residents_association {Residents association}",
    "resort_hotel": "msg: GMBCID.resort_hotel {Resort Hotel}",
    "rest_stop": "msg: GMBCID.rest_stop {Rest Stop}",
    "restaurant": "msg: GMBCID.restaurant {Restaurant}",
    "restaurant_brasserie": "msg: GMBCID.restaurant_brasserie {Brasserie}",
    "resume": "msg: GMBCID.resume {Resume}",
    "resume_service": "msg: GMBCID.resume_service {Resume Service}",
    "retaining_wall_supplier": "msg: GMBCID.retaining_wall_supplier {Retaining Wall Supplier}",
    "retirement_party": "msg: GMBCID.retirement_party {Retirement Party}",
    "reunion": "msg: GMBCID.reunion {Reunion}",
    "rheumatologist": "msg: GMBCID.rheumatologist {Rheumatologist}",
    "rice_cake_shop": "msg: GMBCID.rice_cake_shop {Rice cake shop}",
    "rice_cracker_shop": "msg: GMBCID.rice_cracker_shop {Rice cracker shop}",
    "rice_restaurant": "msg: GMBCID.rice_restaurant {Rice restaurant}",
    "rice_shop": "msg: GMBCID.rice_shop {Rice shop}",
    "rice_wholesaler": "msg: GMBCID.rice_wholesaler {Rice Wholesaler}",
    "river_port": "msg: GMBCID.river_port {River Port}",
    "road_camera": "msg: GMBCID.road_camera {Road Camera}",
    "road_construction_machine_repair_service": "msg: GMBCID.road_construction_machine_repair_service {Road construction machine repair service}",
    "road_cycling": "msg: GMBCID.road_cycling {Road Cycling}",
    "roads_ports_and_canals_engineers_association": "msg: GMBCID.roads_ports_and_canals_engineers_association {Roads ports and canals engineers association}",
    "rock_climbing": "msg: GMBCID.rock_climbing {Rock climbing}",
    "rock_climbing_centre": "msg: GMBCID.rock_climbing_centre {Rock climbing gym}",
    "rock_climbing_instructor": "msg: GMBCID.rock_climbing_instructor {Rock Climbing Instructor}",
    "rock_music_club": "msg: GMBCID.rock_music_club {Rock music club}",
    "rock_shop": "msg: GMBCID.rock_shop {Rock shop}",
    "rocker_or_rock_musician": "msg: GMBCID.rocker_or_rock_musician {Rocker Or Rock Musician}",
    "rodeo": "msg: GMBCID.rodeo {Rodeo}",
    "rolled_metal_products_supplier": "msg: GMBCID.rolled_metal_products_supplier {Rolled Metal Products Supplier}",
    "roller_coaster": "msg: GMBCID.roller_coaster {Roller coaster}",
    "roller_skating_club": "msg: GMBCID.roller_skating_club {Roller skating club}",
    "roller_skating_rink": "msg: GMBCID.roller_skating_rink {Roller skating rink}",
    "roman_restaurant": "msg: GMBCID.roman_restaurant {Roman restaurant}",
    "romanian_restaurant": "msg: GMBCID.romanian_restaurant {Romanian restaurant}",
    "roofing_contractor": "msg: GMBCID.roofing_contractor {Roofing Service}",
    "rowing_area": "msg: GMBCID.rowing_area {Rowing area}",
    "rowing_club": "msg: GMBCID.rowing_club {Rowing club}",
    "rsl_club": "msg: GMBCID.rsl_club {RSL Club}",
    "rubber_stamp_store": "msg: GMBCID.rubber_stamp_store {Rubber Stamp Shop}",
    "rug_store": "msg: GMBCID.rug_store {Rug Shop}",
    "rugby_club": "msg: GMBCID.rugby_club {Rugby club}",
    "rugby_field": "msg: GMBCID.rugby_field {Rugby field}",
    "rugby_league_club": "msg: GMBCID.rugby_league_club {Rugby league club}",
    "rugby_store": "msg: GMBCID.rugby_store {Rugby Shop}",
    "running_store": "msg: GMBCID.running_store {Running Shop}",
    "russian_grocery_store": "msg: GMBCID.russian_grocery_store {Russian grocery store}",
    "russian_orthodox_church": "msg: GMBCID.russian_orthodox_church {Russian Orthodox church}",
    "russian_restaurant": "msg: GMBCID.russian_restaurant {Russian restaurant}",
    "rustic_furniture_store": "msg: GMBCID.rustic_furniture_store {Rustic Furniture Shop}",
    "rv_park": "msg: GMBCID.rv_park {RV Park}",
    "rv_supply_store": "msg: GMBCID.rv_supply_store {RV Supply Shop}",
    "safe_and_vault_shop": "msg: GMBCID.safe_and_vault_shop {Safe & vault shop}",
    "safety_equipment_supplier": "msg: GMBCID.safety_equipment_supplier {Safety Equipment Supplier}",
    "sailing_club": "msg: GMBCID.sailing_club {Sailing club}",
    "sailing_school": "msg: GMBCID.sailing_school {Sailing school}",
    "sailmaker": "msg: GMBCID.sailmaker {Sailmaker}",
    "salad_shop": "msg: GMBCID.salad_shop {Salad shop}",
    "salsa_bar": "msg: GMBCID.salsa_bar {Salsa bar}",
    "salsa_classes": "msg: GMBCID.salsa_classes {Salsa Dance Class}",
    "salvadoran_restaurant": "msg: GMBCID.salvadoran_restaurant {Salvadoran restaurant}",
    "samba_musician": "msg: GMBCID.samba_musician {Samba Musician}",
    "samba_school": "msg: GMBCID.samba_school {Samba school}",
    "sambo_school": "msg: GMBCID.sambo_school {Sambo school}",
    "sand_and_gravel_supplier": "msg: GMBCID.sand_and_gravel_supplier {Sand and Gravel Supplier}",
    "sand_plant": "msg: GMBCID.sand_plant {Sand Plant}",
    "sandblasting_service": "msg: GMBCID.sandblasting_service {Sandblasting service}",
    "sandwich_shop": "msg: GMBCID.sandwich_shop {Sandwich shop}",
    "sanitation_service": "msg: GMBCID.sanitation_service {Sanitation Service}",
    "sardinian_restaurant": "msg: GMBCID.sardinian_restaurant {Sardinian restaurant}",
    "saree_shop": "msg: GMBCID.saree_shop {Saree Shop}",
    "satay_restaurant": "msg: GMBCID.satay_restaurant {Satay restaurant}",
    "sauna": "msg: GMBCID.sauna {Sauna}",
    "sauna_club": "msg: GMBCID.sauna_club {Sauna club}",
    "sauna_store": "msg: GMBCID.sauna_store {Sauna Shop}",
    "saw_mill": "msg: GMBCID.saw_mill {Saw Mill}",
    "saw_sharpening_service": "msg: GMBCID.saw_sharpening_service {Saw sharpening service}",
    "saxophonist": "msg: GMBCID.saxophonist {Saxophonist}",
    "scaffolder": "msg: GMBCID.scaffolder {Scaffolder}",
    "scale_model_club": "msg: GMBCID.scale_model_club {Scale model club}",
    "scale_model_shop": "msg: GMBCID.scale_model_shop {Model shop}",
    "scale_repair_service": "msg: GMBCID.scale_repair_service {Scale repair service}",
    "scale_supplier": "msg: GMBCID.scale_supplier {Scale Supplier}",
    "scandinavian_restaurant": "msg: GMBCID.scandinavian_restaurant {Scandinavian restaurant}",
    "scenic_spot": "msg: GMBCID.scenic_spot {Scenic Spot}",
    "school": "msg: GMBCID.school {School}",
    "school_center": "msg: GMBCID.school_center {School Centre}",
    "school_for_the_deaf": "msg: GMBCID.school_for_the_deaf {School for the Deaf}",
    "school_project": "msg: GMBCID.school_project {School Project}",
    "school_supply_store": "msg: GMBCID.school_supply_store {School Supply Shop}",
    "science_and_research_conference": "msg: GMBCID.science_and_research_conference {Science And Research Conference}",
    "science_museum": "msg: GMBCID.science_museum {Science museum}",
    "scientific_equipment_supplier": "msg: GMBCID.scientific_equipment_supplier {Scientific Equipment Supplier}",
    "scottish_restaurant": "msg: GMBCID.scottish_restaurant {Scottish restaurant}",
    "scout_hall": "msg: GMBCID.scout_hall {Scout hall}",
    "scrapbooking": "msg: GMBCID.scrapbooking {Scrapbooking}",
    "scrapbooking_store": "msg: GMBCID.scrapbooking_store {Arts and Crafts Shop}",
    "screen_printing_shop": "msg: GMBCID.screen_printing_shop {Screen printing shop}",
    "screen_repair_service": "msg: GMBCID.screen_repair_service {Screen repair service}",
    "screen_store": "msg: GMBCID.screen_store {Screen Shop}",
    "screw_supplier": "msg: GMBCID.screw_supplier {Screw Supplier}",
    "scuba_instructor": "msg: GMBCID.scuba_instructor {Scuba Instructor}",
    "sculpter_portfolio": "msg: GMBCID.sculpter_portfolio {Sculpter Portfolio}",
    "sculptor": "msg: GMBCID.sculptor {Sculptor}",
    "sculpture": "msg: GMBCID.sculpture {Sculpture}",
    "sculpture_museum": "msg: GMBCID.sculpture_museum {Sculpture museum}",
    "seafood_donburi": "msg: GMBCID.seafood_donburi {Seafood donburi restaurant}",
    "seafood_restaurant": "msg: GMBCID.seafood_restaurant {Seafood restaurant}",
    "seafood_wholesaler": "msg: GMBCID.seafood_wholesaler {Seafood Wholesaler}",
    "seal_shop": "msg: GMBCID.seal_shop {Seal shop}",
    "seasonal_goods_store": "msg: GMBCID.seasonal_goods_store {Seasonal Goods Shop}",
    "seblak_restaurant": "msg: GMBCID.seblak_restaurant {Seblak restaurant}",
    "second_hand_shop": "msg: GMBCID.second_hand_shop {Secondhand Shop}",
    "secondary_school": "msg: GMBCID.secondary_school {Secondary School}",
    "secondary_school_three": "msg: GMBCID.secondary_school_three {Secondary School Three}",
    "security_system_supplier": "msg: GMBCID.security_system_supplier {Security System Supplier}",
    "seed_supplier": "msg: GMBCID.seed_supplier {Seed Supplier}",
    "self_catering_accommodation": "msg: GMBCID.self_catering_accommodation {Self-catering Accommodation}",
    "self_defense_school": "msg: GMBCID.self_defense_school {Self Defence School}",
    "self_service_car_wash": "msg: GMBCID.self_service_car_wash {Self service car wash}",
    "self_service_restaurant": "msg: GMBCID.self_service_restaurant {Self service restaurant}",
    "semi_conductor_supplier": "msg: GMBCID.semi_conductor_supplier {Semi Conductor Supplier}",
    "seminar": "msg: GMBCID.seminar {Seminar}",
    "seminary": "msg: GMBCID.seminary {Seminary}",
    "senior_citizens_care_service": "msg: GMBCID.senior_citizens_care_service {Senior Citizens Care Service}",
    "senior_high_school": "msg: GMBCID.senior_high_school {Senior High School}",
    "septic_system_service": "msg: GMBCID.septic_system_service {Septic tank service}",
    "serbian_restaurant": "msg: GMBCID.serbian_restaurant {Serbian restaurant}",
    "serviced_apartment": "msg: GMBCID.serviced_apartment {Service Apartment}",
    "seventh_day_adventist_church": "msg: GMBCID.seventh_day_adventist_church {Seventh-day Adventist church}",
    "sewage_disposal_service": "msg: GMBCID.sewage_disposal_service {Sewage Disposal Service}",
    "sewing_machine_repair_service": "msg: GMBCID.sewing_machine_repair_service {Sewing machine repair service}",
    "sewing_machine_store": "msg: GMBCID.sewing_machine_store {Sewing Machine Shop}",
    "sewing_shop": "msg: GMBCID.sewing_shop {Sewing shop}",
    "seychelles_restaurant": "msg: GMBCID.seychelles_restaurant {Seychelles restaurant}",
    "sfiha_restaurant": "msg: GMBCID.sfiha_restaurant {Sfiha restaurant}",
    "shabu_shabu_and_sukiyaki_restaurant": "msg: GMBCID.shabu_shabu_and_sukiyaki_restaurant {Sukiyaki and Shabu Shabu restaurant}",
    "shabu_shabu_restaurant": "msg: GMBCID.shabu_shabu_restaurant {Shabu-shabu Restaurant}",
    "shamanic_drummer": "msg: GMBCID.shamanic_drummer {Shamanic Drummer}",
    "shamisen_player": "msg: GMBCID.shamisen_player {Shamisen Player}",
    "shan_dong_restaurant": "msg: GMBCID.shan_dong_restaurant {Shandong restaurant}",
    "shanghainese_restaurant": "msg: GMBCID.shanghainese_restaurant {Shanghainese restaurant}",
    "sharpening_service": "msg: GMBCID.sharpening_service {Sharpening service}",
    "shawarma_restaurant": "msg: GMBCID.shawarma_restaurant {Shawarma restaurant}",
    "shed_builder": "msg: GMBCID.shed_builder {Shed builder}",
    "sheepskin_and_wool_products_supplier": "msg: GMBCID.sheepskin_and_wool_products_supplier {Sheepskin and Wool Products Supplier}",
    "sheepskin_coat_store": "msg: GMBCID.sheepskin_coat_store {Sheepskin coat store}",
    "sheet_metal_contractor": "msg: GMBCID.sheet_metal_contractor {Sheet metal contractor}",
    "sheet_music_store": "msg: GMBCID.sheet_music_store {Sheet Music Shop}",
    "shelter": "msg: GMBCID.shelter {Shelter}",
    "sheltered_housing": "msg: GMBCID.sheltered_housing {Sheltered housing}",
    "shelving_store": "msg: GMBCID.shelving_store {Shelving Shop}",
    "shinto_shrine": "msg: GMBCID.shinto_shrine {Shinto shrine}",
    "shoe_factory": "msg: GMBCID.shoe_factory {Shoe Factory}",
    "shoe_shining_service": "msg: GMBCID.shoe_shining_service {Shoe shining service}",
    "shoe_store": "msg: GMBCID.shoe_store {Shoe Shop}",
    "shoe_wholesaler": "msg: GMBCID.shoe_wholesaler {Shoe Wholesaler}",
    "shogi_lesson": "msg: GMBCID.shogi_lesson {Shogi Lesson}",
    "shooting_event_area": "msg: GMBCID.shooting_event_area {Shooting event area}",
    "shop": "msg: GMBCID.shop {Shop}",
    "shop_supermarket_furniture_store": "msg: GMBCID.shop_supermarket_furniture_store {Shop Supermarket Furniture Shop}",
    "shopping_center": "msg: GMBCID.shopping_center {Shopping Center}",
    "shower_door_shop": "msg: GMBCID.shower_door_shop {Shower screen shop}",
    "shredding_service": "msg: GMBCID.shredding_service {Shredding Service}",
    "shrimp_farm": "msg: GMBCID.shrimp_farm {Shrimp Farm}",
    "sichuan_restaurant": "msg: GMBCID.sichuan_restaurant {Sichuan restaurant}",
    "sicilian_restaurant": "msg: GMBCID.sicilian_restaurant {Sicilian restaurant}",
    "sightseeing_tour_agency": "msg: GMBCID.sightseeing_tour_agency {Sightseeing Tour Agency}",
    "sign_shop": "msg: GMBCID.sign_shop {Sign shop}",
    "sikh_temple": "msg: GMBCID.sikh_temple {Gurdwara}",
    "silk_plant_shop": "msg: GMBCID.silk_plant_shop {Silk plant shop}",
    "silk_store": "msg: GMBCID.silk_store {Silk Shop}",
    "simple_website": "msg: GMBCID.simple_website {Simple Website}",
    "singaporean_restaurant": "msg: GMBCID.singaporean_restaurant {Singaporean restaurant}",
    "singer": "msg: GMBCID.singer {Singer}",
    "singles_organization": "msg: GMBCID.singles_organization {Singles Organisation}",
    "sitarist": "msg: GMBCID.sitarist {Sitarist}",
    "sixth_form_college": "msg: GMBCID.sixth_form_college {Sixth Form College}",
    "ska_musician": "msg: GMBCID.ska_musician {Ska Musician}",
    "skate_sharpening_service": "msg: GMBCID.skate_sharpening_service {Skate sharpening service}",
    "skate_shop": "msg: GMBCID.skate_shop {Skate shop}",
    "skateboard_park": "msg: GMBCID.skateboard_park {Skateboard Park}",
    "skateboard_shop": "msg: GMBCID.skateboard_shop {Skateboard shop}",
    "skating_instructor": "msg: GMBCID.skating_instructor {Skating Instructor}",
    "skeet_shooting_range": "msg: GMBCID.skeet_shooting_range {Skeet shooting range}",
    "skewer_deep_frying": "msg: GMBCID.skewer_deep_frying {Kushiage and kushikatsu restaurant}",
    "ski_club": "msg: GMBCID.ski_club {Skiing Club}",
    "ski_repair_service": "msg: GMBCID.ski_repair_service {Ski repair service}",
    "ski_school": "msg: GMBCID.ski_school {Ski School}",
    "ski_store": "msg: GMBCID.ski_store {Ski Shop}",
    "skin_care_clinic": "msg: GMBCID.skin_care_clinic {Skin Care Clinic}",
    "skittle_club": "msg: GMBCID.skittle_club {Skittles Club}",
    "skoda_dealer": "msg: GMBCID.skoda_dealer {Skoda Dealer}",
    "skydiving_center": "msg: GMBCID.skydiving_center {Skydiving Centre}",
    "skylight_contractor": "msg: GMBCID.skylight_contractor {Skylight contractor}",
    "small_appliance_repair_service": "msg: GMBCID.small_appliance_repair_service {Small appliance repair service}",
    "small_claims_assistance_service": "msg: GMBCID.small_claims_assistance_service {Small Claims Assistance Service}",
    "small_engine_repair_service": "msg: GMBCID.small_engine_repair_service {Small engine repair service}",
    "small_plates_restaurant": "msg: GMBCID.small_plates_restaurant {Small plates restaurant}",
    "smart_car_dealer": "msg: GMBCID.smart_car_dealer {Smart Car Dealer}",
    "smoke_shop": "msg: GMBCID.smoke_shop {Smoke shop}",
    "snow_removal_service": "msg: GMBCID.snow_removal_service {Snow removal service}",
    "snowboard_shop": "msg: GMBCID.snowboard_shop {Snowboard shop}",
    "soba_noodle_shop": "msg: GMBCID.soba_noodle_shop {Soba noodle shop}",
    "soccer_club": "msg: GMBCID.soccer_club {Football club}",
    "soccer_field": "msg: GMBCID.soccer_field {Soccer Field}",
    "soccer_practice": "msg: GMBCID.soccer_practice {Football Practice}",
    "soccer_store": "msg: GMBCID.soccer_store {Football Shop}",
    "social_and_health_school": "msg: GMBCID.social_and_health_school {Social and Health School}",
    "social_club": "msg: GMBCID.social_club {Social club}",
    "social_security_attorney": "msg: GMBCID.social_security_attorney {Social Security Attorney}",
    "social_services_organization": "msg: GMBCID.social_services_organization {Social services organisation}",
    "sod_supplier": "msg: GMBCID.sod_supplier {Sod Supplier}",
    "sofa_store": "msg: GMBCID.sofa_store {Sofa store}",
    "soft_shelled_turtle_dish_restaurant": "msg: GMBCID.soft_shelled_turtle_dish_restaurant {Suppon restaurant}",
    "softball_club": "msg: GMBCID.softball_club {Softball club}",
    "softball_field": "msg: GMBCID.softball_field {Softball field}",
    "software_training_institute": "msg: GMBCID.software_training_institute {Software Training Institute}",
    "soil_testing_service": "msg: GMBCID.soil_testing_service {Soil testing service}",
    "solar_energy_contractor": "msg: GMBCID.solar_energy_contractor {Solar energy contractor}",
    "solar_energy_equipment_supplier": "msg: GMBCID.solar_energy_equipment_supplier {Solar Energy Equipment Supplier}",
    "solar_hot_water_system_supplier": "msg: GMBCID.solar_hot_water_system_supplier {Solar Hot Water System Supplier}",
    "solo_artist": "msg: GMBCID.solo_artist {Solo Artist}",
    "songwriter": "msg: GMBCID.songwriter {Songwriter}",
    "soondae_restaurant": "msg: GMBCID.soondae_restaurant {Soondae restaurant}",
    "soprano": "msg: GMBCID.soprano {Soprano}",
    "soto_ayam_restaurant": "msg: GMBCID.soto_ayam_restaurant {Soto ayam restaurant}",
    "soto_restaurant": "msg: GMBCID.soto_restaurant {Soto restaurant}",
    "soul_food_restaurant": "msg: GMBCID.soul_food_restaurant {Soul food restaurant}",
    "soup_restaurant": "msg: GMBCID.soup_restaurant {Soup Restaurant}",
    "soup_shop": "msg: GMBCID.soup_shop {Soup Shop}",
    "south_african_restaurant": "msg: GMBCID.south_african_restaurant {South African restaurant}",
    "south_american_restaurant": "msg: GMBCID.south_american_restaurant {South American restaurant}",
    "south_asia_restaurant": "msg: GMBCID.south_asia_restaurant {South Asian restaurant}",
    "south_east_asian_restaurant": "msg: GMBCID.south_east_asian_restaurant {South East Asian restaurant}",
    "south_indian_restaurant": "msg: GMBCID.south_indian_restaurant {South Indian restaurant}",
    "south_sulawesi_restaurant": "msg: GMBCID.south_sulawesi_restaurant {South Sulawesi restaurant}",
    "southern_italian_restaurant": "msg: GMBCID.southern_italian_restaurant {Southern Italian restaurant}",
    "southern_us_restaurant": "msg: GMBCID.southern_us_restaurant {Southern restaurant (US)}",
    "southwest_french_restaurant": "msg: GMBCID.southwest_french_restaurant {South West French restaurant}",
    "southwestern_us_restaurant": "msg: GMBCID.southwestern_us_restaurant {South Western restaurant (US)}",
    "souvenir_manufacturer": "msg: GMBCID.souvenir_manufacturer {Souvenir Manufacturer}",
    "souvenir_store": "msg: GMBCID.souvenir_store {Souvenir Shop}",
    "spa": "msg: GMBCID.spa {Spa}",
    "spa_and_health_club": "msg: GMBCID.spa_and_health_club {Spa and health club}",
    "spa_garden": "msg: GMBCID.spa_garden {Spa garden}",
    "spa_town": "msg: GMBCID.spa_town {Spa Town}",
    "space_enthusiast": "msg: GMBCID.space_enthusiast {Space Enthusiast}",
    "spanish_restaurant": "msg: GMBCID.spanish_restaurant {Spanish restaurant}",
    "special_education_school": "msg: GMBCID.special_education_school {Special Education School}",
    "specialist_psychiatry": "msg: GMBCID.specialist_psychiatry {Specialist Psychiatry}",
    "specialized_clinic": "msg: GMBCID.specialized_clinic {Specialized Clinic}",
    "specialized_hospital": "msg: GMBCID.specialized_hospital {Specialized Hospital}",
    "speech_pathologist": "msg: GMBCID.speech_pathologist {Speech Pathologist}",
    "sperm_bank": "msg: GMBCID.sperm_bank {Sperm Bank}",
    "spice_store": "msg: GMBCID.spice_store {Spice Shop}",
    "spices_exporter": "msg: GMBCID.spices_exporter {Spices Exporter}",
    "spices_wholesalers": "msg: GMBCID.spices_wholesalers {Spices Wholesalers}",
    "spiritist_center": "msg: GMBCID.spiritist_center {Spiritist Centre}",
    "spiritual_singer": "msg: GMBCID.spiritual_singer {Spiritual Singer}",
    "sport_lifesaving_club": "msg: GMBCID.sport_lifesaving_club {Sport Lifesaving Club}",
    "sport_show": "msg: GMBCID.sport_show {Sport Show}",
    "sport_tour_agency": "msg: GMBCID.sport_tour_agency {Sport Tour Agency}",
    "sporting_goods_store": "msg: GMBCID.sporting_goods_store {Sporting Goods Shop}",
    "sports_accessories_wholesaler": "msg: GMBCID.sports_accessories_wholesaler {Sports Accessories Wholesaler}",
    "sports_bar": "msg: GMBCID.sports_bar {Sports bar}",
    "sports_card_store": "msg: GMBCID.sports_card_store {Sports Card Shop}",
    "sports_club": "msg: GMBCID.sports_club {Sports club}",
    "sports_competition": "msg: GMBCID.sports_competition {Sports Competition}",
    "sports_complex": "msg: GMBCID.sports_complex {Sports complex}",
    "sports_massage_therapist": "msg: GMBCID.sports_massage_therapist {Sports Massage Therapist}",
    "sports_medicine_clinic": "msg: GMBCID.sports_medicine_clinic {Sports Medicine Clinic}",
    "sports_medicine_physician": "msg: GMBCID.sports_medicine_physician {Sports Medicine Physician}",
    "sports_memorabilia_store": "msg: GMBCID.sports_memorabilia_store {Sports Memorabilia Shop}",
    "sports_nutrition_store": "msg: GMBCID.sports_nutrition_store {Sports Nutrition Shop}",
    "sports_school": "msg: GMBCID.sports_school {Sports school}",
    "sportswear_store": "msg: GMBCID.sportswear_store {Sportswear Shop}",
    "sportwear_manufacturer": "msg: GMBCID.sportwear_manufacturer {Sportwear Manufacturer}",
    "spring_supplier": "msg: GMBCID.spring_supplier {Spring Supplier}",
    "squash_club": "msg: GMBCID.squash_club {Squash club}",
    "sri_lankan_restaurant": "msg: GMBCID.sri_lankan_restaurant {Sri Lankan restaurant}",
    "stable": "msg: GMBCID.stable {Stable}",
    "stage_lighting_equipment_supplier": "msg: GMBCID.stage_lighting_equipment_supplier {Stage Lighting Equipment Supplier}",
    "stainless_steel_plant": "msg: GMBCID.stainless_steel_plant {Stainless Steel Plant}",
    "stair_contractor": "msg: GMBCID.stair_contractor {Stair contractor}",
    "stall_installation_service": "msg: GMBCID.stall_installation_service {Stall installation service}",
    "stamp_collectors_club": "msg: GMBCID.stamp_collectors_club {Stamp Collectors Club}",
    "stamp_shop": "msg: GMBCID.stamp_shop {Stamp shop}",
    "stand_bar": "msg: GMBCID.stand_bar {Stand bar}",
    "standup_show": "msg: GMBCID.standup_show {Standup Show}",
    "star_gazing_hobbyist": "msg: GMBCID.star_gazing_hobbyist {Star Gazing Hobbyist}",
    "startup_project": "msg: GMBCID.startup_project {Startup Project}",
    "state_dept_of_sports": "msg: GMBCID.state_dept_of_sports {State Dept of Sports}",
    "state_liquor_store": "msg: GMBCID.state_liquor_store {State Liquor Shop}",
    "state_park": "msg: GMBCID.state_park {State Park}",
    "stationery_manufacturer": "msg: GMBCID.stationery_manufacturer {Stationery Manufacturer}",
    "stationery_store": "msg: GMBCID.stationery_store {Stationery Shop}",
    "stationery_wholesaler": "msg: GMBCID.stationery_wholesaler {Stationery Wholesaler}",
    "std_clinic": "msg: GMBCID.std_clinic {STD Clinic}",
    "std_testing_service": "msg: GMBCID.std_testing_service {STD Testing Service}",
    "steamboat_restaurant": "msg: GMBCID.steamboat_restaurant {Steamboat restaurant}",
    "steamed_bun_shop": "msg: GMBCID.steamed_bun_shop {Steamed bun shop}",
    "steel_distributor": "msg: GMBCID.steel_distributor {Steel Distributor}",
    "steel_drum_supplier": "msg: GMBCID.steel_drum_supplier {Steel Drum Supplier}",
    "steel_erector": "msg: GMBCID.steel_erector {Steel erector}",
    "steel_fabricator": "msg: GMBCID.steel_fabricator {Steel Fabricator}",
    "steel_framework_contractor": "msg: GMBCID.steel_framework_contractor {Steel framework contractor}",
    "steelwork_manufacturer": "msg: GMBCID.steelwork_manufacturer {Steelwork Manufacturer}",
    "stereo_rental_store": "msg: GMBCID.stereo_rental_store {Stereo Rental Shop}",
    "stereo_repair_service": "msg: GMBCID.stereo_repair_service {Stereo repair service}",
    "stereo_store": "msg: GMBCID.stereo_store {Stereo Shop}",
    "sticker_manufacturer": "msg: GMBCID.sticker_manufacturer {Sticker Manufacturer}",
    "stitching_class": "msg: GMBCID.stitching_class {Stitching Class}",
    "stock_broker": "msg: GMBCID.stock_broker {Stock broker}",
    "stone_carving": "msg: GMBCID.stone_carving {Stone carving}",
    "stone_cutter": "msg: GMBCID.stone_cutter {Stone cutter}",
    "stone_supplier": "msg: GMBCID.stone_supplier {Stone Supplier}",
    "store": "msg: GMBCID.store {Store}",
    "store_equipment_supplier": "msg: GMBCID.store_equipment_supplier {Shop Equipment Supplier}",
    "stove_builder": "msg: GMBCID.stove_builder {Stove builder}",
    "street_style_photography_portfolio": "msg: GMBCID.street_style_photography_portfolio {Street Style Photography Portfolio}",
    "stringed_intrument_maker": "msg: GMBCID.stringed_intrument_maker {Luthier}",
    "stucco_contractor": "msg: GMBCID.stucco_contractor {Stucco contractor}",
    "student_housing_center": "msg: GMBCID.student_housing_center {Student Housing Centre}",
    "students_parents_association": "msg: GMBCID.students_parents_association {Students parents association}",
    "students_support_association": "msg: GMBCID.students_support_association {Students support association}",
    "study_at_home_school": "msg: GMBCID.study_at_home_school {Study at Home School}",
    "stylist": "msg: GMBCID.stylist {Stylist}",
    "sugar_factory": "msg: GMBCID.sugar_factory {Sugar Factory}",
    "sukiyaki_restaurant": "msg: GMBCID.sukiyaki_restaurant {Sukiyaki restaurant}",
    "summer_camp": "msg: GMBCID.summer_camp {Summer camp}",
    "sundae_restaurant": "msg: GMBCID.sundae_restaurant {Sundae restaurant}",
    "sundanese_restaurant": "msg: GMBCID.sundanese_restaurant {Sundanese restaurant}",
    "sunglasses_store": "msg: GMBCID.sunglasses_store {Sunglasses Shop}",
    "sunroom_contractor": "msg: GMBCID.sunroom_contractor {Sunroom Contractor}",
    "supermarket": "msg: GMBCID.supermarket {Supermarket}",
    "surf_lifesaving_club": "msg: GMBCID.surf_lifesaving_club {Surf lifesaving club}",
    "surf_school": "msg: GMBCID.surf_school {Surf school}",
    "surf_shop": "msg: GMBCID.surf_shop {Surf shop}",
    "surgeon": "msg: GMBCID.surgeon {Surgeon}",
    "surgical_center": "msg: GMBCID.surgical_center {Surgical Center}",
    "surgical_oncologist": "msg: GMBCID.surgical_oncologist {Surgical Oncologist}",
    "surgical_products_wholesaler": "msg: GMBCID.surgical_products_wholesaler {Surgical Products Wholesaler}",
    "surinamese_restaurant": "msg: GMBCID.surinamese_restaurant {Surinamese restaurant}",
    "surplus_store": "msg: GMBCID.surplus_store {Outlet}",
    "sushi_restaurant": "msg: GMBCID.sushi_restaurant {Sushi restaurant}",
    "sushi_takeaway": "msg: GMBCID.sushi_takeaway {Sushi takeaway}",
    "swabian_restaurant": "msg: GMBCID.swabian_restaurant {Swabian restaurant}",
    "swedish_restaurant": "msg: GMBCID.swedish_restaurant {Swedish restaurant}",
    "swim_club": "msg: GMBCID.swim_club {Swimming Club}",
    "swimming_instructor": "msg: GMBCID.swimming_instructor {Swimming Instructor}",
    "swimming_lake": "msg: GMBCID.swimming_lake {Swimming Lake}",
    "swimming_pool": "msg: GMBCID.swimming_pool {Swimming pool}",
    "swimming_pool_contractor": "msg: GMBCID.swimming_pool_contractor {Swimming pool contractor}",
    "swimming_pool_repair_service": "msg: GMBCID.swimming_pool_repair_service {Swimming pool repair service}",
    "swimming_school": "msg: GMBCID.swimming_school {Swimming school}",
    "swimwear_store": "msg: GMBCID.swimwear_store {Swimwear Shop}",
    "swiss_restaurant": "msg: GMBCID.swiss_restaurant {Swiss restaurant}",
    "synagogue": "msg: GMBCID.synagogue {Synagogue}",
    "synthesist": "msg: GMBCID.synthesist {Synthesist}",
    "syrian_restaurant": "msg: GMBCID.syrian_restaurant {Syrian restaurant}",
    "t_shirt_store": "msg: GMBCID.t_shirt_store {T-shirt Shop}",
    "tabascan_restaurant": "msg: GMBCID.tabascan_restaurant {Tabascan restaurant}",
    "tabla_player": "msg: GMBCID.tabla_player {Tabla Player}",
    "table_tennis_club": "msg: GMBCID.table_tennis_club {Table tennis club}",
    "table_tennis_facility": "msg: GMBCID.table_tennis_facility {Table tennis facility}",
    "tacaca_restaurant": "msg: GMBCID.tacaca_restaurant {Tacaca restaurant}",
    "tack_shop": "msg: GMBCID.tack_shop {Tack shop}",
    "taco_restaurant": "msg: GMBCID.taco_restaurant {Taco restaurant}",
    "taekwondo_school": "msg: GMBCID.taekwondo_school {Tae kwon do school}",
    "tai_chi_school": "msg: GMBCID.tai_chi_school {Tai chi school}",
    "tailor": "msg: GMBCID.tailor {Tailor}",
    "taiwanese_restaurant": "msg: GMBCID.taiwanese_restaurant {Taiwanese restaurant}",
    "takoyaki_stand": "msg: GMBCID.takoyaki_stand {Takoyaki restaurant}",
    "talk_show": "msg: GMBCID.talk_show {Talk Show}",
    "tamale_shop": "msg: GMBCID.tamale_shop {Tamale shop}",
    "tambourine_player": "msg: GMBCID.tambourine_player {Tambourine Player}",
    "tango_musician": "msg: GMBCID.tango_musician {Tango Musician}",
    "tanning_studio": "msg: GMBCID.tanning_studio {Tanning salon}",
    "taoist_temple": "msg: GMBCID.taoist_temple {Taoist temple}",
    "tapas_restaurant": "msg: GMBCID.tapas_restaurant {Tapas restaurant}",
    "tatami_store": "msg: GMBCID.tatami_store {Tatami Shop}",
    "tatoo_artist_portfolio": "msg: GMBCID.tatoo_artist_portfolio {Tattoo Artist Portfolio}",
    "tattoo_and_piercing_shop": "msg: GMBCID.tattoo_and_piercing_shop {Tattoo and piercing shop}",
    "tattoo_artist": "msg: GMBCID.tattoo_artist {Tattoo artist}",
    "tattoo_removal_service": "msg: GMBCID.tattoo_removal_service {Tattoo-Removal Service}",
    "tax_assessor": "msg: GMBCID.tax_assessor {Tax assessor}",
    "tax_attorney": "msg: GMBCID.tax_attorney {Tax Attorney}",
    "tax_collectors_office": "msg: GMBCID.tax_collectors_office {Tax Collectors Office}",
    "tax_consultant": "msg: GMBCID.tax_consultant {Tax Consultant}",
    "tax_preparation": "msg: GMBCID.tax_preparation {Tax preparation}",
    "tax_preparation_service": "msg: GMBCID.tax_preparation_service {Tax preparation service}",
    "taxi_service": "msg: GMBCID.taxi_service {Taxi service}",
    "tb_clinic": "msg: GMBCID.tb_clinic {TB Clinic}",
    "tea_exporter": "msg: GMBCID.tea_exporter {Tea Exporter}",
    "tea_manufacturer": "msg: GMBCID.tea_manufacturer {Tea Manufacturer}",
    "tea_market_place": "msg: GMBCID.tea_market_place {Tea market place}",
    "tea_store": "msg: GMBCID.tea_store {Tea Shop}",
    "tea_wholesaler": "msg: GMBCID.tea_wholesaler {Tea Wholesaler}",
    "teacher_college": "msg: GMBCID.teacher_college {Teacher College}",
    "tech_coding_enthusiast": "msg: GMBCID.tech_coding_enthusiast {Tech Coding Enthusiast}",
    "technical_school": "msg: GMBCID.technical_school {Technical School}",
    "technical_service": "msg: GMBCID.technical_service {Technical service}",
    "technology_conference": "msg: GMBCID.technology_conference {Technology Conference}",
    "technology_museum": "msg: GMBCID.technology_museum {Technology museum}",
    "tegal_restaurant": "msg: GMBCID.tegal_restaurant {Tegal restaurant}",
    "telecommunication_school": "msg: GMBCID.telecommunication_school {Telecommunication School}",
    "telecommunications_equipment_supplier": "msg: GMBCID.telecommunications_equipment_supplier {Telecommunications Equipment Supplier}",
    "telescope_store": "msg: GMBCID.telescope_store {Telescope Shop}",
    "temaki_restaurant": "msg: GMBCID.temaki_restaurant {Temaki restaurant}",
    "tempura_bowl_restaurants": "msg: GMBCID.tempura_bowl_restaurants {Tempura donburi restaurant}",
    "tempura_dish_restaurant": "msg: GMBCID.tempura_dish_restaurant {Tempura restaurant}",
    "tennis_club": "msg: GMBCID.tennis_club {Tennis club}",
    "tennis_court": "msg: GMBCID.tennis_court {Tennis court}",
    "tennis_instructor": "msg: GMBCID.tennis_instructor {Tennis Instructor}",
    "tennis_store": "msg: GMBCID.tennis_store {Tennis Shop}",
    "tenor": "msg: GMBCID.tenor {Tenor}",
    "teppan_grill_restaurant": "msg: GMBCID.teppan_grill_restaurant {Teppanyaki restaurant}",
    "tex_mex_restaurant": "msg: GMBCID.tex_mex_restaurant {Tex-Mex restaurant}",
    "textile_exporter": "msg: GMBCID.textile_exporter {Textile Exporter}",
    "thai_massage_therapist": "msg: GMBCID.thai_massage_therapist {Thai massage therapist}",
    "thai_restaurant": "msg: GMBCID.thai_restaurant {Thai restaurant}",
    "theater_supply_store": "msg: GMBCID.theater_supply_store {Theatrical Supplies Shop}",
    "theme_park": "msg: GMBCID.theme_park {Theme park}",
    "thread_supplier": "msg: GMBCID.thread_supplier {Thread Supplier}",
    "threads_and_yarns_wholesaler": "msg: GMBCID.threads_and_yarns_wholesaler {Threads and Yarns Wholesaler}",
    "three_d_printing_service": "msg: GMBCID.three_d_printing_service {3D printing service}",
    "thrift_store": "msg: GMBCID.thrift_store {Thrift Shop}",
    "thuringian_restaurant": "msg: GMBCID.thuringian_restaurant {Thuringian restaurant}",
    "tibetan_restaurant": "msg: GMBCID.tibetan_restaurant {Tibetan restaurant}",
    "tiffin_center": "msg: GMBCID.tiffin_center {Tiffin center}",
    "tiki_bar": "msg: GMBCID.tiki_bar {Tiki bar}",
    "tile_contractor": "msg: GMBCID.tile_contractor {Tile contractor}",
    "tile_store": "msg: GMBCID.tile_store {Tile Shop}",
    "tire_manufacturer": "msg: GMBCID.tire_manufacturer {Tire Manufacturer}",
    "tire_shop": "msg: GMBCID.tire_shop {Tyre Shop}",
    "toast_restaurant": "msg: GMBCID.toast_restaurant {Toast restaurant}",
    "tobacco_exporter": "msg: GMBCID.tobacco_exporter {Tobacco Exporter}",
    "tobacco_shop": "msg: GMBCID.tobacco_shop {Tobacco shop}",
    "tobacco_supplier": "msg: GMBCID.tobacco_supplier {Tobacco Supplier}",
    "tofu_restaurant": "msg: GMBCID.tofu_restaurant {Tofu restaurant}",
    "toiletries_store": "msg: GMBCID.toiletries_store {Toiletries Shop}",
    "toner_cartridge_supplier": "msg: GMBCID.toner_cartridge_supplier {Toner Cartridge Supplier}",
    "tongue_restaurant": "msg: GMBCID.tongue_restaurant {Tongue restaurant}",
    "tonkatsu_restaurant": "msg: GMBCID.tonkatsu_restaurant {Tonkatsu restaurant}",
    "tool_grinding_service": "msg: GMBCID.tool_grinding_service {Tool grinding service}",
    "tool_manufacturer": "msg: GMBCID.tool_manufacturer {Tool Manufacturer}",
    "tool_store": "msg: GMBCID.tool_store {Tool Shop}",
    "tool_wholesaler": "msg: GMBCID.tool_wholesaler {Tool Wholesaler}",
    "topsoil_supplier": "msg: GMBCID.topsoil_supplier {Topsoil Supplier}",
    "tortilla_shop": "msg: GMBCID.tortilla_shop {Tortilla shop}",
    "tour_agency": "msg: GMBCID.tour_agency {Tour Agency}",
    "tour_operator": "msg: GMBCID.tour_operator {Tour Operator}",
    "tourist_attraction": "msg: GMBCID.tourist_attraction {Tourist attraction}",
    "towing_equipment_provider": "msg: GMBCID.towing_equipment_provider {Towing Equipment Provider}",
    "towing_service": "msg: GMBCID.towing_service {Towing service}",
    "toy_and_game_manufacturer": "msg: GMBCID.toy_and_game_manufacturer {Toy and Game Manufacturer}",
    "toy_library": "msg: GMBCID.toy_library {Toy Library}",
    "toy_manufacturer": "msg: GMBCID.toy_manufacturer {Toy Manufacturer}",
    "toy_museum": "msg: GMBCID.toy_museum {Toy museum}",
    "toy_store": "msg: GMBCID.toy_store {Toy Shop}",
    "tractor_dealer": "msg: GMBCID.tractor_dealer {Tractor Dealer}",
    "trade_school": "msg: GMBCID.trade_school {Trade School}",
    "trademark_attorney": "msg: GMBCID.trademark_attorney {Trademark Attorney}",
    "trading_card_store": "msg: GMBCID.trading_card_store {Trading Card Shop}",
    "traditional_african_musician": "msg: GMBCID.traditional_african_musician {Traditional African Musician}",
    "traditional_costume_club": "msg: GMBCID.traditional_costume_club {Traditional costume club}",
    "traditional_kostume_store": "msg: GMBCID.traditional_kostume_store {Traditional Kostume Shop}",
    "traditional_musician": "msg: GMBCID.traditional_musician {Traditional Musician}",
    "traditional_restaurant": "msg: GMBCID.traditional_restaurant {Traditional restaurant}",
    "traditional_us_american_restaurant": "msg: GMBCID.traditional_us_american_restaurant {Traditional American restaurant}",
    "trailer_dealer": "msg: GMBCID.trailer_dealer {Trailer Dealer}",
    "trailer_manufacturer": "msg: GMBCID.trailer_manufacturer {Trailer Manufacturer}",
    "training_center": "msg: GMBCID.training_center {Training centre}",
    "training_school": "msg: GMBCID.training_school {Training School}",
    "transplant_surgeon": "msg: GMBCID.transplant_surgeon {Transplant Surgeon}",
    "transportation_escort_service": "msg: GMBCID.transportation_escort_service {Transportation escort service}",
    "transportation_service": "msg: GMBCID.transportation_service {Transportation service}",
    "travel_agency": "msg: GMBCID.travel_agency {Travel Agency}",
    "travel_clinic": "msg: GMBCID.travel_clinic {Travel Clinic}",
    "travel_enthusiast": "msg: GMBCID.travel_enthusiast {Travel Enthusiast}",
    "travel_photography_portfolio": "msg: GMBCID.travel_photography_portfolio {Travel Photography Portfolio}",
    "tree_service": "msg: GMBCID.tree_service {Tree service}",
    "trial_attorney": "msg: GMBCID.trial_attorney {Trial Attorney}",
    "trip": "msg: GMBCID.trip {Trip}",
    "trombonist": "msg: GMBCID.trombonist {Trombonist}",
    "trophy_shop": "msg: GMBCID.trophy_shop {Trophy shop}",
    "tropical_fish_store": "msg: GMBCID.tropical_fish_store {Tropical Fish Shop}",
    "truck_accessories_store": "msg: GMBCID.truck_accessories_store {Van Accessories Shop}",
    "truck_parts_supplier": "msg: GMBCID.truck_parts_supplier {Truck Parts Supplier}",
    "truck_topper_supplier": "msg: GMBCID.truck_topper_supplier {Truck Topper Supplier}",
    "trucking_school": "msg: GMBCID.trucking_school {Trucking School}",
    "trumpeter": "msg: GMBCID.trumpeter {Trumpeter}",
    "truss_manufacturer": "msg: GMBCID.truss_manufacturer {Truss Manufacturer}",
    "tuba_player": "msg: GMBCID.tuba_player {Tuba Player}",
    "tune_up_supplier": "msg: GMBCID.tune_up_supplier {Tune Up Supplier}",
    "tunisian_restaurant": "msg: GMBCID.tunisian_restaurant {Tunisian restaurant}",
    "turf_supplier": "msg: GMBCID.turf_supplier {Turf Supplier}",
    "turkish_restaurant": "msg: GMBCID.turkish_restaurant {Turkish restaurant}",
    "turkmen_restaurant": "msg: GMBCID.turkmen_restaurant {Turkmen restaurant}",
    "tuscan_restaurant": "msg: GMBCID.tuscan_restaurant {Tuscan restaurant}",
    "tutoring_service": "msg: GMBCID.tutoring_service {Tutoring Service}",
    "tuxedo_shop": "msg: GMBCID.tuxedo_shop {Formal menswear shop}",
    "typewriter_repair_service": "msg: GMBCID.typewriter_repair_service {Typewriter repair service}",
    "typewriter_supplier": "msg: GMBCID.typewriter_supplier {Typewriter Supplier}",
    "typing_service": "msg: GMBCID.typing_service {Typing Service}",
    "typography_portfolio": "msg: GMBCID.typography_portfolio {Typography Portfolio}",
    "udon_noodle_shop": "msg: GMBCID.udon_noodle_shop {Udon noodle restaurant}",
    "ukrainian_restaurant": "msg: GMBCID.ukrainian_restaurant {Ukrainian restaurant}",
    "ukulele_player": "msg: GMBCID.ukulele_player {Ukulele Player}",
    "unagi_restaurant": "msg: GMBCID.unagi_restaurant {Unagi restaurant}",
    "underwear_store": "msg: GMBCID.underwear_store {Underwear Shop}",
    "unfinished_furniture_store": "msg: GMBCID.unfinished_furniture_store {Unfinished Furniture Shop}",
    "uniform_store": "msg: GMBCID.uniform_store {Uniform Shop}",
    "unisex_hairdresser": "msg: GMBCID.unisex_hairdresser {Unisex Hairdresser}",
    "unitarian_universalist_church": "msg: GMBCID.unitarian_universalist_church {Unitarian Universalist Church}",
    "united_church_of_canada": "msg: GMBCID.united_church_of_canada {United Church of Canada}",
    "united_church_of_christ": "msg: GMBCID.united_church_of_christ {United Church of Christ}",
    "united_methodist_church": "msg: GMBCID.united_methodist_church {United Methodist church}",
    "unity_church": "msg: GMBCID.unity_church {Unity church}",
    "university_library": "msg: GMBCID.university_library {University Library}",
    "upholstery_cleaning_service": "msg: GMBCID.upholstery_cleaning_service {Upholstery cleaning service}",
    "upholstery_shop": "msg: GMBCID.upholstery_shop {Upholstery shop}",
    "urgent_care_center": "msg: GMBCID.urgent_care_center {Urgent Care Center}",
    "urologist": "msg: GMBCID.urologist {Urologist}",
    "urology_clinic": "msg: GMBCID.urology_clinic {Urology Clinic}",
    "uruguayan_restaurant": "msg: GMBCID.uruguayan_restaurant {Uruguayan restaurant}",
    "us_pacific_northwest_restaurant": "msg: GMBCID.us_pacific_northwest_restaurant {Pacific Northwest restaurant (US)}",
    "used_appliance_store": "msg: GMBCID.used_appliance_store {Used Appliance Shop}",
    "used_auto_parts_store": "msg: GMBCID.used_auto_parts_store {Used Vehicle Parts Shop}",
    "used_bicycle_shop": "msg: GMBCID.used_bicycle_shop {Used Bicycle Shop}",
    "used_book_store": "msg: GMBCID.used_book_store {Second-hand Book Shop}",
    "used_car_dealer": "msg: GMBCID.used_car_dealer {Used Car Dealer}",
    "used_cd_store": "msg: GMBCID.used_cd_store {Used CD Shop}",
    "used_clothing_store": "msg: GMBCID.used_clothing_store {Second-hand Clothing Shop}",
    "used_computer_store": "msg: GMBCID.used_computer_store {Used Computer Shop}",
    "used_furniture_store": "msg: GMBCID.used_furniture_store {Second-hand furniture shop}",
    "used_game_store": "msg: GMBCID.used_game_store {Used Game Shop}",
    "used_motorcycle_dealer": "msg: GMBCID.used_motorcycle_dealer {Used Motorcycle Dealer}",
    "used_musical_instrument_store": "msg: GMBCID.used_musical_instrument_store {Used Musical Instrument Shop}",
    "used_office_furniture_store": "msg: GMBCID.used_office_furniture_store {Used Office Furniture Shop}",
    "used_store_fixture_supplier": "msg: GMBCID.used_store_fixture_supplier {Used Store Fixture Supplier}",
    "used_tire_shop": "msg: GMBCID.used_tire_shop {Used Tyre Shop}",
    "ux_designer_portfolio": "msg: GMBCID.ux_designer_portfolio {UX Designer Portfolio}",
    "uyghur_cuisine_restaurant": "msg: GMBCID.uyghur_cuisine_restaurant {Uyghur cuisine restaurant}",
    "uzbek_restaurant": "msg: GMBCID.uzbek_restaurant {Uzbeki restaurant}",
    "vacation_apartment": "msg: GMBCID.vacation_apartment {Vacation Apartment}",
    "vacation_home_rental_agency": "msg: GMBCID.vacation_home_rental_agency {Vacation Home Rental Agency}",
    "vacuum_cleaner_store": "msg: GMBCID.vacuum_cleaner_store {Vacuum Cleaner Shop}",
    "vacuum_cleaning_system_supplier": "msg: GMBCID.vacuum_cleaning_system_supplier {Vacuum Cleaning System Supplier}",
    "valencian_restaurant": "msg: GMBCID.valencian_restaurant {Valencian restaurant}",
    "valet_parking_service": "msg: GMBCID.valet_parking_service {Valet parking service}",
    "vaporizer_store": "msg: GMBCID.vaporizer_store {Vaporiser Shop}",
    "variety_store": "msg: GMBCID.variety_store {Variety Shop}",
    "vascular_surgeon": "msg: GMBCID.vascular_surgeon {Vascular Surgeon}",
    "vcr_repair_service": "msg: GMBCID.vcr_repair_service {Video Player Repair Service}",
    "vegan_restaurant": "msg: GMBCID.vegan_restaurant {Vegan restaurant}",
    "vegetarian_cafe_and_deli": "msg: GMBCID.vegetarian_cafe_and_deli {Vegetarian cafe and deli}",
    "vegetarian_restaurant": "msg: GMBCID.vegetarian_restaurant {Vegetarian restaurant}",
    "vehicle_wrapping_service": "msg: GMBCID.vehicle_wrapping_service {Vehicle wrapping service}",
    "venetian_restaurant": "msg: GMBCID.venetian_restaurant {Venetian restaurant}",
    "venezuelan_restaurant": "msg: GMBCID.venezuelan_restaurant {Venezuelan restaurant}",
    "veterans_hospital": "msg: GMBCID.veterans_hospital {Veterans Hospital}",
    "veterans_organization": "msg: GMBCID.veterans_organization {Veterans' Organisation}",
    "veterinarian": "msg: GMBCID.veterinarian {Veterinarian}",
    "video_camera_repair_service": "msg: GMBCID.video_camera_repair_service {Video camera repair service}",
    "video_conferencing_equipment_supplier": "msg: GMBCID.video_conferencing_equipment_supplier {Video Conferencing Equipment Supplier}",
    "video_duplication_service": "msg: GMBCID.video_duplication_service {Video Duplication Service}",
    "video_editing_service": "msg: GMBCID.video_editing_service {Video editing service}",
    "video_editor_portfolio": "msg: GMBCID.video_editor_portfolio {Video Editor Portfolio}",
    "video_equipment_repair_service": "msg: GMBCID.video_equipment_repair_service {Video equipment repair service}",
    "video_game_store": "msg: GMBCID.video_game_store {Video Game Shop}",
    "video_production_service": "msg: GMBCID.video_production_service {Video production service}",
    "video_store": "msg: GMBCID.video_store {Video Shop}",
    "vietnamese_restaurant": "msg: GMBCID.vietnamese_restaurant {Vietnamese restaurant}",
    "vineyard_church": "msg: GMBCID.vineyard_church {Vineyard church}",
    "vintage_clothing_store": "msg: GMBCID.vintage_clothing_store {Vintage Clothing Shop}",
    "vinyl_sign_shop": "msg: GMBCID.vinyl_sign_shop {Vinyl sign shop}",
    "violin_shop": "msg: GMBCID.violin_shop {Violin shop}",
    "violinist": "msg: GMBCID.violinist {Violinist}",
    "virtual_conference": "msg: GMBCID.virtual_conference {Virtual Conference}",
    "virtual_hackathon": "msg: GMBCID.virtual_hackathon {Virtual Hackathon}",
    "virtual_job_fair": "msg: GMBCID.virtual_job_fair {Virtual Job Fair}",
    "visa_and_passport_office": "msg: GMBCID.visa_and_passport_office {Visa and passport office}",
    "visual_development_artist_portfolio": "msg: GMBCID.visual_development_artist_portfolio {Visual Development Artist Portfolio}",
    "vitae": "msg: GMBCID.vitae {Vitae}",
    "vitamin_and_supplements_store": "msg: GMBCID.vitamin_and_supplements_store {Vitamin & Supplements Shop}",
    "vocal_instructor": "msg: GMBCID.vocal_instructor {Vocal Instructor}",
    "vocalist": "msg: GMBCID.vocalist {Vocalist}",
    "vocational_college": "msg: GMBCID.vocational_college {Vocational College}",
    "vocational_gymnasium_school": "msg: GMBCID.vocational_gymnasium_school {Vocational Gymnasium School}",
    "vocational_secondary_school": "msg: GMBCID.vocational_secondary_school {Vocational Secondary School}",
    "vocational_training_school": "msg: GMBCID.vocational_training_school {Vocational Training School}",
    "volkswagen_dealer": "msg: GMBCID.volkswagen_dealer {Volkswagen Dealer}",
    "volleyball_club": "msg: GMBCID.volleyball_club {Volleyball club}",
    "volleyball_court": "msg: GMBCID.volleyball_court {Volleyball court}",
    "volleyball_instructor": "msg: GMBCID.volleyball_instructor {Volleyball Instructor}",
    "volunteer_organization": "msg: GMBCID.volunteer_organization {Voluntary organisation}",
    "volvo_dealer": "msg: GMBCID.volvo_dealer {Volvo Dealer}",
    "waldorf_school": "msg: GMBCID.waldorf_school {Waldorf School}",
    "walk_in_clinic": "msg: GMBCID.walk_in_clinic {Walk-in Clinic}",
    "wallpaper_installer": "msg: GMBCID.wallpaper_installer {Wallpaper installer}",
    "wallpaper_store": "msg: GMBCID.wallpaper_store {Wallpaper Shop}",
    "war_museum": "msg: GMBCID.war_museum {War museum}",
    "warehouse": "msg: GMBCID.warehouse {Warehouse}",
    "warehouse_store": "msg: GMBCID.warehouse_store {Warehouse Shop}",
    "washer_and_dryer_repair_service": "msg: GMBCID.washer_and_dryer_repair_service {Washer and Dryer Repair Service}",
    "washer_and_dryer_store": "msg: GMBCID.washer_and_dryer_store {Washing Machine & Dryer Shop}",
    "waste_management_service": "msg: GMBCID.waste_management_service {Waste-Management Service}",
    "watch_manufacturer": "msg: GMBCID.watch_manufacturer {Watch Manufacturer}",
    "watch_repair_service": "msg: GMBCID.watch_repair_service {Watch Repair Service}",
    "watch_store": "msg: GMBCID.watch_store {Watch shop}",
    "water_cooler_supplier": "msg: GMBCID.water_cooler_supplier {Water Cooler Supplier}",
    "water_damage_restoration_service": "msg: GMBCID.water_damage_restoration_service {Water damage restoration service}",
    "water_filter_supplier": "msg: GMBCID.water_filter_supplier {Water Filter Supplier}",
    "water_jet_cutting_service": "msg: GMBCID.water_jet_cutting_service {Water jet cutting service}",
    "water_park": "msg: GMBCID.water_park {Water park}",
    "water_pump_supplier": "msg: GMBCID.water_pump_supplier {Water Pump Supplier}",
    "water_ski_shop": "msg: GMBCID.water_ski_shop {Water ski shop}",
    "water_skiing_club": "msg: GMBCID.water_skiing_club {Water skiing club}",
    "water_skiing_instructor": "msg: GMBCID.water_skiing_instructor {Water Skiing Instructor}",
    "water_softening_equipment_supplier": "msg: GMBCID.water_softening_equipment_supplier {Water Softening Equipment Supplier}",
    "water_tank_cleaning_service": "msg: GMBCID.water_tank_cleaning_service {Water Tank Cleaners}",
    "water_testing_service": "msg: GMBCID.water_testing_service {Water Testing Laboratory}",
    "water_treatment_supplier": "msg: GMBCID.water_treatment_supplier {Water Treatment Supplier}",
    "water_works_equipment_supplier": "msg: GMBCID.water_works_equipment_supplier {Water Works Equipment Supplier}",
    "waterbed_repair_service": "msg: GMBCID.waterbed_repair_service {Waterbed repair service}",
    "waterbed_store": "msg: GMBCID.waterbed_store {Waterbed Shop}",
    "wax_museum": "msg: GMBCID.wax_museum {Wax museum}",
    "wax_supplier": "msg: GMBCID.wax_supplier {Wax Supplier}",
    "waxing_hair_removal_service": "msg: GMBCID.waxing_hair_removal_service {Waxing Hair-Removal Service}",
    "web_developer_portfolio": "msg: GMBCID.web_developer_portfolio {Web Developer Portfolio}",
    "web_hosting_service": "msg: GMBCID.web_hosting_service {Web Hosting Service}",
    "web_shop": "msg: GMBCID.web_shop {Web Shop}",
    "webdesign_portfolio": "msg: GMBCID.webdesign_portfolio {Webdesign Portfolio}",
    "webinar": "msg: GMBCID.webinar {Webinar}",
    "website_designer": "msg: GMBCID.website_designer {Web Designer}",
    "wedding": "msg: GMBCID.wedding {Wedding}",
    "wedding_photographer": "msg: GMBCID.wedding_photographer {Wedding photographer}",
    "wedding_photography_portfolio": "msg: GMBCID.wedding_photography_portfolio {Wedding Photography Portfolio}",
    "wedding_planner": "msg: GMBCID.wedding_planner {Wedding planner}",
    "wedding_souvenir_shop": "msg: GMBCID.wedding_souvenir_shop {Wedding souvenir shop}",
    "wedding_store": "msg: GMBCID.wedding_store {Wedding Shop}",
    "weight_loss_service": "msg: GMBCID.weight_loss_service {Weight-Loss Service}",
    "welding_gas_supplier": "msg: GMBCID.welding_gas_supplier {Welding Gas Supplier}",
    "well_drilling_contractor": "msg: GMBCID.well_drilling_contractor {Well drilling contractor}",
    "wellness_center": "msg: GMBCID.wellness_center {Wellness Centre}",
    "wellness_program": "msg: GMBCID.wellness_program {Wellness Programme}",
    "welsh_restaurant": "msg: GMBCID.welsh_restaurant {Welsh restaurant}",
    "wesleyan_church": "msg: GMBCID.wesleyan_church {Wesleyan church}",
    "west_african_restaurant": "msg: GMBCID.west_african_restaurant {West African restaurant}",
    "western_apparel_store": "msg: GMBCID.western_apparel_store {Western Clothing Shop}",
    "western_restaurant": "msg: GMBCID.western_restaurant {Western restaurant}",
    "whale_watching_tour_agency": "msg: GMBCID.whale_watching_tour_agency {Whale Watching Tour Agency}",
    "wheel_alignment_service": "msg: GMBCID.wheel_alignment_service {Wheel alignment service}",
    "wheel_store": "msg: GMBCID.wheel_store {Wheel Shop}",
    "wheelchair_repair_service": "msg: GMBCID.wheelchair_repair_service {Wheelchair repair service}",
    "wheelchair_store": "msg: GMBCID.wheelchair_store {Wheelchair Shop}",
    "wholesale_bakery": "msg: GMBCID.wholesale_bakery {Wholesale Bakery}",
    "wholesale_drugstore": "msg: GMBCID.wholesale_drugstore {Wholesale Drugstore}",
    "wholesale_florist": "msg: GMBCID.wholesale_florist {Wholesale Florist}",
    "wholesale_food_store": "msg: GMBCID.wholesale_food_store {Wholesale Food Store}",
    "wholesale_grocer": "msg: GMBCID.wholesale_grocer {Wholesale Grocer}",
    "wholesale_jeweler": "msg: GMBCID.wholesale_jeweler {Wholesale Jeweler}",
    "wholesale_market": "msg: GMBCID.wholesale_market {Wholesale Market}",
    "wholesale_plant_nursery": "msg: GMBCID.wholesale_plant_nursery {Wholesale Plant Nursery}",
    "wholesaler": "msg: GMBCID.wholesaler {Wholesaler}",
    "wholesaler_household_appliances": "msg: GMBCID.wholesaler_household_appliances {Wholesaler Household Appliances}",
    "wicker_store": "msg: GMBCID.wicker_store {Wicker Shop}",
    "wig_shop": "msg: GMBCID.wig_shop {Wig shop}",
    "wildlife_and_safari_park": "msg: GMBCID.wildlife_and_safari_park {Wildlife and safari park}",
    "wildlife_park": "msg: GMBCID.wildlife_park {Wildlife park}",
    "wildlife_refuge": "msg: GMBCID.wildlife_refuge {Wildlife Refuge}",
    "wildlife_rescue_service": "msg: GMBCID.wildlife_rescue_service {Wildlife rescue service}",
    "wind_turbine_builder": "msg: GMBCID.wind_turbine_builder {Wind turbine builder}",
    "window_cleaning_service": "msg: GMBCID.window_cleaning_service {Window cleaning service}",
    "window_installation_service": "msg: GMBCID.window_installation_service {Window installation service}",
    "window_supplier": "msg: GMBCID.window_supplier {Window Supplier}",
    "window_tinting_service": "msg: GMBCID.window_tinting_service {Window tinting service}",
    "window_treatment_store": "msg: GMBCID.window_treatment_store {Curtain shop}",
    "windsurfing_store": "msg: GMBCID.windsurfing_store {Windsurfing Shop}",
    "wine_bar": "msg: GMBCID.wine_bar {Wine bar}",
    "wine_club": "msg: GMBCID.wine_club {Wine Club}",
    "wine_store": "msg: GMBCID.wine_store {Wine shop}",
    "wine_wholesaler": "msg: GMBCID.wine_wholesaler {Wine Wholesaler}",
    "wing_chun_school": "msg: GMBCID.wing_chun_school {Wing chun school}",
    "wok_restaurant": "msg: GMBCID.wok_restaurant {Wok restaurant}",
    "womens_clothing_store": "msg: GMBCID.womens_clothing_store {Ladies' Clothes Shop}",
    "womens_college": "msg: GMBCID.womens_college {Women's College}",
    "womens_organization": "msg: GMBCID.womens_organization {Women's Organisation}",
    "womens_personal_trainer": "msg: GMBCID.womens_personal_trainer {Women's Personal Trainer}",
    "womens_protection_service": "msg: GMBCID.womens_protection_service {Womens protection service}",
    "womens_shelter": "msg: GMBCID.womens_shelter {Women's shelter}",
    "wood_and_laminate_flooring_supplier": "msg: GMBCID.wood_and_laminate_flooring_supplier {Wood and Laminate Flooring Supplier}",
    "wood_floor_installation_service": "msg: GMBCID.wood_floor_installation_service {Wood floor installation service}",
    "wood_floor_refinishing_service": "msg: GMBCID.wood_floor_refinishing_service {Wood floor refinishing service}",
    "wood_frame_supplier": "msg: GMBCID.wood_frame_supplier {Wood Frame Supplier}",
    "wood_stove_shop": "msg: GMBCID.wood_stove_shop {Wood stove shop}",
    "wood_supplier": "msg: GMBCID.wood_supplier {Wood Supplier}",
    "wood_working_class": "msg: GMBCID.wood_working_class {Wood Working Class}",
    "wool_store": "msg: GMBCID.wool_store {Wool Shop}",
    "work_clothes_store": "msg: GMBCID.work_clothes_store {Work Clothes Shop}",
    "workers_club": "msg: GMBCID.workers_club {Workers' club}",
    "workers_compensation_attorney": "msg: GMBCID.workers_compensation_attorney {Workers Compensation Attorney}",
    "working_womens_hostel": "msg: GMBCID.working_womens_hostel {Working Women's Hostel}",
    "wrestling_school": "msg: GMBCID.wrestling_school {Wrestling school}",
    "x_ray_equipment_supplier": "msg: GMBCID.x_ray_equipment_supplier {X-ray Equipment Supplier}",
    "x_ray_lab": "msg: GMBCID.x_ray_lab {Medical imaging centre}",
    "xylophonist": "msg: GMBCID.xylophonist {Xylophonist}",
    "yacht_club": "msg: GMBCID.yacht_club {Yacht club}",
    "yakiniku_restaurant": "msg: GMBCID.yakiniku_restaurant {Yakiniku restaurant}",
    "yakisoba_restaurant": "msg: GMBCID.yakisoba_restaurant {Yakisoba Restaurant}",
    "yakitori_restaurant": "msg: GMBCID.yakitori_restaurant {Yakitori restaurant}",
    "yarn_store": "msg: GMBCID.yarn_store {Wool shop}",
    "yemenite_restaurant": "msg: GMBCID.yemenite_restaurant {Yemenite restaurant}",
    "yoga_instructor": "msg: GMBCID.yoga_instructor {Yoga Instructor}",
    "yoga_retreat_center": "msg: GMBCID.yoga_retreat_center {Yoga Retreat Centre}",
    "yoga_studio": "msg: GMBCID.yoga_studio {Yoga studio}",
    "youth_care": "msg: GMBCID.youth_care {Youth care}",
    "youth_center": "msg: GMBCID.youth_center {Youth Centre}",
    "youth_clothing_store": "msg: GMBCID.youth_clothing_store {Youth Clothing Shop}",
    "youth_club": "msg: GMBCID.youth_club {Youth club}",
    "youth_hostel": "msg: GMBCID.youth_hostel {Youth Hostel}",
    "youth_organization": "msg: GMBCID.youth_organization {Youth organisation}",
    "youth_social_services_organization": "msg: GMBCID.youth_social_services_organization {Youth Social Services Organisation}",
    "yucatan_restaurant": "msg: GMBCID.yucatan_restaurant {Yucatan restaurant}",
    "zhe_jiang_restaurant": "msg: GMBCID.zhe_jiang_restaurant {Zhejiang restaurant}",
    "zoo": "msg: GMBCID.zoo {Zoo}"
};
