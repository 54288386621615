import { DASHBOARD_SHOP_ROUTE } from "../../../../../../server/shared/routes";
import { getAppConfig } from "../../../../../../wbtgen/src/components/App/epics/appConfig/appConfig";
import AppConfig from "../../../../../../wbtgen/src/utils/AppConfig";
import { SubscriptionTypeT } from "../../../../../../wbtgen/src/redux/modules/flowTypes";
import { isEcommerceSubscription } from "../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import { isPartnerSubdomain } from "../../../../../../wbtgen/src/components/ComponentTierManager/partnerSubdomainNameUtils";

import { isShopInAllTiersEnabled } from "../../../common/config";
import { OnlineShopStatus } from "./types";
import { SHOP_PAGES_ROUTES } from "./constants";

export const prefixShopRoute = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const pathPrefix = appConfig.getOptional("oneWeb.webshop.iframeUrlPathPrefix", "");

    if (!pathPrefix || route.startsWith(pathPrefix)) {
        return route;
    }

    return pathPrefix + route;
};

const unprefixShopRoute = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const pathPrefix = appConfig.getOptional("oneWeb.webshop.iframeUrlPathPrefix", "");

    if (!pathPrefix || !route.startsWith(pathPrefix)) {
        return route;
    }

    return route.substring(pathPrefix.length);
};

export const mapShopRouteToUrl = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const shopAppUrl = appConfig.get("oneWeb.webshop.appUrl");

    return shopAppUrl + prefixShopRoute(route);
};

export const mapShopRouteToLocationRoute = (route: string): string => {
    return DASHBOARD_SHOP_ROUTE + unprefixShopRoute(route);
};

export const mapShopApiRouteToUrl = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const shopAppUrl = appConfig.get("oneWeb.webshop.appUrl");

    // api routes are not prefixed
    return shopAppUrl + route;
};

export const isOnlineShopAllowedForTier = (subscriptionType: SubscriptionTypeT) => (
    !isPartnerSubdomain() &&
    (isShopInAllTiersEnabled || isEcommerceSubscription(subscriptionType))
);

type OnlineShopStatusApiResponse = {
    isWebshopSetupDone: boolean;
    isDigitalShopSetupDone: boolean;
    isPaymentRequired: boolean;
};

export const onlineShopStatus = (options: OnlineShopStatusApiResponse): OnlineShopStatus => {
    if (options?.isWebshopSetupDone) {
        if (options?.isPaymentRequired) {
            return OnlineShopStatus.Inactive;
        } else {
            return OnlineShopStatus.Active;
        }
    }

    return OnlineShopStatus.NoShop;
};

export const isOnlineShopActive = (status: OnlineShopStatus | null) => {
    return status === OnlineShopStatus.Active;
};

export const getShopRouteFromLocation = () => {
    const path = (
        location.pathname.substring(DASHBOARD_SHOP_ROUTE.length) ||
        SHOP_PAGES_ROUTES.HOME
    );

    return prefixShopRoute(path) + location.search;
};
