// @flow

const DEFAULT_LOCALE = 'en_gb';
const PUBLISH_TEMP_FOLDER_PREFIX = 'tmp-publish-UTC-date-';
const SIMPLESITE = 'simplesite';
const SITE_TEMPLATES_KEYWORD = 'siteTemplates';

module.exports = {
    EMPTY_SVG: '<svg xmlns="http://www.w3.org/2000/svg"></svg>',
    KIND: {
        SVG: 'SVG',
    },
    RAW_ASSET_ENDPOINT: 'rawAsset',
    DEFAULT_LOCALE,
    PUBLISH_TEMP_FOLDER_PREFIX,
    SIMPLESITE,
    SITE_TEMPLATES_KEYWORD,
};
