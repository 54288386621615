import React from "react";
import { useSelector } from "react-redux";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import { getEcommercePackageNameFromMetadata } from "../../../../../../../../wbtgen/src/components/ComponentTierManager/utils";
import { SubscriptionType } from "../../../../../../../../server/shared/subscriptionStatus/constants";
import { FEATURE_BUSINESS_LISTING } from "../../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/tracking/contants";

import { SettingsSectionUpgrade } from "../../../../SettingsControls";
import { selectSubscriptionData } from "../../../../../common/selectors";

export const GMBGetStartedUpgrade = () => {
    const intl = useIntl();

    const subscriptionData = useSelector(selectSubscriptionData);
    const subscriptionMetadata = subscriptionData?.metadata;
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    const packageName = eCommercePackageNameFromMetadata || intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}");

    return (
        <SettingsSectionUpgrade
            feature={FEATURE_BUSINESS_LISTING}
            title={intl.msgJoint(["msg:trackingTab.businessECommerce.blockLabel.header {{packageName} feature}", { packageName }])}
            description={
                <React.Fragment>
                    {intl.msgJoint("msg: gmb.doesNotIncludeGMB {Your current Website Builder plan does not include Google My Business.}")}{" "}
                    {intl.msgJoint([
                        "msg: gmb.upgradeToBusinessPlusEcommerce {Upgrade to {packageName} to get this and other great features.}",
                        { packageName }
                    ])}
                </React.Fragment>
            }
            allowedUpgradeTypes={[SubscriptionType.ECOMMERCE]}
            data-testid="gmb-settings-upgrade"
        />
    );
};
